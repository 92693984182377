export const RouterAbi = [
    {
      type: "constructor",
      inputs: [{ name: "_factory", type: "address", internalType: "address" }],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "addLiquidity",
      inputs: [
        { name: "tokenA", type: "address", internalType: "address" },
        { name: "tokenB", type: "address", internalType: "address" },
        { name: "amountADesired", type: "uint256", internalType: "uint256" },
        { name: "amountBDesired", type: "uint256", internalType: "uint256" },
        { name: "amountAMin", type: "uint256", internalType: "uint256" },
        { name: "amountBMin", type: "uint256", internalType: "uint256" },
        { name: "to", type: "address", internalType: "address" },
        { name: "deadline", type: "uint256", internalType: "uint256" }
      ],
      outputs: [
        { name: "amountA", type: "uint256", internalType: "uint256" },
        { name: "amountB", type: "uint256", internalType: "uint256" },
        { name: "liquidity", type: "uint256", internalType: "uint256" }
      ],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "factory",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "removeLiquidity",
      inputs: [
        { name: "tokenA", type: "address", internalType: "address" },
        { name: "tokenB", type: "address", internalType: "address" },
        { name: "liquidity", type: "uint256", internalType: "uint256" },
        { name: "amountAMin", type: "uint256", internalType: "uint256" },
        { name: "amountBMin", type: "uint256", internalType: "uint256" },
        { name: "to", type: "address", internalType: "address" },
        { name: "deadline", type: "uint256", internalType: "uint256" }
      ],
      outputs: [
        { name: "amountA", type: "uint256", internalType: "uint256" },
        { name: "amountB", type: "uint256", internalType: "uint256" }
      ],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "swapExactTokensForTokens",
      inputs: [
        { name: "amountIn", type: "uint256", internalType: "uint256" },
        { name: "amountOutMin", type: "uint256", internalType: "uint256" },
        { name: "path", type: "address[]", internalType: "address[]" },
        { name: "to", type: "address", internalType: "address" },
        { name: "deadline", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "amountOut", type: "uint256", internalType: "uint256" }],
      stateMutability: "nonpayable"
    },
    {
      type: "event",
      name: "LiquidityAdded",
      inputs: [
        { name: "provider", type: "address", indexed: true, internalType: "address" },
        { name: "tokenA", type: "address", indexed: true, internalType: "address" },
        { name: "tokenB", type: "address", indexed: true, internalType: "address" },
        { name: "amountA", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "amountB", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "liquidity", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "LiquidityRemoved",
      inputs: [
        { name: "provider", type: "address", indexed: true, internalType: "address" },
        { name: "tokenA", type: "address", indexed: true, internalType: "address" },
        { name: "tokenB", type: "address", indexed: true, internalType: "address" },
        { name: "amountA", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "amountB", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "liquidity", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "TokensSwapped",
      inputs: [
        { name: "trader", type: "address", indexed: true, internalType: "address" },
        { name: "tokenIn", type: "address", indexed: true, internalType: "address" },
        { name: "tokenOut", type: "address", indexed: true, internalType: "address" },
        { name: "amountIn", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "amountOut", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "error",
      name: "AddressEmptyCode",
      inputs: [{ name: "target", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "AddressInsufficientBalance",
      inputs: [{ name: "account", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "FailedInnerCall",
      inputs: []
    },
    {
      type: "error",
      name: "ReentrancyGuardReentrantCall",
      inputs: []
    },
    {
      type: "error",
      name: "SafeERC20FailedOperation",
      inputs: [{ name: "token", type: "address", internalType: "address" }]
    }
  ] as const;
  
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, signal } from '@angular/core';
import { ButtonComponent } from '@fsco/shared';

@Component({
    selector: 'app-evidence-form',
    standalone: true,
    imports: [CommonModule, ButtonComponent],
    templateUrl: './evidence-form.component.html',
    styleUrls: [],
})
export class EvidenceFormComponent {
    loading = signal<boolean>(false);
    error = signal<string | null>(null);

    selectedFile = signal<File | null>(null);

    @Output() fileSelected = new EventEmitter<string>();

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            console.log('input.files', input.files);
            this.selectedFile.set(input.files[0]);
            const file = input.files[0];
            const fileUrl = URL.createObjectURL(file);
            console.log('fileUrl', fileUrl);
            this.fileSelected.emit(fileUrl);
        }
    }
}

import { Injectable } from '@angular/core';
import { ContractLoader, GetWallets } from '@fsco/shared';
import { ProvisionLoader } from '@fsco/shared';
import { environment } from '../../environments/environment';
import { WalletService } from './wallet.service';

@Injectable({
    providedIn: 'root',
})
export class ProofContractService {
    private readonly provisionLoader: ProvisionLoader<typeof environment.did>;

    constructor(
        private readonly contractLoader: ContractLoader,
        private readonly walletService: WalletService,
    ) {
        this.provisionLoader = new ProvisionLoader(environment.did);
    }

    async getDeclarationContract() {
        const config = this.provisionLoader.contract(
            environment.chainConfig.contracts.declarations,
        );
        const contract = await this.contractLoader.getContractById(
            config.abi,
            config.deploymentId,
        );
        contract.set_wallet(this.walletService.adminWallet().id);
        console.log('Got Declaration Contract With', {
            ref: environment.chainConfig.contracts.declarations,
            deploymentId: config.deploymentId,
            address: contract.address,
            chainId: contract.chainId,
        });
        return contract;
    }

    async getProofContract() {
        const config = this.provisionLoader.contract(
            environment.chainConfig.contracts.proofs,
        );
        const contract = await this.contractLoader.getContractById(
            config.abi,
            config.deploymentId,
        );
        contract.set_wallet(this.walletService.adminWallet().id);
        console.log('Got Proof Contract With', {
            ref: environment.chainConfig.contracts.proofs,
            deploymentId: config.deploymentId,
            address: contract.address,
            chainId: contract.chainId,
        });
        return contract;
    }

    async getProofLinkContract() {
        const config = this.provisionLoader.contract(
            environment.chainConfig.contracts.links,
        );
        const contract = await this.contractLoader.getContractById(
            config.abi,
            config.deploymentId,
        );
        contract.set_wallet(this.walletService.adminWallet().id);
        console.log('Got Proof Link Contract With', {
            ref: environment.chainConfig.contracts.links,
            deploymentId: config.deploymentId,
            address: contract.address,
            chainId: contract.chainId,
        });
        return contract;
    }

    async getVCTContract() {
        const config = this.provisionLoader.deployment(
            environment.chainConfig.deployments.vctToken,
        );
        const contract = await this.contractLoader.getContractById(
            config.abi,
            config.deploymentId,
        );
        contract.set_wallet(this.walletService.adminWallet().id);
        console.log('Got VCT Contract With', {
            ref: environment.chainConfig.deployments.vctToken,
            deploymentId: config.deploymentId,
            address: contract.address,
            chainId: contract.chainId,
        });
        return contract;
    }

    async getDVCTContract() {
        const config = this.provisionLoader.deployment(
            environment.chainConfig.deployments.dvctToken,
        );
        const contract = await this.contractLoader.getContractById(
            config.abi,
            config.deploymentId,
        );
        contract.set_wallet(this.walletService.adminWallet().id);
        console.log('Got DVCT Contract With', {
            ref: environment.chainConfig.deployments.dvctToken,
            deploymentId: config.deploymentId,
            address: contract.address,
            chainId: contract.chainId,
        });
        return contract;
    }
}


@if(href){
<a
    [href]="href"
    [target]="target"
    [rel]="rel"
    [ngClass]="[
        'fsco-button inline-flex items-center justify-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
        variant === 'primary' ? 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500' :
        variant === 'secondary' ? 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-green-500' :
        'bg-transparent text-gray-700 hover:bg-gray-100 focus:ring-green-500',
        size === 'small' ? 'text-xs' : size === 'large' ? 'text-base px-6 py-3' : 'text-sm',
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
        class || ''
    ]"
    (click)="onClick()"
>
    @if(icon) {
        <ng-icon [name]="icon" class="mr-2 -ml-1 h-5 w-5"></ng-icon>
    }
    <ng-content></ng-content>
</a>
} @else {
<button
    [ngClass]="[
        'fsco-button inline-flex items-center justify-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
        variant === 'primary' ? 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500' :
        variant === 'secondary' ? 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-green-500' :
        'bg-transparent text-gray-700 hover:bg-gray-100 focus:ring-green-500',
        size === 'small' ? 'text-xs' : size === 'large' ? 'text-base px-6 py-3' : 'text-sm',
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
        class || ''
    ]"
    (click)="onClick()"
    [disabled]="disabled"
>
    @if(icon) {
        <ng-icon [name]="icon" class="mr-2 -ml-1 h-5 w-5"></ng-icon>
  }
  <ng-content></ng-content>
</button>
}



<div class="container mx-auto p-4">
    <!-- Error Message -->
    @if (error()) {
    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
        {{ error() }}
    </div>
    }

    <!-- Loading State -->
    @if (loading()) {
    <div class="flex justify-center items-center">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
    }

    <!-- Evidence Upload Step -->
    <div class="max-w-lg mx-auto">
        <h2 class="text-xl font-bold mb-4">Upload Evidence</h2>
        <div class="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
            <input type="file" (change)="onFileSelected($event)" class="hidden" id="file-upload" />
            <label for="file-upload" class="cursor-pointer text-blue-600 hover:text-blue-800">
                Click to upload or drag and drop
            </label>
            @if (selectedFile()) {
            <div class="mt-4 text-sm text-gray-600">
                Selected file: {{ selectedFile()?.name }}
            </div>
            }
        </div>

    </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TheaterIcon } from 'lucide-angular';
import { Observable, map } from 'rxjs';
import { getEnvironment } from '../../../environments/environment';
import { Environment } from '../../config/environment.token';
import { SessionService } from '../../services/session.service';
import { Logger } from '../../utils/logger';
import { AUTH_TOKEN_VERSION } from '../classes/user';
@Injectable()
export class AuthGuard implements CanActivate {
    private readonly logger = new Logger('AuthGuard');
    private readonly environment: Environment = getEnvironment();
    constructor(
        private router: Router,
        private session: SessionService,
    ) {}
    canActivate(
        _next: ActivatedRouteSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.session.get$().pipe(
            map((session) => {
                if (session || this.environment.enableLogin === false) {
                    this.logger.log('Auth Guard Passed');
                    return true;
                }
                this.logger.log('Routing To Login Auth Guard');
                this.router.navigate(['login']);
                return false;
            }),
        );
    }
}

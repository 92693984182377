export const PairAbi = [
    {
      type: "function",
      name: "DEFAULT_ADMIN_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "EXCHANGE_RATE_PRECISION",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "MAX_FEE",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "PAIR_ADMIN_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "PERCENTAGE_DIVISOR",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "addLiquidity",
      inputs: [
        { name: "amountA", type: "uint256", internalType: "uint256" },
        { name: "amountB", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "liquidityMinted", type: "uint256", internalType: "uint256" }],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "allowance",
      inputs: [
        { name: "owner", type: "address", internalType: "address" },
        { name: "spender", type: "address", internalType: "address" }
      ],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "approve",
      inputs: [
        { name: "spender", type: "address", internalType: "address" },
        { name: "value", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "balanceOf",
      inputs: [{ name: "account", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "decimals",
      inputs: [],
      outputs: [{ name: "", type: "uint8", internalType: "uint8" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "exchangeRate",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "getReserves",
      inputs: [],
      outputs: [
        { name: "reserveA", type: "uint256", internalType: "uint256" },
        { name: "reserveB", type: "uint256", internalType: "uint256" }
      ],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "getRoleAdmin",
      inputs: [{ name: "role", type: "bytes32", internalType: "bytes32" }],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "grantRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "hasRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "initialize",
      inputs: [
        { name: "name", type: "string", internalType: "string" },
        { name: "symbol", type: "string", internalType: "string" },
        { name: "_tokenA", type: "address", internalType: "address" },
        { name: "_tokenB", type: "address", internalType: "address" },
        { name: "admin", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "name",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "removeLiquidity",
      inputs: [
        { name: "liquidity", type: "uint256", internalType: "uint256" },
        { name: "to", type: "address", internalType: "address" }
      ],
      outputs: [
        { name: "amountA", type: "uint256", internalType: "uint256" },
        { name: "amountB", type: "uint256", internalType: "uint256" }
      ],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "renounceRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "callerConfirmation", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "revokeRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "setExchangeRate",
      inputs: [{ name: "newExchangeRate", type: "uint256", internalType: "uint256" }],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "setSwapFees",
      inputs: [
        { name: "feeAtoB", type: "uint256", internalType: "uint256" },
        { name: "feeBtoA", type: "uint256", internalType: "uint256" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "supportsInterface",
      inputs: [{ name: "interfaceId", type: "bytes4", internalType: "bytes4" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "swap",
      inputs: [
        { name: "amountIn", type: "uint256", internalType: "uint256" },
        { name: "amountOutMin", type: "uint256", internalType: "uint256" },
        { name: "path", type: "address[]", internalType: "address[]" },
        { name: "to", type: "address", internalType: "address" },
        { name: "deadline", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "amountOut", type: "uint256", internalType: "uint256" }],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "swapFeeAtoB",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "swapFeeBtoA",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "symbol",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "tokenA",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "contract IERC20Metadata" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "tokenB",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "contract IERC20Metadata" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "totalSupply",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "transfer",
      inputs: [
        { name: "to", type: "address", internalType: "address" },
        { name: "value", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "transferFrom",
      inputs: [
        { name: "from", type: "address", internalType: "address" },
        { name: "to", type: "address", internalType: "address" },
        { name: "value", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "nonpayable"
    },
    {
      type: "event",
      name: "Approval",
      inputs: [
        { name: "owner", type: "address", indexed: true, internalType: "address" },
        { name: "spender", type: "address", indexed: true, internalType: "address" },
        { name: "value", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "ExchangeRateUpdated",
      inputs: [
        { name: "newExchangeRate", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "Initialized",
      inputs: [{ name: "version", type: "uint64", indexed: false, internalType: "uint64" }],
      anonymous: false
    },
    {
      type: "event",
      name: "LiquidityAdded",
      inputs: [
        { name: "provider", type: "address", indexed: true, internalType: "address" },
        { name: "amountA", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "amountB", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "liquidityMinted", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "LiquidityRemoved",
      inputs: [
        { name: "provider", type: "address", indexed: true, internalType: "address" },
        { name: "amountA", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "amountB", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "liquidityBurned", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleAdminChanged",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "previousAdminRole", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "newAdminRole", type: "bytes32", indexed: true, internalType: "bytes32" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleGranted",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "account", type: "address", indexed: true, internalType: "address" },
        { name: "sender", type: "address", indexed: true, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleRevoked",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "account", type: "address", indexed: true, internalType: "address" },
        { name: "sender", type: "address", indexed: true, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "Swap",
      inputs: [
        { name: "sender", type: "address", indexed: true, internalType: "address" },
        { name: "amountIn", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "amountOut", type: "uint256", indexed: false, internalType: "uint256" },
        { name: "inputToken", type: "address", indexed: true, internalType: "address" },
        { name: "outputToken", type: "address", indexed: true, internalType: "address" },
        { name: "to", type: "address", indexed: false, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "Transfer",
      inputs: [
        { name: "from", type: "address", indexed: true, internalType: "address" },
        { name: "to", type: "address", indexed: true, internalType: "address" },
        { name: "value", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "error",
      name: "AccessControlBadConfirmation",
      inputs: []
    },
    {
      type: "error",
      name: "AccessControlUnauthorizedAccount",
      inputs: [
        { name: "account", type: "address", internalType: "address" },
        { name: "neededRole", type: "bytes32", internalType: "bytes32" }
      ]
    },
    {
      type: "error",
      name: "AddressEmptyCode",
      inputs: [{ name: "target", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "AddressInsufficientBalance",
      inputs: [{ name: "account", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "AlreadyInitialized",
      inputs: []
    },
    {
      type: "error",
      name: "ERC20InsufficientAllowance",
      inputs: [
        { name: "spender", type: "address", internalType: "address" },
        { name: "allowance", type: "uint256", internalType: "uint256" },
        { name: "needed", type: "uint256", internalType: "uint256" }
      ]
    },
    {
      type: "error",
      name: "ERC20InsufficientBalance",
      inputs: [
        { name: "sender", type: "address", internalType: "address" },
        { name: "balance", type: "uint256", internalType: "uint256" },
        { name: "needed", type: "uint256", internalType: "uint256" }
      ]
    },
    {
      type: "error",
      name: "ERC20InvalidApprover",
      inputs: [{ name: "approver", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "ERC20InvalidReceiver",
      inputs: [{ name: "receiver", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "ERC20InvalidSender",
      inputs: [{ name: "sender", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "ERC20InvalidSpender",
      inputs: [{ name: "spender", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "FailedInnerCall",
      inputs: []
    },
    {
      type: "error",
      name: "FeeTooHigh",
      inputs: []
    },
    {
      type: "error",
      name: "IdenticalInputOutputToken",
      inputs: []
    },
    {
      type: "error",
      name: "InsufficientLiquidity",
      inputs: []
    },
    {
      type: "error",
      name: "InsufficientLiquidityBurned",
      inputs: []
    },
    {
      type: "error",
      name: "InsufficientLiquidityMinted",
      inputs: []
    },
    {
      type: "error",
      name: "InsufficientOutputAmount",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidAdminAddress",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidAmounts",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidExchangeRate",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidInitialization",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidInputToken",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidLiquidityAmount",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidNameOrSymbol",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidOutputToken",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidPathLength",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidSwapDirection",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidTokenAddresses",
      inputs: []
    },
    {
      type: "error",
      name: "NotInitializing",
      inputs: []
    },
    {
      type: "error",
      name: "ReentrancyGuardReentrantCall",
      inputs: []
    },
    {
      type: "error",
      name: "SafeERC20FailedOperation",
      inputs: [{ name: "token", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "UnauthorizedInitializer",
      inputs: []
    },
    {
      type: "error",
      name: "ZeroBalanceDetected",
      inputs: []
    },
    {
      type: "error",
      name: "ZeroSupplyDetected",
      inputs: []
    }
  ] as const;
  
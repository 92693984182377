import { Injectable, inject } from '@angular/core';
import { DEFAULT_LOGGER_CONFIG, LOGGER_CONFIG } from './logger.config';
import { LogEntry, LogLevel, LoggerConfig } from './logger.interface';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    private config: LoggerConfig;

    constructor() {
        this.config =
            inject(LOGGER_CONFIG, { optional: true }) ?? DEFAULT_LOGGER_CONFIG;
    }

    private createLogEntry(
        message: string,
        details: Record<string, string | number | boolean>,
        level: LogLevel,
        source: string,
    ): LogEntry {
        return {
            timestamp: this.config.enableTimestamp
                ? new Date().toISOString()
                : '',
            message,
            details,
            level,
            source,
        };
    }

    private log(entry: LogEntry): void {
        if (this.config.enableConsole && this.shouldLog(entry.level)) {
            const formattedLog = this.config.customFormatter
                ? this.config.customFormatter(entry)
                : this.defaultFormatter(entry);

            switch (entry.level) {
                case LogLevel.DEBUG:
                    console.debug(formattedLog);
                    break;
                case LogLevel.INFO:
                    console.info(formattedLog);
                    break;
                case LogLevel.WARN:
                    console.warn(formattedLog);
                    break;
                case LogLevel.ERROR:
                    console.error(formattedLog);
                    break;
            }
        }
    }

    private defaultFormatter(entry: LogEntry): string {
        const timestamp = entry.timestamp ? `[${entry.timestamp}] ` : '';
        const source = entry.source ? `[${entry.source}] ` : '';
        const level = `[${entry.level}] `;
        const details = Object.keys(entry.details).length
            ? `\nDetails: ${JSON.stringify(entry.details, null, 2)}`
            : '';

        return `${timestamp}${level}${source}${entry.message}${details}`;
    }

    private shouldLog(level: LogLevel): boolean {
        const levels = Object.values(LogLevel);
        const configLevelIndex = levels.indexOf(this.config.minLevel);
        const currentLevelIndex = levels.indexOf(level);
        return currentLevelIndex >= configLevelIndex;
    }

    debug(
        message: string,
        details: Record<string, string | number | boolean>,
        source: string,
    ): void {
        this.log(this.createLogEntry(message, details, LogLevel.DEBUG, source));
    }

    info(
        message: string,
        details: Record<string, string | number | boolean>,
        source: string,
    ): void {
        this.log(this.createLogEntry(message, details, LogLevel.INFO, source));
    }

    warn(
        message: string,
        details: Record<string, string | number | boolean>,
        source: string,
    ): void {
        this.log(this.createLogEntry(message, details, LogLevel.WARN, source));
    }

    error(
        message: string,
        details: Record<string, string | number | boolean>,
        source: string,
    ): void {
        this.log(this.createLogEntry(message, details, LogLevel.ERROR, source));
    }
}

import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges, signal } from '@angular/core';

import { ButtonComponent } from '../button/button.component';

@Component({
    selector: 'fsco-modal',
    standalone: true,
    imports: [CommonModule, ButtonComponent],
    templateUrl: './modal.component.html',
    styles: [],
})
export class ModalComponent {
    showing = signal<boolean>(false);

    @Input() isOpen = false;
    @Input() title = '';
    @Input() buttonText = 'Open Modal';

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isOpen']) {
            this.showing.set(changes['isOpen'].currentValue);
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@fsco/shared';
import {
    Observable,
    Subject,
    finalize,
    from,
    lastValueFrom,
    timer,
} from 'rxjs';
import { map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { MetadataService } from './metadata.service';
import { environment } from '../../environments/environment';
import { getAppScopedQueuedEventInfos } from '@angular/core/primitives/event-dispatch';

/**
 * 1. Load QR sessionId from shareRing API
 * 2. Create a metadata record of this sessionId in the metadata service
 * 3. Poll the metadata service api for the sessionId waiting for the values of the query to be populated
 *
 * 4. User approves the QR shareRing request
 * 5. ShareRing API Calls webhook inbound
 * 6. Webhook inbound update the metadata sessionId record with the values
 *
 * 7. The UI now sees the values and can continue with the create Proof flow.
 */

type URL_OPTIONS = {
    queryId?: string;
    sessionId?: string;
    keyId?: string;
    qr_owner?: string;
    clientId?: string;
};

const getShareRingQRBaseUrl = () => {
    return environment.shareringConfig.qrBaseUrl;
};
const getShareRingQRSubfix = () => {
    return environment.shareringConfig.qrSubfix;
};

const getShareRingVQLBaseUrl = () => {
    return environment.shareringConfig.vqlBaseUrl;
};

const buildUrl = (options: URL_OPTIONS) => {
    const subLink = `${getShareRingVQLBaseUrl()}?query:${options.queryId ?? ''}${options.clientId ? `%2C${options.clientId}` : '%2Cbasic'}${options.sessionId ? `:${options.sessionId}` : ''}andkey%3D${options.keyId ?? ''}andqr_owner%3D${options.qr_owner ?? ''}`;
    const fullLink = `${getShareRingQRBaseUrl()}?link=${subLink}${getShareRingQRSubfix()}`;
    console.log('fullLink ', fullLink);
    return fullLink;
};

interface MetadataValues {
    sessionId: string;
    values: Record<string, unknown>;
    results: Record<string, unknown>;
}

interface PollResult {
    attributes: unknown;
    isCompleted: boolean;
    qrURL: string;
}
type GetSessionResponse = {
    data: {
        generateQRSession: {
            status: string;
            message: string;
            data: {
                session_id: string;
            };
        };
    };
};

type GetQRRequestItemsResponse = {
    data: {
        getQRRequestItems: {
            status: string;
            message: string;
            data: {
                items: Array<{
                    _id: string;
                    query_id: string;
                    session_id: string;
                    result: string;
                    completion: boolean;
                    _created: string | null;
                    _updated: string;
                    user_name: string;
                    qr_owner: string;
                    query_info: {
                        name: string;
                        title: string;
                        color: string;
                        user_wallet_address: string;
                        created_by: string;
                        _created: string;
                        _updated: string;
                    };
                }>;
                page: number;
                pageSize: number;
                total: number;
            };
        };
    };
};

@Injectable({
    providedIn: 'root',
})
export class ShareRingService {
    private readonly logger = new Logger('ShareRingService');
    private readonly metadataTypeKey = 'sharering-webhook-data';
    private readonly baseUrl = environment.shareringConfig.graphqlBaseUrl;
    private readonly headers = {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        'Content-Type': 'application/json',
    };

    constructor(
        private readonly http: HttpClient,
        private readonly metadataService: MetadataService,
    ) {}

    private async getOrCreateMetadataType(): Promise<string> {
        try {
            const { typeId } = await lastValueFrom(
                this.metadataService.getMetadataTypeByKey(this.metadataTypeKey),
            );
            this.logger.info('Got metadata type', { typeId });
            if (typeId) return typeId;
            throw new Error('Metadata type not found');
        } catch (error) {
            this.logger.warn('Error getting or creating metadata type', {
                error,
            });
            const { typeId: newTypeId } = await lastValueFrom(
                this.metadataService.createMetadataType(this.metadataTypeKey),
            );
            this.logger.info('Created metadata type', { newTypeId });
            return newTypeId;
        }
    }

    private async createInitialMetadata(sessionId: string, typeId: string) {
        const values: MetadataValues = {
            sessionId,
            values: {},
            results: {},
        };

        await lastValueFrom(
            this.metadataService.createMetadata(
                JSON.stringify(values),
                typeId,
                sessionId,
            ),
        );
        this.logger.info('Created initial metadata', { sessionId, typeId });
    }

    private async getUserSharedMetadata(
        sessionId: string,
        typeId: string,
    ): Promise<{ value: string }> {
        return await lastValueFrom(
            this.metadataService.findMetadata(sessionId, typeId),
        );
    }

    private createPollResult(
        result: { value: string },
        sessionId: string,
        clientId: string,
        queryId: string,
        ownerId: string,
    ): PollResult {
        const rawValues: MetadataValues = JSON.parse(result.value as string);
        return {
            attributes: rawValues.values,
            isCompleted: Object.keys(rawValues.values).length !== 0,
            qrURL: this.getQRURL(clientId, queryId, sessionId, ownerId),
        };
    }
    private getSession() {
        return this.http.post<GetSessionResponse>(
            this.baseUrl,
            {
                query: `mutation {
                generateQRSession(metadata: "undefined") {
                    status
                    message
                    data {
                        session_id
                    }
                }
            }`,
                variables: {},
            },
            { headers: this.headers },
        );

        // TODO: create a metadata record of this sessionId with no values
    }

    // TODO: point this a metadata service get values for sessionId
    private getQRRequestItems(queryId: string, sessionId: string) {
        const query = `{
            getQRRequestItems(
              queryId: "${queryId}"
              sessionId: "${sessionId}"
              userName: ""
              type: "query"
              page: 1
              pageSize: 1
            ) {
              status
              message
              data {
                items {
                  _id
                  query_id
                  session_id
                  result
                  completion
                  _created
                  _updated
                  user_name
                  qr_owner
                  query_info {
                    name
                    title
                    color
                    user_wallet_address
                    created_by
                    _created
                    _updated
                  }
                }
                page
                pageSize
                total
              }
            }
          }`;
        return this.http.post<GetQRRequestItemsResponse>(
            this.baseUrl,
            {
                query: query,
                operationName: null,
                variables: {},
            },
            {
                headers: {
                    ...this.headers,
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    Pragma: 'no-cache',
                    Expires: '0',
                },
            },
        );
    }

    private getQRURL(
        clientId: string,
        queryId: string,
        sessionId: string,
        ownerId: string,
    ) {
        return buildUrl({
            queryId,
            sessionId,
            clientId,
            qr_owner: ownerId,
        });
    }

    public startQR(
        clientId: string,
        queryId: string,
        ownerId: string,
    ): Observable<PollResult> {
        this.logger.info('Starting QR', { clientId, queryId, ownerId });
        const sessionLoader = timer(0, 10 * 60 * 1000).pipe(
            switchMap(() => this.getSession()),
            map((res) => res.data.generateQRSession.data.session_id),
        );

        return sessionLoader.pipe(
            switchMap(async (sessionId) => {
                this.logger.info('Got session', { sessionId });
                const typeId = await this.getOrCreateMetadataType();
                this.logger.info('Got Metadata Type', {
                    sessionId,
                    typeId,
                });
                await this.createInitialMetadata(sessionId, typeId);
                this.logger.info('Created initial metadata', {
                    sessionId,
                    typeId,
                });

                const pollSubscription = timer(0, 5 * 1000).pipe(
                    switchMap(() =>
                        from(this.getUserSharedMetadata(sessionId, typeId)),
                    ),
                    map((result) =>
                        this.createPollResult(
                            result,
                            sessionId,
                            clientId,
                            queryId,
                            ownerId,
                        ),
                    ),
                    takeWhile((result) => !result.isCompleted, true),
                    tap((result) => {
                        this.logger.info('Poll result', { result });
                        if (result.isCompleted) {
                            sessionLoader.subscribe().unsubscribe();
                            pollSubscription.subscribe().unsubscribe();
                        }
                    }),
                );

                return pollSubscription;
            }),
            switchMap((pollSubscription) => pollSubscription),
        );
    }
}

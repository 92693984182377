import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonComponent } from '@fsco/shared';
import {
    CheckCircle2,
    CircleMinus,
    Loader2,
    LoaderCircle,
    LucideAngularModule,
    XCircle,
} from 'lucide-angular';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { EventService } from '../services/event.service';
import { ProofService } from '../services/proof.service';
import { EvidenceDetailsComponent } from '../shared/components/evidence-details/evidence-details.component';
import { ProofStatusIndicatorComponent } from '../shared/components/proof-status-indicator/proof-status-indicator.component';
import { ProofTableComponent } from '../shared/components/proof-table/proof-table.component';
import {
    ProofState,
    VerificationStatus,
    ZKEvidence,
} from '../shared/interfaces/proof.interface';

@Component({
    selector: 'app-verify-proof',
    standalone: true,
    imports: [
        CommonModule,
        ButtonComponent,
        ProofTableComponent,
        ProofStatusIndicatorComponent,
        LucideAngularModule,
        EvidenceDetailsComponent,
    ],
    templateUrl: './verify-proof.component.html',
    styles: [],
})
export class VerifyProofComponent implements OnInit {
    loading = signal<boolean>(false);
    error = signal<string | null>(null);
    selectedProof = signal<ProofState | null>(null);
    showDetails = signal<boolean>(false);

    // Lucide icons
    readonly loader2Icon = Loader2;
    readonly checkCircle2Icon = CheckCircle2;
    readonly circleMinusIcon = CircleMinus;
    readonly circleIcon = LoaderCircle;
    readonly xCircleIcon = XCircle;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private proofService: ProofService,
        private eventService: EventService,
    ) {}

    ngOnInit(): void {
        // Subscribe to route params
        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            if (id) {
                this.loadProofDetails(id);
            } else {
                this.showDetails.set(false);
                this.selectedProof.set(null);
            }
        });
    }
    loadProofDetails(proofId: string): void {
        this.loading.set(true);
        this.error.set(null);

        this.eventService
            .get(proofId)
            .pipe(
                switchMap((proof) => {
                    // Immediately show the proof details
                    this.selectedProof.set(proof);
                    this.showDetails.set(true);
                    this.loading.set(false);

                    if (proof.evidence.type === 'raw') {
                        this.loading.set(false);
                        return of(proof);
                    }

                    // For ZK evidence, handle verification
                    return this.handleZKEvidenceVerification(proof);
                }),
            )
            .subscribe({
                next: (updatedProof) => {
                    this.selectedProof.set(updatedProof);
                },
                error: (error) => {
                    console.error('Load Error:', error);
                    this.error.set('Failed to load proof details');
                    this.handleVerificationError();
                },
            });
    }

    private handleZKEvidenceVerification(proof: ProofState) {
        const evidence = proof.evidence as ZKEvidence;
        evidence.verificationStatuses = this.initializeVerificationStatuses(
            evidence.attributes,
            VerificationStatus.Pending,
        );

        this.selectedProof.set({ ...proof, evidence });

        return this.proofService.verifyProof(proof.id, evidence).pipe(
            map((verification) => {
                evidence.verificationStatuses = Object.keys(
                    evidence.attributes,
                ).reduce(
                    (acc, key) => {
                        acc[key] = verification[key]?.merkleOnchainMatched
                            ? VerificationStatus.Verified
                            : VerificationStatus.VerifiedFailed;
                        return acc;
                    },
                    {} as Record<string, VerificationStatus>,
                );
                return { ...proof, evidence };
            }),
            catchError((error) => {
                console.error('Verification Error:', error);
                evidence.verificationStatuses =
                    this.initializeVerificationStatuses(
                        evidence.attributes,
                        VerificationStatus.VerifiedFailed,
                    );
                return of({ ...proof, evidence });
            }),
        );
    }

    private handleVerificationError() {
        const currentProof = this.selectedProof();
        if (currentProof?.evidence.type === 'zk') {
            const evidence = currentProof.evidence as ZKEvidence;
            evidence.verificationStatuses = this.initializeVerificationStatuses(
                evidence.attributes,
                VerificationStatus.VerifiedFailed,
            );
            this.selectedProof.set({ ...currentProof, evidence });
        }
    }

    private initializeVerificationStatuses(
        attributes: Record<string, string>,
        status: VerificationStatus,
    ): Record<string, VerificationStatus> {
        return Object.keys(attributes).reduce(
            (acc, key) => {
                acc[key] = status;
                return acc;
            },
            {} as Record<string, VerificationStatus>,
        );
    }

    getVerificationStatus(key: string): VerificationStatus {
        const selectedProof = this.selectedProof();
        if (this.loading()) {
            return VerificationStatus.Pending;
        }
        if (!selectedProof) {
            return VerificationStatus.VerifiedFailed;
        }
        if (selectedProof.evidence.type === 'raw') {
            return VerificationStatus.Verified;
        }

        if (selectedProof.evidence.type === 'zk') {
            return selectedProof.evidence.verificationStatuses[key];
        }
        return VerificationStatus.Unverified;
    }

    isStatus(key: string, status: VerificationStatus): boolean {
        return this.getVerificationStatus(key) === status;
    }

    handleProofSelect(proof: ProofState): void {
        // Navigate to the proof details route
        this.router.navigate(['/verify-proof', proof.id]);
    }

    backToList(): void {
        this.showDetails.set(false);
        this.selectedProof.set(null);
        this.router.navigate(['/verify-proof']);
    }

    getLinkTargetAttributes(proof: ProofState): [string, string][] {
        if (!('link' in proof) || !proof.link?.targetAttributes) {
            return [];
        }
        return Object.entries(proof.link.targetAttributes);
    }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
    heroAcademicCap,
    heroChartBar,
    heroClipboardDocument,
    heroCog,
    heroDocumentText,
    heroHome,
    heroWallet,
} from '@ng-icons/heroicons/outline';

@Component({
    selector: 'fsco-button',
    standalone: true,
    imports: [CommonModule, NgIconComponent],
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    providers: [
        provideIcons({
            heroHome,
            heroChartBar,
            heroWallet,
            heroDocumentText,
            heroClipboardDocument,
            heroAcademicCap,
            heroCog,
        }),
    ],
})
export class ButtonComponent {
    @Input() variant: 'primary' | 'secondary' | 'tertiary' = 'primary';
    @Input() size: 'small' | 'medium' | 'large' = 'medium';
    @Input() disabled = false;
    @Input() icon?: string;
    @Input() class?: string;
    @Input() href?: string;
    @Input() target?: string;
    @Input() rel?: string;

    @Output() clicked = new EventEmitter<void>();

    onClick() {
        if (!this.disabled) {
            this.clicked.emit();
        }
    }
}

export enum LogLevel {
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
}

export interface LogEntry {
    timestamp: string;
    message: string;
    details: Record<string, string | number | boolean>;
    level: LogLevel;
    source: string;
}

export interface LoggerConfig {
    minLevel: LogLevel;
    enableConsole: boolean;
    enableTimestamp: boolean;
    customFormatter?: (entry: LogEntry) => string;
}

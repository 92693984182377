import { Injectable } from '@angular/core';
import { Logger } from '@fsco/shared';
import { Observable, from, map, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Declaration } from '../shared/interfaces/proof.interface';
import { getMockDeclarations } from '../shared/mocks/data.mock';
import { ProofContractService } from './contract.service';
import { EventService } from './event.service';
import { WalletService } from './wallet.service';

export type Pretty<type> = { [key in keyof type]: type[key] } & unknown;

@Injectable({
    providedIn: 'root',
})
export class DeclarationService {
    private readonly logger = new Logger('DeclarationService');
    // Mock data - replace with actual API endpoints
    private readonly declarations: Record<string, Declaration> =
        getMockDeclarations();

    private declarations_cache: Declaration[] = [];

    constructor(
        private contractService: ProofContractService,
        private walletService: WalletService,
        private eventService: EventService,
    ) {}

    getDeclarationsOffline(): Observable<Declaration[]> {
        return of(Object.values(this.declarations));
    }

    resetCache() {
        this.logger.log('Resetting declarations cache');
        this.declarations_cache = [];
    }

    async getDeclarationsOnline() {
        if (this.declarations_cache.length > 0) {
            this.logger.log(
                `Returning ${this.declarations_cache.length} cached declarations`,
            );
            return this.declarations_cache;
        }
        this.logger.log('Loading Declarations from chain');
        const admin_user = this.walletService.adminWallet();
        const declaration_contract =
            await this.contractService.getDeclarationContract();
        this.declarations_cache = await declaration_contract.executeWith(
            {
                walletId: admin_user.id,
            },
            async (contract) => {
                const balance = await contract.balanceOf({
                    owner: admin_user.address,
                });

                const declarations: Declaration[] = [];

                for (let i = 0n; i < balance.data[0].value; i++) {
                    const tokenId = await contract.tokenOfOwnerByIndex({
                        owner: admin_user.address,
                        index: i,
                    });
                    const [abi_version, abi_info] = await Promise.all([
                        contract.getLatestVersion({
                            tokenId: tokenId.data[0].value,
                        }),
                        contract.getLatestInfo({
                            tokenId: tokenId.data[0].value,
                        }),
                    ]);

                    const uri = abi_info.data[0].value;
                    const name = abi_info.data[1].value;
                    const version = abi_version.data[0].value.toString();

                    declarations.push({
                        id: tokenId.data[0].value.toString(),
                        uri,
                        version,
                        shareRing: {
                            clientId: environment.shareringConfig.clientId,
                            queryId: environment.shareringConfig.queryId,
                            queryOwner: environment.shareringConfig.queryOwner,
                        },
                        name,
                        description: 'Demo declaration published on chain',
                        // TODO: Pull this from the uri
                        formConfig: [
                            {
                                attributeKey: 'declaration.fact',
                                label: 'Declaration Fact',
                                type: 'text',
                            },
                        ],
                    });
                }
                return declarations;
            },
        );
        this.logger.log(
            `${this.declarations_cache.length} Declarations loaded from chain`,
        );
        return this.declarations_cache;
    }

    getDeclarations(): Observable<Declaration[]> {
        if (environment.online) {
            return from(this.getDeclarationsOnline());
        }
        return this.getDeclarationsOffline();
    }

    getDeclaration(id: string): Observable<Declaration> {
        if (environment.online) {
            return from(this.getDeclarationsOnline()).pipe(
                map((declarations) => {
                    const declaration = declarations.find((d) => d.id === id);
                    if (!declaration) {
                        throw new Error(`Declaration not found with id: ${id}`);
                    }
                    return declaration;
                }),
            );
        }

        const declaration = this.declarations[id];

        if (!declaration) {
            throw new Error(`Declaration not found with id: ${id}`);
        }
        return of(declaration);
    }
}

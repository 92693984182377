<div class="mb-8">
  <div class="flex justify-between">
    @for (step of steps; track step.title) {
    <div class="flex flex-col items-center">
      <div 
        class="w-8 h-8 rounded-full flex items-center justify-center transition-colors duration-200"
        [class]="isCompleted()($index) ? 'bg-green-500 text-white' : 
                isCurrent()($index) ? 'bg-green-500 text-white border-2 border-white' :
                'bg-gray-200 text-gray-600'"
      >
        @if (isCompleted()($index)) {
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        } @else {
          {{ $index + 1 }}
        }
      </div>
      <div class="text-sm mt-2" [class.font-medium]="isCurrent()($index)">
        {{ step.title }}
      </div>
    </div>
    }
  </div>
</div> 
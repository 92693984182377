import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ethers } from 'ethers';
import { MerkleTree } from 'merkletreejs';
import { from, lastValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProofContractService } from './contract.service';

const sha256 = (message: string | Buffer | Uint8Array): string => {
    if (typeof message === 'string') {
        const hash = CryptoJS.SHA256(message);
        return hash.toString(CryptoJS.enc.Hex);
    }

    // Convert Buffer/Uint8Array to WordArray that CryptoJS can understand
    const wordArray = CryptoJS.lib.WordArray.create(message as any);
    const hash = CryptoJS.SHA256(wordArray);
    return hash.toString(CryptoJS.enc.Hex);
};

export enum VerificationLevel {
    Undefined = 0,
    Revoked = 1,
    Checked = 2,
    Verified = 3,
}

export interface AttributeVerificationResult {
    verificationLevel: VerificationLevel;
    attributeHashMatched: boolean;
    merkleOnchainMatched: boolean;
    merkleOffchainMatched: boolean;
}

export interface VerificationAttributes {
    [key: string]: AttributeVerificationResult;
}

export interface VerificationResult {
    ownerEtheriumAddress: string;
    ownerMatched: boolean;
    attributes: VerificationAttributes;
}

@Injectable({
    providedIn: 'root',
})
export class VerificationService {
    constructor(private contractService: ProofContractService) {}

    private mapComputedAttributes(attrName: string) {
        switch (attrName) {
            case 'age':
                return 'date_of_birth';
            case 'country':
                return 'nationality';
            default:
                return attrName;
        }
    }

    async runVerification(values: any) {
        const verificationResult: VerificationResult = {
            ownerMatched: false,
            ownerEtheriumAddress: '',
            attributes: {},
        };

        // extract attributes, take vct token id (did) and addresses
        const {
            vct,
            ShareLedger_Address: shareledgerAddress,
            Matic_Address: ethereumAddress,
            ...attributes
        } = values;

        // 1. check token owner
        const tokenIndex = await lastValueFrom(this.didTokenIdToTokenId(vct));
        const ownerEtheriumAddress = await lastValueFrom(
            this.ownerOf(tokenIndex),
        );

        verificationResult.ownerEtheriumAddress = ownerEtheriumAddress;
        verificationResult.ownerMatched =
            ethereumAddress.toLowerCase() ===
            ownerEtheriumAddress.toLowerCase();

        const verificationResults: VerificationAttributes = {};

        // get merkle root
        const merkleRoot = await lastValueFrom(this.getMerkleRoot(vct));
        for (const attributeName of Object.keys(attributes)) {
            let attrName = attributeName;

            const attrNameArr = attributeName.split('.');
            if (attrNameArr.length === 1) {
                // <attr>
                attrName = attributeName;
            } else if (attrNameArr.length < 3) {
                // <attr>.<level>
                attrName = attrNameArr[0];
            } else {
                // <doc>.<attr>.<level>.<something>...
                attrName = attrNameArr[1];
            }

            verificationResults[attributeName] = {
                attributeHashMatched: false,
                merkleOffchainMatched: false,
                merkleOnchainMatched: false,
                verificationLevel: VerificationLevel.Undefined,
            };

            // parse attribute value
            try {
                const [attrValue, attrValueHashFromDevice, proofs] = JSON.parse(
                    attributes[attributeName],
                );

                let attrNameHash: string;
                let attrValueHash: string;

                if (Array.isArray(attrValue)) {
                    const [countryCode, docType, value] = attrValue;
                    attrNameHash = sha256(
                        `${countryCode.toLowerCase()}.${docType.toLowerCase()}.${attrName}`,
                    );
                    attrValueHash = sha256(
                        `${countryCode.toLowerCase()}.${docType.toLowerCase()}.${attrName}.${value}`,
                    );
                } else {
                    const personAttrName = this.mapComputedAttributes(attrName);
                    attrNameHash = sha256(personAttrName);
                    attrValueHash = sha256(`${personAttrName}.${attrValue}`);
                }

                // 2. check attribute hash
                if (attrValueHashFromDevice === attrValueHash) {
                    verificationResults[attributeName].attributeHashMatched =
                        true;
                }

                // 3. verify proofs off chain
                const merkleTree = new MerkleTree([], sha256, { sort: true });
                verificationResults[attributeName].merkleOffchainMatched =
                    merkleTree.verify(proofs, attrValueHash, merkleRoot);

                const [level, verified] = await Promise.all([
                    // get verification level
                    lastValueFrom(this.getAttributesData(vct, attrNameHash)),
                    // 4. verify proofs on chain
                    lastValueFrom(
                        this.verifyAttribute(vct, attrValueHash, proofs),
                    ),
                ]);

                verificationResults[attributeName].verificationLevel = level;
                verificationResults[attributeName].merkleOnchainMatched =
                    verified;
            } catch (error) {
                console.error(
                    `Error verifying ${attributes[attributeName]}: `,
                    error,
                );
            }
        }
        console.log('verificationResults: ', verificationResults);
        return verificationResults;
    }

    didTokenIdToTokenId(didTokenId: string) {
        return from(this.contractService.getVCTContract()).pipe(
            switchMap(async (instance) => {
                const result = await instance.contract().didTokenIdToTokenId({
                    didTokenId,
                });
                return result.data[0].value;
            }),
        );
    }

    ownerOf(tokenIndex: bigint) {
        const erc721TokenId = ethers.toBigInt(tokenIndex);

        return from(this.contractService.getVCTContract()).pipe(
            switchMap(async (contract) => {
                const result = await contract.contract().ownerOf({
                    tokenId: erc721TokenId,
                });
                return result.data[0].value as unknown as string;
            }),
        );
    }
    getMerkleRoot(tokenId: string) {
        return from(this.contractService.getVCTContract()).pipe(
            switchMap(async (contract) => {
                const result = await contract
                    .contract()
                    .getMerkleRoot({ didTokenId: tokenId });
                return result.data[0].value as unknown as string;
            }),
        );
    }

    getAttributesData(tokenId: string, attrNameHash: string) {
        return from(this.contractService.getVCTContract()).pipe(
            switchMap(async (contract) => {
                const result = await contract.contract().getAttributesData({
                    didTokenId: tokenId,
                    attribute: this.stringToHex(
                        attrNameHash,
                    ) as unknown as `0x${string}`,
                });
                return result.data[0].value as unknown as VerificationLevel;
            }),
        );
    }

    verifyAttribute(tokenId: string, attrValueHash: string, proofs: string[]) {
        return from(this.contractService.getVCTContract()).pipe(
            switchMap(async (contract) => {
                const result = await contract.contract().verifyAttribute({
                    didTokenId: tokenId,
                    attribute: this.stringToHex(
                        attrValueHash,
                    ) as unknown as `0x${string}`,
                    merkleProof: proofs.map(
                        (p) => this.stringToHex(p) as unknown as `0x${string}`,
                    ),
                });

                return result.data[0].value as unknown as boolean;
            }),
        );
    }
    private stringToHex(str: string): `0x${string}` {
        // Remove '0x' prefix if present
        const cleanStr = str.startsWith('0x') ? str.slice(2) : str;
        // Ensure even length
        const paddedStr = cleanStr.length % 2 ? `0${cleanStr}` : cleanStr;
        return `0x${paddedStr}` as `0x${string}`;
    }
}

import axios from 'axios';
import { Observable, from, mergeMap } from 'rxjs';
import { getHeaders } from '../util/headers';
import { CallDeployment, IContractCommandClient } from './types';

export class ContractCommandClient implements IContractCommandClient {
    constructor(
        private readonly baseUrl: string,
        private readonly headers: () => Promise<Record<string, string>>,
    ) {
        this.baseUrl = `${baseUrl}/contract`;
    }

    callDeployment(query: CallDeployment): Observable<{ queryId: string }> {
        return from(this.headers()).pipe(
            mergeMap((headers) => {
                return axios
                    .post<{ queryId: string }>(
                        `${this.baseUrl}/deployment/${query.deploymentId}/call`,
                        query,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                ...headers,
                            },
                        },
                    )
                    .then((res) => res.data);
            }),
        );
    }
}

import { Injectable } from '@angular/core';
import { Observable, from, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { ProofEvent } from '../shared/interfaces/proof.interface';
import { ProofContractService } from './contract.service';
import { EventService } from './event.service';
import { WalletService } from './wallet.service';

export type ERC20LinkType = {
    type: 'erc20';
    contractAddress: string;
    userBalance: bigint;
};

export type NFTLinkType = {
    type: 'nft';
    tokenId: bigint;
    contractAddress: string;
};

export type WalletLinkType = {
    type: 'wallet';
    walletAddress: string;
};

export type TransactionLinkType = {
    type: 'transaction';
    txHash: bigint;
};

export type LinkData =
    | ERC20LinkType
    | NFTLinkType
    | WalletLinkType
    | TransactionLinkType;

@Injectable({
    providedIn: 'root',
})
export class LinkService {
    constructor(
        private readonly contractService: ProofContractService,
        private readonly walletService: WalletService,
        private readonly eventService: EventService,
    ) {}

    async requestLinkOnline(proofTokenId: string, linkData: LinkData) {
        const proof = await lastValueFrom(this.eventService.get(proofTokenId));
        if (proof.status !== 'approved') {
            throw new Error(
                `Proof ${proofTokenId} is not in a valid state for linking. Status: ${proof.status}`,
            );
        }

        const contract = await this.contractService.getProofLinkContract();
        const proof_contract = await this.contractService.getProofContract();
        const admin_wallet = await this.walletService.adminWallet();

        {
            const tokenOwner = await proof_contract
                .set_wallet(admin_wallet.id)
                .execute('ownerOf', {
                    tokenId: BigInt(proofTokenId),
                });

            if (tokenOwner.data[0].value !== contract.address) {
                {
                    const tx = await proof_contract
                        .set_wallet(admin_wallet.id)
                        .execute('approve', {
                            to: contract.address,
                            tokenId: BigInt(proofTokenId),
                        });
                    const res = await tx.wait();
                    const approveLog = res.execution.logs.find(
                        (log) => log.name === 'Approval',
                    );
                    if (!approveLog) {
                        throw new Error('Approval log not found');
                    }
                    console.log('Approval Log', approveLog);
                }

                {
                    const tx = await contract
                        .set_wallet(admin_wallet.id)
                        .execute('depositFor', {
                            account: admin_wallet.address,
                            proofTokenIds: [BigInt(proofTokenId)],
                        });

                    const res = await tx.wait();
                    const depositLog = res.execution.logs.find(
                        (log) => log.name === 'Transfer',
                    );
                    if (!depositLog) {
                        throw new Error('Deposit log not found');
                    }
                    console.log('Deposit Log', depositLog);
                }
            }
        }
        // biome-ignore lint/suspicious/noImplicitAnyLet: will be inferred from bellow
        let tx;
        switch (linkData.type) {
            case 'erc20':
                tx = await contract
                    .set_wallet(admin_wallet.id)
                    .execute('createERC20Link', {
                        tokenId: BigInt(proofTokenId),
                        contractAddress: linkData.contractAddress,
                        userBalance: linkData.userBalance,
                    });
                break;
            case 'nft':
                tx = await contract
                    .set_wallet(admin_wallet.id)
                    .execute('createNFTLink', {
                        tokenId: BigInt(proofTokenId),
                        targetTokenId: linkData.tokenId,
                        contractAddress: linkData.contractAddress,
                    });
                break;
            case 'wallet':
                tx = await contract
                    .set_wallet(admin_wallet.id)
                    .execute('createWalletLink', {
                        tokenId: BigInt(proofTokenId),
                        walletAddress: linkData.walletAddress,
                    });
                break;
            case 'transaction':
                tx = await contract
                    .set_wallet(admin_wallet.id)
                    .execute('createTransactionLink', {
                        tokenId: BigInt(proofTokenId),
                        transactionHash: linkData.txHash,
                    });
                break;

            default:
                throw new Error(`Invalid link type: ${linkData}`);
        }

        const receipt = await tx.wait();

        const linkLog = receipt.execution.logs.find(
            (log) => log.name === 'LinkDataSet',
        );
        if (!linkLog) {
            throw new Error('Link log not found');
        }
        console.log('Link Log', linkLog);

        const linkId = linkLog.params.tokenId;

        const linkRequestEvent: ProofEvent = {
            type: 'link-requested',
            txHash: receipt.tx.hash,
            aggId: proofTokenId,
            eventTime: new Date(),
            requestedBy: admin_wallet.id,
            walletAddress: admin_wallet.address,
            requestedAt: new Date().toISOString(),
            linkRequest: {
                id: linkId.value.toString(),
                customerId: admin_wallet.id,
                proofId: proofTokenId,
                targetType: linkData.type,
                targetAttributes: linkData as Record<string, string>,
            },
        };

        return await this.eventService.apply(linkRequestEvent).toPromise();
    }

    requestLinkOffline(proofTokenId: string, linkData: LinkData) {
        const linkRequestEvent: ProofEvent = {
            type: 'link-requested',
            txHash: '0x0',
            aggId: proofTokenId,
            eventTime: new Date(),
            requestedBy: 'offline-user',
            walletAddress: '0x0',
            requestedAt: new Date().toISOString(),
            linkRequest: {
                id: `link-${Date.now()}`,
                customerId: 'offline-user',
                proofId: proofTokenId,
                targetType: linkData.type,
                targetAttributes: linkData as Record<string, string>,
            },
        };

        return this.eventService.apply(linkRequestEvent).toPromise();
    }

    requestLink(proofTokenId: string, linkData: LinkData): Observable<any> {
        if (environment.online) {
            return from(this.requestLinkOnline(proofTokenId, linkData));
        }
        return from(this.requestLinkOffline(proofTokenId, linkData));
    }

    async reviewLinkOnline(
        linkId: string,
        status: 'approved' | 'rejected',
        notes: string,
    ) {
        const contract = await this.contractService.getProofLinkContract();
        const admin_wallet = await this.walletService.adminWallet();

        const tx = await contract
            .set_wallet(admin_wallet.id)
            .execute('reviewLink', {
                tokenId: BigInt(linkId),
                approved: status === 'approved',
            });
        const receipt = await tx.wait();

        const linkLog = receipt.execution.logs.find(
            (log) => log.name === 'LinkStatusUpdated',
        );
        if (!linkLog) {
            throw new Error('Link log not found');
        }

        const linkReviewedEvent: ProofEvent = {
            type: 'link-reviewed',
            txHash: receipt.tx.hash,
            aggId: linkId,
            eventTime: new Date(),
            reviewedBy: admin_wallet.id,
            walletAddress: admin_wallet.address,
            reviewedAt: new Date().toISOString(),
            status,
            notes,
        };

        return await this.eventService.apply(linkReviewedEvent).toPromise();
    }

    reviewLinkOffline(
        linkId: string,
        status: 'approved' | 'rejected',
        notes: string,
    ) {
        const linkReviewedEvent: ProofEvent = {
            type: 'link-reviewed',
            txHash: '0x0',
            aggId: linkId,
            eventTime: new Date(),
            reviewedBy: 'offline-admin',
            walletAddress: '0x0',
            reviewedAt: new Date().toISOString(),
            status,
            notes,
        };

        return this.eventService.apply(linkReviewedEvent).toPromise();
    }

    reviewLink(
        linkId: string,
        status: 'approved' | 'rejected',
        notes: string,
    ): Observable<any> {
        if (environment.online) {
            return from(this.reviewLinkOnline(linkId, status, notes));
        }
        return from(this.reviewLinkOffline(linkId, status, notes));
    }
}

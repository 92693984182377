import { Component } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterModule, RouterOutlet],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'fsco-proof';

    constructor(private readonly router: Router) {}

    logout() {
        this.router.navigate(['logout']);
    }

    getYear() {
        return new Date().getFullYear();
    }

    get enableLogin() {
        return environment.enableLogin;
    }
}

<fsco-button (click)="showing.set(true)" >{{buttonText}}</fsco-button>
@if (showing()) {
<div class="relative z-10" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <!-- Header -->
                <div class="bg-white px-4 pb-4 pt-5 sm:p-6">
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                            <div class="flex justify-between items-center">
                                <h3 class="text-xl font-semibold text-gray-900">{{title}}</h3>
                                <button 
                                    (click)="showing.set(false)" 
                                    class="text-gray-500 hover:text-gray-700 focus:outline-none"
                                    aria-label="Close dialog">
                                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>
                            <!-- Content -->
                            <div class="mt-4">
                                <ng-content></ng-content>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}

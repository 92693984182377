import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
    ButtonComponent,
    ExplorerService,
    ProvisionLoader,
} from '@fsco/shared';
import { ExternalLink, LucideAngularModule } from 'lucide-angular';
import { environment } from '../../../../environments/environment';
import { ProofState } from '../../interfaces/proof.interface';

@Component({
    selector: 'app-proof-status-indicator',
    templateUrl: './proof-status-indicator.component.html',
    standalone: true,
    imports: [CommonModule, ButtonComponent, LucideAngularModule],
})
export class ProofStatusIndicatorComponent {
    @Input() proofState!: ProofState;

    private provisionLoader!: ProvisionLoader<typeof environment.did>;

    externalLinkIcon = ExternalLink;

    constructor(public explorerService: ExplorerService) {
        this.provisionLoader = new ProvisionLoader(environment.did);
    }

    getReviewedAt(proofState: ProofState): Date | null {
        return (
            proofState.events.find((event) => event.type === 'reviewed')
                ?.eventTime || null
        );
    }

    getProofOwner(proofState: ProofState): string | null {
        return (
            proofState.events.find((event) => event.type === 'requested')
                ?.walletAddress ?? null
        );
    }

    getProofNFT(proofState: ProofState): string | null {
        return (
            proofState.events.find((event) => event.type === 'requested')
                ?.aggId ?? null
        );
    }

    getContractAddress(_proofState: ProofState): string | null {
        return this.provisionLoader.contract(
            environment.chainConfig.contracts.proofs,
        ).address;
    }

    getRequestEvent(proofState: ProofState): { txHash: string } | null {
        return (
            proofState.events.find((event) => event.type === 'requested') ??
            null
        );
    }

    getReviewEvent(proofState: ProofState): { txHash: string } | null {
        return (
            proofState.events.find((event) => event.type === 'reviewed') ?? null
        );
    }

    openExternalLink(url: string): void {
        if (!url) return;
        window.open(url, '_blank');
    }
}

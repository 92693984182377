import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  map,
  of
} from 'rxjs';
import { getEnvironment } from '../../environments/environment';
import { AUTH_TOKEN_VERSION } from '../auth/classes/user';
import { AuthSDK } from '../auth/sdk';
import { Environment } from '../config/environment.token';
import { UserLogin } from '../models/session.model';
import { Logger } from '../utils/logger';

export const getTokenExpirationDate = (token: string): Date => {
    if (!token) throw new Error('Token is required');
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) throw new Error('Invalid token');

    const payload = JSON.parse(atob(tokenParts[1]));
    return new Date(payload.exp * 1000);
};

export const isTokenExpired = (token: string): boolean => {
    const expirationDate = getTokenExpirationDate(token);
    const currentTime = Date.now();
    return currentTime >= expirationDate.getTime() - 30000;
};

// Service class that handles auth state and business logic
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    logger = new Logger('AuthService');
    tokens: BehaviorSubject<UserLogin | null>;

    private environment: Environment = getEnvironment();
    private sdk: AuthSDK;
    private refreshTimeout: NodeJS.Timeout | null = null;

    constructor(private httpService: HttpClient) {
        this.logger.log('Initialized');
        this.sdk = new AuthSDK(this.environment, this.httpService);
        const tokens = this.load();
        this.tokens = new BehaviorSubject<UserLogin | null>(tokens);

        this.tokens.subscribe((tokens) => {
            if (tokens?.token) {
                this.save(tokens);
                this.scheduleTokenRefresh(tokens);
            }
        });
    }

    scheduleTokenRefresh(tokens: UserLogin | null) {
        if (tokens?.token) {
            this.logger.log('Scheduling Token Refresh', JSON.stringify(tokens));
            // Clear any existing timeout
            if (this.refreshTimeout) {
                clearTimeout(this.refreshTimeout);
            }

            const expirationDate = getTokenExpirationDate(tokens.token);
            const refreshExpirationDate = getTokenExpirationDate(tokens.refresh_token);
            const currentTime = new Date();
            // 1 minutes before token or refresh token expiry
            const nearestExpirationTime = Math.min(
                expirationDate.getTime() - currentTime.getTime() - 60 * 1000, // 1 minutes before expiry
                refreshExpirationDate.getTime() - currentTime.getTime() - 60 * 1000, // 1 minutes before expiry
            )
            const timeUntilRefresh = Math.max(
                0,
                nearestExpirationTime,
            );

            this.logger.log('Token Refresh Status', {
                currentTime: currentTime.toISOString(),
                expirationDate: new Date(currentTime.getTime() + timeUntilRefresh).toISOString(),
                timeUntilRefresh,
                expired: timeUntilRefresh <= 0,
            });

            if (timeUntilRefresh > 60 * 1000) {
                this.refreshTimeout = setTimeout(() => {
                    this.refreshToken(tokens.refresh_token);
                }, timeUntilRefresh);
                this.logger.log('Token Refresh Scheduled', timeUntilRefresh);
            } else {
                this.logger.warn('Token already expired or expiring too soon');
                // Attempt immediate refresh
                this.refreshToken(tokens.refresh_token);
            }
        }
    }

    load(): UserLogin | null {
        const contents = localStorage.getItem(this.environment.appName);
        if (!contents) {
            this.logger.log('No Cached Token Found');
            return null;
        }
        const tokens = JSON.parse(contents) as UserLogin;
        if (tokens.version !== AUTH_TOKEN_VERSION) {
            this.logger.log('Cached Token Version Mismatch');
            this.save(null);
            return null;
        }
        if (tokens.token && !isTokenExpired(tokens.token)) {
            this.logger.log('Cache Token Not Expired');
            return tokens;
        }
        if (tokens.refresh_token && !isTokenExpired(tokens.refresh_token)) {
            this.logger.log('Refresh Token Not Expired');
            return tokens;
        }
        this.logger.log('All Tokens Expired Login Required');
        return null;
    }

    save(tokens: UserLogin | null) {
        this.logger.log('Saving User Token', tokens);
        if (tokens) {
            localStorage.setItem(
                this.environment.appName,
                JSON.stringify(tokens),
            );
        } else {
            localStorage.removeItem(this.environment.appName);
        }
    }

    getRedirectUrl(): string {
        return `${window.location.origin}/login`;
    }

    getLogoutRedirectUrl(): string {
        return `${window.location.origin}/logout`;
    }

    loginUrl(): string {
        const redirectUrl = this.getRedirectUrl();
        const url = this.sdk.getLoginUrl(redirectUrl);
        return `${url}&logout=true`;
    }

    logoutUrl(): string {
        const redirectUrl = this.getLogoutRedirectUrl();
        const url = this.sdk.getLogoutUrl(redirectUrl);
        return url;
    }

    clearLocalStorageAndCookies(): void {
        localStorage.clear();
        for (const c of document.cookie.split(';')) {
            document.cookie = c
                .replace(/^ +/, '')
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        }
    }

    exchangeGrantCodeAndSetAuthToken(
        code: string,
        _state: string,
    ): Promise<UserLogin | null> {
        const redirectUrl = this.getRedirectUrl();
        return firstValueFrom(
            this.sdk.exchangeCode(code, redirectUrl).pipe(
                catchError((error) => {
                    this.logger.error('Error exchanging grant code:', error);
                    return of(null);
                }),
                map((response) => {
                    this.logger.log(
                        'exchangeGrantCodeAndSetAuthToken response',
                        response,
                    );
                    if (!response?.access_token) {
                        return null;
                    }
                    return {
                        token: response.access_token,
                        refresh_token: response.refresh_token,
                        version: AUTH_TOKEN_VERSION,
                    };
                }),
                map((tokens) => {
                    this.tokens.next(tokens);
                    return tokens;
                }),
            ),
        );
    }

    refreshToken(refreshToken: string): Promise<UserLogin | null> {
        this.logger.log('Refreshing Token', refreshToken);
        return firstValueFrom(
            this.sdk.refreshToken(refreshToken).pipe(
                catchError((error) => {
                    this.logger.error('Error refreshing token:', error);
                    return of(null);
                }),
                map((response) => {
                    this.logger.log('refreshToken response', response);
                    if (!response?.access_token) {
                        return null;
                    }
                    return {
                        token: response.access_token,
                        refresh_token: response.refresh_token,
                        version: AUTH_TOKEN_VERSION,
                    };
                }),
                map((tokens) => {
                    this.tokens.next(tokens);
                    return tokens;
                }),
            ),
        );
    }
}

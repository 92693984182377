<div class="overflow-x-auto">
    <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
            <tr>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Attribute
                </th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Verified
                </th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Value
                </th>
            </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
            @for (attr of evidence.attributes | keyvalue; track attr.key) {
            @if (attr.key !== 'ShareLedger_Address' && attr.key !== 'Matic_Address' && attr.key !== 'vct') {
            <tr>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {{ attr.key }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex justify-left">
                    @if (isStatus(attr.key, 'verified')) {
                    <i-lucide [name]="checkCircle2Icon" class="w-5 h-5 text-green-500"></i-lucide>
                    } @else if (isStatus(attr.key, 'verifiedFailed')) {
                    <i-lucide [name]="circleMinusIcon" class="w-5 h-5 text-red-500"></i-lucide>
                    } @else if (isStatus(attr.key, 'pending')) {
                    <i-lucide [name]="loader2Icon" class="w-5 h-5 text-gray-500 animate-spin"></i-lucide>
                    } @else if (isStatus(attr.key, 'unverified')) {
                    <i-lucide [name]="xCircleIcon" class="w-5 h-5 text-gray-500"></i-lucide>
                    } @else {
                    <i-lucide [name]="xCircleIcon" class="w-5 h-5 text-gray-500"></i-lucide>
                    }
                </td>
                <td class="px-6 py-4 text-sm text-gray-500 max-w-xs break-words">
                    <div class="max-w-[200px] overflow-hidden">{{ attr.value }}</div>
                </td>
            </tr>
            }
            }
        </tbody>
    </table>
</div> 
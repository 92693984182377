import {
    Declaration,
    Evidence,
    ProofEvent,
} from '../interfaces/proof.interface';

export const getMockDeclarations = (): Record<string, Declaration> => {
    // Mock data - replace with actual API endpoints
    return {
        '0': {
            id: '0',
            version: '1',
            uri: 'https://example.com/bernie-age-check-prod',
            shareRing: {
                clientId: '',
                queryId: '672164c94773658a42db991e',
                queryOwner:
                    'shareledger1w72qu00nxlnukag60f6mp79q7xxjl4shec37x0',
            },
            name: 'Bernie Age Check on prod',
            description: 'Verify that you are over the required drinking age',
            formConfig: [
                {
                    attributeKey: 'dateOfBirth',
                    label: 'Date of Birth',
                    type: 'date',
                },
                {
                    attributeKey: 'country',
                    label: 'Country',
                    type: 'text',
                },
            ],
        },
        '1': {
            id: '1',
            version: '1',
            uri: 'https://example.com/bernie-age-check-TEST-ENV',
            shareRing: {
                clientId: '6721d9e72718b9f0f30facc8',
                queryId: '6733f5af3e3e72b91a783eed',
                queryOwner:
                    'shareledger168mr67pqfxxdecpcxaqqkm9n0h9x4jnxsqk4n4',
            },
            name: 'Bernie Age Check on TEST ENV',
            description: 'Verify that you are over the required drinking age',
            formConfig: [
                {
                    attributeKey: 'drivers_license.number',
                    label: 'Drivers License Number',
                    type: 'text',
                },
                {
                    attributeKey: 'age',
                    label: 'Age',
                    type: 'text',
                },
            ],
        },
        '2': {
            id: '2',
            version: '1',
            uri: 'https://example.com/age-verification',
            shareRing: {
                clientId: '',
                queryId: '67206210a92f3777810b69bc',
                queryOwner:
                    'shareledger1rph3a4n7hy0knprt6szug4gnmg5gngtt57tjwq',
            },
            name: 'Drinking Age Verification',
            description: 'Verify that you are over the required drinking age',
            formConfig: [
                {
                    attributeKey: 'dateOfBirth',
                    label: 'Date of Birth',
                    type: 'date',
                },
                {
                    attributeKey: 'country',
                    label: 'Country',
                    type: 'text',
                },
            ],
        },
        '3': {
            id: '3',
            version: '1',
            uri: 'https://example.com/bank-balance',
            shareRing: {
                clientId: '',
                queryId: '6722faa73bf872028f3d2832',
                queryOwner:
                    'shareledger1rph3a4n7hy0knprt6szug4gnmg5gngtt57tjwq',
            },
            name: 'Bank Balance Verification',
            description: 'Verify that you maintain a minimum bank balance',
            formConfig: [
                {
                    attributeKey: 'accountNumber',
                    label: 'Account Number',
                    type: 'text',
                },
                {
                    attributeKey: 'minimumBalance',
                    label: 'Minimum Balance',
                    type: 'number',
                },
            ],
        },
        '4': {
            id: '4',
            version: '1',
            uri: 'https://example.com/company-verification',
            shareRing: {
                clientId: '',
                queryId: '6720760ca92f3777810b7078',
                queryOwner:
                    'shareledger1rph3a4n7hy0knprt6szug4gnmg5gngtt57tjwq',
            },
            name: 'Company Verification',
            description: 'Verify company registration and details',
            formConfig: [
                {
                    attributeKey: 'companyName',
                    label: 'Company Name',
                    type: 'text',
                },
            ],
        },
    };
};

export const getMockProof = (): ProofEvent[] => {
    const mockDeclarations = getMockDeclarations();
    const mockDeclaration = mockDeclarations['0'];

    const mockEvidence: Evidence = {
        id: 'mock-evidence',
        customerId: 'mock-customer',
        declarationId: 'mock-declaration',
        type: 'zk',
        provider: 'sharering',
        attributes: {
            'drivers_license.number.checked':
                '4afeb983f76184e930d21ffad4bf75a044613108d844bcb9893beea4e17a6dec',
            'age.checked':
                '["1992-04-28","06d89eb438d560d30b11c18028cf0acec1588b36f5e3afec56457a1e4fb29544",["231f08f89304e7944e7a478d00643e0a313efbbc56ab5634dfeb63c828150d47","0a6dff5494119509875a2bb82b9bd0b69e983e0ca34a39e125de7b3d49684fa1","841eb86cceb4aafb0f93925791a5c75a1b2c1296f43d41f7da15d72da4d803c9","81966fc45d4b74b75e98703147501b6db7dda745a51b8e7712005254e18e49a7"],0,12]',
            vct: 'did:shr:02080a628fd2ba2d0d0475eba6ed3ca2e1b48f5f8f048ca8c363b8f980dc473670',
            ShareLedger_Address:
                'shareledger168mr67pqfxxdecpcxaqqkm9n0h9x4jnxsqk4n4',
            Matic_Address: '0xA1a1D9Ee38Fd5d7F5fAD705f0E0D6243c94743C8',
        },
        verificationStatuses: {},
    };

    return [
        {
            type: 'requested',
            status: 'pending',
            aggId: '1',
            eventTime: new Date('2024-01-01'),
            requestedBy: 'test-user',
            requestedAt: '2024-01-01T00:00:00Z',
            declaration: mockDeclaration,
            evidence: mockEvidence,
            txHash: 'mock-tx-hash-1', // Added missing txHash field
            walletAddress: '0x1234567890abcdef',
        },
        {
            type: 'reviewed',
            status: 'approved',
            aggId: '1',
            eventTime: new Date('2024-01-02'),
            reviewedBy: 'test-reviewer',
            reviewedAt: '2024-01-02T00:00:00Z',
            reviewNotes: 'Approved for testing',
            proof: {
                id: 'mock-proof',
                customerId: 'mock-customer',
                declarationId: 'mock-declaration',
                status: 'approved',
                evidenceHash: 'mock-hash',
            },
            txHash: 'mock-tx-hash-2', // Added missing txHash field
            walletAddress: '0x1234567890abcdef',
        },
    ];
};

<div class="login flex items-center justify-center h-screen">
  <div class="flex flex-col justify-center items-center text-center">
    <div class="login-logo border-"></div>
    <div class="login-message text-gray-500 mt-2">
      <div *ngIf="!appChecksHasUserData(); else gettingUserData">
        <span class="text-gray-700">Loading</span> user data<span class="dots"></span>
      </div>
      <ng-template #gettingUserData>
        <span class="text-gray-700">Redirecting</span> to login<span class="dots"></span>
      </ng-template>
    </div>
  </div>
</div>

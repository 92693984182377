import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
    ButtonComponent,
    ModalComponent,
    StepProgressComponent,
} from '@fsco/shared';
import { finalize } from 'rxjs';
import { FSCOQueryComponent } from '../providers/fsco/query/fsco-query.component';
import { ShareRingQueryComponent } from '../providers/sharering/query2/sharering-query.component';
import { ProofService } from '../services/proof.service';
import { DeclarationFormComponent } from '../shared/components/declaration-form/declaration-form.component';
import { DeclarationSelectorComponent } from '../shared/components/declaration-selector/declaration-selector.component';
import { EvidenceDetailsComponent } from '../shared/components/evidence-details/evidence-details.component';
import { Declaration, Evidence } from '../shared/interfaces/proof.interface';

type ProofStep = {
    title: string;
    description: string;
    complete: boolean;
};

@Component({
    selector: 'app-create-proof',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonComponent,
        DeclarationFormComponent,
        DeclarationSelectorComponent,
        MatDialogModule,
        FSCOQueryComponent,
        ShareRingQueryComponent,
        ModalComponent,
        StepProgressComponent,
        EvidenceDetailsComponent,
    ],
    templateUrl: './create-proof.component.html',
    styles: [],
})
export class CreateProofComponent {
    currentStep = signal<number>(0);
    loading = signal<boolean>(false);
    evidenceData = signal<Evidence | null>(null);
    selectedDeclaration = signal<Declaration | undefined>(undefined);
    selectedFile: File | null = null;
    error = signal<string | null>(null);
    success = signal<boolean>(false);
    providerDataReceived = signal<boolean>(false);
    evidenceModalOpen = signal<boolean>(false);

    steps: ProofStep[] = [
        {
            title: 'Select Declaration Type',
            description: 'State what you want to prove',
            complete: false,
        },
        {
            title: 'Select Evidence Provider',
            description: 'Provide supporting documentation',
            complete: false,
        },
        {
            title: 'Review Proof',
            description: 'Provide the information you want to prove',
            complete: false,
        },
    ];

    constructor(
        private router: Router,
        private proofService: ProofService,
    ) {}

    handleDeclarationSelect(declaration: Declaration): void {
        this.selectedDeclaration.set(declaration);
    }

    handleEvidenceSubmit(data: Evidence) {
        console.log('handleEvidenceSubmit', data);
        this.providerDataReceived.set(true);
        this.evidenceData.set(data);
        this.evidenceModalOpen.set(false);
        this.nextStep();
    }

    handleReviewSubmit(): void {
        const declarationData = this.selectedDeclaration();
        const evidenceData = this.evidenceData();

        if (!declarationData || !evidenceData) {
            this.error.set('Please provide both declaration and evidence');
            return;
        }

        this.loading.set(true);
        this.error.set(null);

        // Create the proof request using the declaration service
        this.proofService
            .createProofRequest(declarationData.id, evidenceData)
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe({
                next: (response) => {
                    this.success.set(true);
                    setTimeout(() => {
                        this.router.navigate(['/dashboard'], {
                            queryParams: { requestId: response.id },
                        });
                    }, 500);
                },
                error: (err: Error) => {
                    this.error.set(
                        'Failed to submit proof request. Please try again.',
                    );
                    console.error('Error submitting proof:', err);
                },
            });
    }

    nextStep(): void {
        if (this.currentStep() < this.steps.length - 1) {
            this.steps[this.currentStep()].complete = true;
            this.currentStep.set(this.currentStep() + 1);
        }
    }

    previousStep(): void {
        if (this.currentStep() > 0) {
            this.currentStep.set(this.currentStep() - 1);
        }
    }

    canProceed(): boolean {
        switch (this.currentStep()) {
            case 0:
                return !!this.evidenceData();
            case 1:
                return !!this.selectedFile;
            case 2:
                return true;
            default:
                return false;
        }
    }

    getEvidenceEntries(): [string, any][] {
        if (!this.evidenceData()) return [];
        return Object.entries(this.evidenceData()?.attributes!);
    }
}

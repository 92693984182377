import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
    heroCog,
    heroDocumentDuplicate,
    heroLink,
    heroShieldCheck,
} from '@ng-icons/heroicons/outline';

@Component({
    selector: 'app-dashboard',
    standalone: true,
    imports: [RouterModule, CommonModule, NgIconComponent],
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [
        provideIcons({
            heroDocumentDuplicate,
            heroShieldCheck,
            heroLink,
            heroCog,
        }),
    ],
})
export class DashboardComponent {
    title = 'FSCO | Proof';
    roles = [
        {
            name: 'Create Proof',
            route: '/create-proof',
            icon: 'heroDocumentDuplicate',
            helperText:
                'Create a new zero-knowledge proof with supporting evidence.',
        },
        {
            name: 'Verify Proof',
            route: '/verify-proof',
            icon: 'heroShieldCheck',
            helperText:
                'Verify an existing proof and validate its authenticity.',
        },
        {
            name: 'Link Proof',
            route: '/link-proof',
            icon: 'heroLink',
            helperText: 'Link your proofs to a blockchain artifact.',
        },
        {
            name: 'Admin Review',
            route: '/admin/proof-review',
            icon: 'heroCog',
            helperText: 'Review and manage proof requests.',
            adminOnly: true,
        },
    ];

    // TODO: Replace with actual auth service check
    isAdmin = true; // Temporarily set to true for testing
}

<div class="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl mx-auto">
        <!-- Loading State -->
        @if (loading()) {
        <div class="flex justify-center items-center py-12">
            <i-lucide [name]="loader2Icon" class="text-3xl text-gray-600 animate-spin"></i-lucide>
        </div>
        }

        <!-- Error Message -->
        @if (error()) {
        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
            {{ error() }}
        </div>
        }

        @if (!loading() && !showDetails()) {
        <!-- Proof List View -->
        <div class="bg-white shadow rounded-lg p-6">
            <h2 class="text-lg font-medium text-gray-900 mb-4">Select a Proof to View Details</h2>
            <app-proof-table [allowedStatuses]="['approved']"
                (proofSelected)="handleProofSelect($event)"></app-proof-table>
        </div>
        }

        @if (!loading() && showDetails()) {
        <!-- Proof Details View -->
        @if (selectedProof(); as proof) {
        <div class="mb-4">
            <fsco-button (click)="backToList()">
                ← Back to List
            </fsco-button>
        </div>

        <div class="grid grid-cols-1 gap-6">
            <!-- Declaration Details -->
            <div class="bg-white rounded-lg shadow p-6">
                <h3 class="text-lg font-medium text-gray-900 mb-4">Declaration Details</h3>
                <div class="space-y-4">
                    <div>
                        <span class="text-sm font-medium text-gray-500">Name:</span>
                        <p class="mt-1 text-sm text-gray-900">{{ proof.declaration.name }}</p>
                    </div>
                    <div>
                        <span class="text-sm font-medium text-gray-500">Description:</span>
                        <p class="mt-1 text-sm text-gray-900">{{ proof.declaration.description }}</p>
                    </div>
                    <div>
                        <span class="text-sm font-medium text-gray-500">ID:</span>
                        <p class="mt-1 text-sm text-gray-900">{{ proof.declaration.id }}</p>
                    </div>
                </div>
            </div>

            <!-- On-Chain Proof Details -->
            <app-proof-status-indicator [proofState]="proof"></app-proof-status-indicator>

            <!-- Link Status Details -->
            @if (proof.status == 'approved') {
            <div class="bg-white rounded-lg shadow p-6">
                <h3 class="text-lg font-medium text-gray-900 mb-4">Link Status</h3>
                <div class="space-y-4">
                    <div class="flex items-center">
                        <span class="text-sm font-medium text-gray-500 mr-2">Status:</span>
                        <span [ngClass]="{
                    'bg-yellow-100 text-yellow-800': proof.linkStatus === 'pending',
                    'bg-green-100 text-green-800': proof.linkStatus === 'approved',
                    'bg-red-100 text-red-800': proof.linkStatus === 'rejected',
                    'bg-gray-100 text-gray-800': proof.linkStatus === 'unlinked'
                  }" class="px-2 py-1 text-xs rounded-full">
                            {{ proof.linkStatus }}
                        </span>
                    </div>

                    @if (proof.linkStatus === 'pending') {
                    <div>
                        <span class="text-sm font-medium text-gray-500">Requested By:</span>
                        <p class="mt-1 text-sm text-gray-900">{{ proof.linkRequestedBy }}</p>
                        <span class="text-sm font-medium text-gray-500">Requested At:</span>
                        <p class="mt-1 text-sm text-gray-900">{{ proof.linkRequestedAt | date:'medium' }}</p>
                    </div>
                    }

                    @if (proof.linkStatus === 'approved' || proof.linkStatus === 'rejected') {
                    <div>
                        <span class="text-sm font-medium text-gray-500">Reviewed By:</span>
                        <p class="mt-1 text-sm text-gray-900">{{ proof.linkReviewedBy }}</p>
                        <span class="text-sm font-medium text-gray-500">Reviewed At:</span>
                        <p class="mt-1 text-sm text-gray-900">{{ proof.linkReviewedAt | date:'medium' }}</p>
                    </div>
                    }

                    @if (proof.linkStatus !== 'unlinked') {
                    <div>
                        <span class="text-sm font-medium text-gray-500">Target Type:</span>
                        <p class="mt-1 text-sm text-gray-900">{{ proof.link.targetType }}</p>
                    </div>

                    <div>
                        <span class="text-sm font-medium text-gray-500">Target Details:</span>
                        <div class="mt-2 bg-gray-50 rounded-md p-4">
                            @for (attr of getLinkTargetAttributes(proof); track attr[0]) {
                            <div class="flex justify-between py-2">
                                <span class="font-medium text-gray-600">{{ attr[0] }}</span>
                                <span class="text-gray-900">{{ attr[1] }}</span>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
            </div>
            }


            <!-- Evidence Details -->
            <div class="bg-white rounded-lg shadow p-6">
                <h3 class="text-lg font-medium text-gray-900 mb-4">Proved Details</h3>
                <div class="space-y-4">
                    <app-evidence-details 
                        [evidence]="proof.evidence"
                        [loading]="loading()"
                    ></app-evidence-details>
                </div>
            </div>
        </div>
        }
        }
    </div>
</div>
import { InjectionToken } from '@angular/core';
import { LogLevel, LoggerConfig } from './logger.interface';

export const DEFAULT_LOGGER_CONFIG: LoggerConfig = {
    minLevel: LogLevel.DEBUG,
    enableConsole: true,
    enableTimestamp: true,
};

export const LOGGER_CONFIG = new InjectionToken<LoggerConfig>('LOGGER_CONFIG');

import { Routes } from '@angular/router';
import { ProofReviewComponent } from './admin/proof-review/proof-review.component';
import { CreateProofComponent } from './create-proof/create-proof.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LinkProofComponent } from './link-proof/link-proof.component';
import { VerifyProofComponent } from './verify-proof/verify-proof.component';

import { AuthGuard, LoginComponent, LogoutComponent, NoPermissionComponent } from '@fsco/shared';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-proof',
    component: CreateProofComponent,
    title: 'Create New Proof',
    canActivate: [AuthGuard],
  },
  {
    path: 'verify-proof',
    children: [
      {
        path: '',
        component: VerifyProofComponent
      },
      {
        path: ':id',
        component: VerifyProofComponent
      }
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'link-proof',
    component: LinkProofComponent,
    title: 'Link Proof to Wallet',
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/proof-review',
    component: ProofReviewComponent,
    title: 'Review Proof Requests',
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login',
  },
  {
    path: 'logout',
    component: LogoutComponent,
    title: 'Logout',
  },
  {
    path: 'no-permission',
    component: NoPermissionComponent,
    title: 'No Permission',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

<div class="container mx-auto p-4">
  <!-- Replace the old steps progress with the new component -->
  <fsco-step-progress [steps]="steps" [currentStep]="currentStep() + 1"></fsco-step-progress>

  <!-- Error Message -->
  @if (error()) {
  <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
    {{ error() }}
  </div>
  }

  <!-- Step Content -->
  <div class="mt-6">
    @switch (currentStep()) {
    @case (0) {
    <!-- Declaration Form Step -->
    <div class="max-w-lg mx-auto">
      <h2 class="text-xl font-bold mb-4">Select Declaration Type</h2>
      <div class="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
        <app-declaration-selector (declarationSelect)="handleDeclarationSelect($event)"></app-declaration-selector>
      </div>
      <div class="flex justify-between mt-6">
        <fsco-button (click)="previousStep()">
          Previous
        </fsco-button>
        <fsco-button (click)="nextStep()" [disabled]="!selectedDeclaration()">
          Next
        </fsco-button>
      </div>
    </div>
    }
    @case (1) {
    <!-- Evidence Provider Selector -->
    <div class="max-w-lg mx-auto">
      <h2 class="text-xl font-bold mb-4">Select Your Evidence Provider</h2>
      <div class="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
        <div class="space-y-4">
          <fsco-modal buttonText="FSCO | Proofs" [isOpen]="evidenceModalOpen()">
            <app-fsco-query [declaration]="selectedDeclaration()!"
              (evidenceSubmit)="handleEvidenceSubmit($event)"></app-fsco-query>
          </fsco-modal>
          <fsco-modal buttonText="ShareRing" [isOpen]="evidenceModalOpen()">
            <app-sharering-query [declaration]="selectedDeclaration()!" (evidenceSubmit)="handleEvidenceSubmit($event)">
            </app-sharering-query>
          </fsco-modal>
        </div>
      </div>
      <div class="flex justify-between mt-6">
        <fsco-button (click)="previousStep()">
          Previous
        </fsco-button>
        <fsco-button (click)="nextStep()" [disabled]="!providerDataReceived">
          Next
        </fsco-button>
      </div>
    </div>
    }
    @case (2) {
    <!-- Evidence Details -->
    <div class="bg-white rounded-lg shadow p-6">
      <h3 class="text-lg font-medium text-gray-900 mb-4">Review Your Submission</h3>
      <div class="space-y-4">
        @if (evidenceData()) {
          <app-evidence-details 
            [evidence]="evidenceData()!"
            [loading]="loading()"
          ></app-evidence-details>
        }
      </div>
    </div>

    <div class="flex justify-between mt-6">
      <fsco-button (click)="previousStep()">
        Previous
      </fsco-button>
      <fsco-button (click)="handleReviewSubmit()" [disabled]="loading()">
        Submit Proof
      </fsco-button>
    </div>
    }
    }
  </div>
</div>

import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import { ButtonComponent } from '@fsco/shared';
import { EventService } from '../../../services/event.service';
import { ProofState, ProofStatus } from '../../interfaces/proof.interface';

@Component({
    selector: 'app-proof-table',
    standalone: true,
    imports: [CommonModule, ButtonComponent],
    templateUrl: './proof-table.component.html',
})
export class ProofTableComponent implements OnInit {
    @Input() allowedStatuses: ProofStatus[] = [
        'pending',
        'approved',
        'rejected',
    ];
    @Output() proofSelected = new EventEmitter<ProofState>();

    proofs = signal<ProofState[]>([]);

    constructor(private readonly eventService: EventService) {}

    ngOnInit(): void {
        this.eventService.list(this.allowedStatuses).subscribe((proofs) => {
            this.proofs.set(proofs);
        });
    }

    getStatusClass(status: ProofStatus): string {
        switch (status) {
            case 'approved':
                return 'bg-green-100 text-green-800';
            case 'rejected':
                return 'bg-red-100 text-red-800';
            case 'pending':
                return 'bg-yellow-100 text-yellow-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    }

    onProofSelect(proof: ProofState): void {
        this.proofSelected.emit(proof);
    }

    getLastUpdateTime(proof: ProofState): string {
        switch (proof.status) {
            case 'pending':
                return new Date(proof.requestedAt).toISOString();
            case 'approved':
                switch (proof.linkStatus) {
                    case 'pending':
                        return new Date(proof.linkRequestedAt).toISOString();
                    case 'approved':
                        return new Date(proof.linkReviewedAt).toISOString();
                    case 'rejected':
                        return new Date(proof.linkReviewedAt).toISOString();
                    default:
                        return new Date(proof.reviewedAt).toISOString();
                }
            case 'rejected':
                return new Date(proof.reviewedAt).toISOString();
        }
    }

    isStatusAllowed(status: ProofStatus): boolean {
        return this.allowedStatuses.includes(status);
    }
}

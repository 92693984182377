import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { AttestationManagerAbi } from './abis/fiat-collateralisation/AttestationManager';
import { CollateralizedTokenAbi } from './abis/fiat-collateralisation/CollateralizedToken';
import { CollateralizedTokenFactoryAbi } from './abis/fiat-collateralisation/CollateralizedTokenFactory';
import { LiquidityProviderFactoryAbi } from './abis/liquidity-provider/LiquidityProviderFactory';
import { PairAbi } from './abis/liquidity-provider/Pair';
import { RouterAbi } from './abis/liquidity-provider/Router';
import { DocumentVerifiableCredentialsTokenAbi } from './abis/vct/DocumentVerifiableCredentialsToken';
import { VerifiableCredentialsTokenAbi } from './abis/vct/VerifiableCredentialsToken';

import { SessionService } from '@fsco/shared';
import { Abi } from 'abitype';
import { getEnvironment } from '../../environments/environment';
import { Environment } from '../config/environment.token';
import { getContractsEnvironment } from '../contracts/environment/contracts.environment';
import { ContractSdk } from './sdk/sdk';

@Injectable({
    providedIn: 'root',
})
export class ContractLoader {
    private sdk: ContractSdk;
    private environment: Environment = getEnvironment();
    private contractsEnvironment = getContractsEnvironment();

    constructor(private readonly sessionService: SessionService) {
        this.sdk = new ContractSdk(this.environment.apiUrl, async () =>
            this.sessionService.authHeaders(),
        );
    }

    async addressChainToDeploymentId(address: string, chainId: number) {
        const deployments = await lastValueFrom(
            this.sdk.query.searchDeployments({
                contractAddress: [address],
                chainId: [chainId],
                take: 1,
            }),
        );
        if (deployments.response.length === 0) {
            throw new Error('Deployment not found');
        }
        return deployments.response[0].deploymentId;
    }

    async getContractByAddress<T extends Abi>(abi: T, address: string) {
        const deploymentId = await this.addressChainToDeploymentId(
            address,
            31337,
        );
        return this.sdk.factory.getContract(abi, {
            deploymentId,
        });
    }
    getContractById<T extends Abi>(abi: T, deploymentId: string) {
        return this.sdk.factory.getContract(abi, {
            deploymentId,
        });
    }

    getCollateralizedTokenFactoryContract() {
        return this.sdk.factory.getContract(CollateralizedTokenFactoryAbi, {
            deploymentId:
                this.contractsEnvironment.tempEnvironment
                    .collateralizedTokenFactoryId,
        });
    }

    getAttestationManagerContract(deploymentId: string) {
        return this.sdk.factory.getContract(AttestationManagerAbi, {
            deploymentId,
        });
    }

    getCollateralizedTokenContract(deploymentId: string) {
        return this.sdk.factory.getContract(CollateralizedTokenAbi, {
            deploymentId,
        });
    }

    getLiquidityProviderFactoryContract() {
        return this.sdk.factory.getContract(LiquidityProviderFactoryAbi, {
            deploymentId:
                this.contractsEnvironment.tempEnvironment
                    .liquidityProviderFactoryId,
        });
    }

    getPairContract(deploymentId: string) {
        return this.sdk.factory.getContract(PairAbi, {
            deploymentId,
        });
    }

    getRouterContract() {
        return this.sdk.factory.getContract(RouterAbi, {
            deploymentId: this.contractsEnvironment.tempEnvironment.routerId,
        });
    }
    getVerifiableCredentialsTokenContract() {
        return this.sdk.factory.getContract(VerifiableCredentialsTokenAbi, {
            deploymentId:
                this.contractsEnvironment.tempEnvironment
                    .verifiableCredentialsTokenId,
        });
    }
    getDocumentVerifiableCredentialsTokenContract() {
        return this.sdk.factory.getContract(
            DocumentVerifiableCredentialsTokenAbi,
            {
                deploymentId:
                    this.contractsEnvironment.tempEnvironment
                        .documentVerifiableCredentialsTokenId,
            },
        );
    }
}

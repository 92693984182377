import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    ViewChild,
} from '@angular/core';
import { ButtonComponent } from '@fsco/shared';
import { QRCodeModule } from 'angularx-qrcode';
import { ShareRingService } from '../../../services/sharering.service';
import {
    Declaration,
    Evidence,
    VerificationStatus,
} from '../../../shared/interfaces/proof.interface';

@Component({
    selector: 'app-sharering-query',
    standalone: true,
    imports: [CommonModule, ButtonComponent, QRCodeModule],
    templateUrl: './sharering-query.component.html',
    styles: [
        `
    :host {
      display: block;
    }

    .qr-container {
      @apply flex justify-center items-center p-4;
    }

    #qrcode {
      @apply w-[400px] h-[400px];
    }
  `,
    ],
})
export class ShareRingQueryComponent implements OnInit {
    @ViewChild('qrcodeElement') qrcodeElement!: ElementRef;

    @Input() declaration!: Declaration;
    @Output() evidenceSubmit = new EventEmitter<Evidence>();

    loading = signal<boolean>(false);
    error = signal<string | null>(null);
    success = signal<boolean>(false);

    qrcodeUrl = signal<string | null>(null);

    constructor(private shareRingService: ShareRingService) { }

    private checkAttributes(attributes: Record<string, string>) {
        if (Object.keys(attributes).length === 0) {
            return;
        }

        this.evidenceSubmit.emit({
            attributes,
            declarationId: this.declaration.id,
            id: `evidence-${Date.now()}`,
            type: 'zk',
            provider: 'sharering',
            customerId: 'customer-id',
            verificationStatuses: Object.keys(attributes).reduce(
                (acc, key) => {
                    acc[key] = VerificationStatus.Unverified;
                    return acc;
                },
                {} as Record<string, VerificationStatus>,
            ),
        });
    }

    ngOnInit() {
        this.shareRingService
            .startQR(
                this.declaration.shareRing.clientId,
                this.declaration.shareRing.queryId,
                this.declaration.shareRing.queryOwner,
            )
            .subscribe((data) => {
                // generate QR code
                this.qrcodeUrl.set(data.qrURL);
                // check the values from metadata, shows that the data has been received from other provider
                this.checkAttributes(data.attributes as Record<string, string>);
            });
    }
}

export const LiquidityProviderFactoryAbi = [
    {
        type: 'constructor',
        inputs: [{ name: 'admin', type: 'address', internalType: 'address' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'DEFAULT_ADMIN_ROLE',
        inputs: [],
        outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'FACTORY_ADMIN_ROLE',
        inputs: [],
        outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'allPairs',
        inputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'allPairsLength',
        inputs: [],
        outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'createPair',
        inputs: [
            { name: 'tokenA', type: 'address', internalType: 'address' },
            { name: 'tokenB', type: 'address', internalType: 'address' },
            { name: 'admin', type: 'address', internalType: 'address' },
            { name: 'name', type: 'string', internalType: 'string' },
            { name: 'symbol', type: 'string', internalType: 'string' },
        ],
        outputs: [{ name: 'pair', type: 'address', internalType: 'address' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'getPair',
        inputs: [
            { name: 'tokenA', type: 'address', internalType: 'address' },
            { name: 'tokenB', type: 'address', internalType: 'address' },
        ],
        outputs: [{ name: '', type: 'address', internalType: 'address' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getRoleAdmin',
        inputs: [{ name: 'role', type: 'bytes32', internalType: 'bytes32' }],
        outputs: [{ name: '', type: 'bytes32', internalType: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'grantRole',
        inputs: [
            { name: 'role', type: 'bytes32', internalType: 'bytes32' },
            { name: 'account', type: 'address', internalType: 'address' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'hasRole',
        inputs: [
            { name: 'role', type: 'bytes32', internalType: 'bytes32' },
            { name: 'account', type: 'address', internalType: 'address' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'renounceRole',
        inputs: [
            { name: 'role', type: 'bytes32', internalType: 'bytes32' },
            {
                name: 'callerConfirmation',
                type: 'address',
                internalType: 'address',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'revokeRole',
        inputs: [
            { name: 'role', type: 'bytes32', internalType: 'bytes32' },
            { name: 'account', type: 'address', internalType: 'address' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'supportsInterface',
        inputs: [
            { name: 'interfaceId', type: 'bytes4', internalType: 'bytes4' },
        ],
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'event',
        name: 'PairCreated',
        inputs: [
            {
                name: 'tokenA',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'tokenB',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'pair',
                type: 'address',
                indexed: false,
                internalType: 'address',
            },
            {
                name: '',
                type: 'uint256',
                indexed: false,
                internalType: 'uint256',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'RoleAdminChanged',
        inputs: [
            {
                name: 'role',
                type: 'bytes32',
                indexed: true,
                internalType: 'bytes32',
            },
            {
                name: 'previousAdminRole',
                type: 'bytes32',
                indexed: true,
                internalType: 'bytes32',
            },
            {
                name: 'newAdminRole',
                type: 'bytes32',
                indexed: true,
                internalType: 'bytes32',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'RoleGranted',
        inputs: [
            {
                name: 'role',
                type: 'bytes32',
                indexed: true,
                internalType: 'bytes32',
            },
            {
                name: 'account',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'sender',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'RoleRevoked',
        inputs: [
            {
                name: 'role',
                type: 'bytes32',
                indexed: true,
                internalType: 'bytes32',
            },
            {
                name: 'account',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
            {
                name: 'sender',
                type: 'address',
                indexed: true,
                internalType: 'address',
            },
        ],
        anonymous: false,
    },
    {
        type: 'error',
        name: 'AccessControlBadConfirmation',
        inputs: [],
    },
    {
        type: 'error',
        name: 'AccessControlUnauthorizedAccount',
        inputs: [
            { name: 'account', type: 'address', internalType: 'address' },
            { name: 'neededRole', type: 'bytes32', internalType: 'bytes32' },
        ],
    },
    {
        type: 'error',
        name: 'ReentrancyGuardReentrantCall',
        inputs: [],
    },
] as const;

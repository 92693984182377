<div class="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
  <div class="max-w-3xl mx-auto">
    <!-- Steps Progress -->
    <fsco-step-progress 
      [steps]="steps" 
      [currentStep]="currentStage()"
    ></fsco-step-progress>

    <div class="bg-white shadow rounded-lg overflow-hidden">
      <!-- Header -->
      <div class="px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-semibold text-gray-900">Link Proof to Asset</h2>
        <p class="mt-1 text-sm text-gray-500">Follow the steps to link your proof to an on-chain asset.</p>
      </div>

      <!-- Content -->
      <div class="p-6 space-y-6">
        <!-- Stage 1: Select Proof -->
        @if (currentStage() === 1) {
          <div>
            <h3 class="text-lg font-medium text-gray-900 mb-4">Select a Proof</h3>
            
            <!-- Search -->
            <div class="mb-4">
              <div class="relative">
                <input
                  type="text"
                  [(ngModel)]="searchTerm"
                  placeholder="Search proofs..."
                  class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                >
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
              </div>
            </div>

            <!-- Proofs List -->
            <div class="space-y-2">
              @for (proof of filterProofs(searchTerm()); track proof.id) {
                <div
                  (click)="selectProof(proof)"
                  [class.border-blue-500]="selectedProof()?.id === proof.id"
                  class="border rounded-lg p-4 hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                >
                  <div class="flex justify-between items-center">
                    <div>
                      <h4 class="text-sm font-medium text-gray-900">
                        {{ proof.declaration.name || 'Unnamed Proof' }}
                      </h4>
                      <p class="text-sm text-gray-500">
                        Created: {{ proof.requestedAt | date }}
                      </p>
                    </div>
                    <span [ngClass]="{
                      'bg-green-100 text-green-800': proof.status === 'approved',
                      'bg-red-100 text-red-800': proof.status === 'rejected',
                      'bg-yellow-100 text-yellow-800': proof.status === 'pending'
                    }" class="px-2 py-1 text-xs rounded-full">
                      {{ proof.status }}
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
        }

        <!-- Stage 2: Enter On-Chain Asset Details -->
        @if (currentStage() === 2) {
          <div>
            <h3 class="text-lg font-medium text-gray-900 mb-4">Enter On-Chain Asset Details</h3>
            
            <!-- Tab Navigation -->
            <div class="flex space-x-4 mb-4">
              @for (tab of tabs; track tab.id) {
                <button 
                  (click)="selectTab(tab.id)" 
                  [class.font-semibold]="selectedTab() === tab.id" 
                  class="px-4 py-2 border rounded-md"
                >
                  {{ tab.label }}
                </button>
              }
            </div>

            <!-- Tab Content -->
            @if (selectedTab() === 'erc20') {
              <div>
                <h4 class="text-md font-medium text-gray-900 mb-2">ERC-20 Balance</h4>
                <input type="text" [(ngModel)]="erc20ContractAddress" placeholder="Contract Address" class="w-full border border-gray-300 rounded-md p-2 mb-2">
                <input type="text" [(ngModel)]="erc20WalletAddress" placeholder="Wallet For Balance" class="w-full border border-gray-300 rounded-md p-2">
              </div>
            }

            @if (selectedTab() === 'nft') {
              <div>
                <h4 class="text-md font-medium text-gray-900 mb-2">NFT</h4>
                <input type="text" [(ngModel)]="nftContractAddress" placeholder="NFT Contract Address" class="w-full border border-gray-300 rounded-md p-2 mb-2">
                <input type="text" [(ngModel)]="nftTokenId" placeholder="NFT Token ID" class="w-full border border-gray-300 rounded-md p-2">
              </div>
            }

            @if (selectedTab() === 'wallet') {
              <div>
                <h4 class="text-md font-medium text-gray-900 mb-2">Wallet</h4>
                <input type="text" [(ngModel)]="walletAddress" placeholder="Wallet Address" class="w-full border border-gray-300 rounded-md p-2">
              </div>
            }

            @if (selectedTab() === 'transaction') {
              <div>
                <h4 class="text-md font-medium text-gray-900 mb-2">Transaction</h4>
                <input type="text" [(ngModel)]="transactionHash" placeholder="Transaction Hash" class="w-full border border-gray-300 rounded-md p-2">
              </div>
            }
          </div>
        }

        <!-- Stage 3: Review and Submit -->
        @if (currentStage() === 3) {
          <div>
            <h3 class="text-lg font-medium text-gray-900 mb-4">Review and Submit</h3>
            @if (selectedProof()) {
              <p><strong>Selected Proof:</strong> {{ selectedProof()!.declaration.name  }}</p>
              <p><strong>Declaration Type:</strong> {{ selectedProof()!.declaration.description  }}</p>
              <p><strong>On-Chain Asset Type:</strong> {{ selectedTab() }}</p>
              @if (selectedTab() === 'erc20') {
                <p><strong>Contract Address:</strong> {{ erc20ContractAddress() }}</p>
                <p><strong>Wallet For Balance:</strong> {{ erc20WalletAddress() }}</p>
              }
              @if (selectedTab() === 'nft') {
                <p><strong>NFT Contract Address:</strong> {{ nftContractAddress() }}</p>
                <p><strong>NFT Token ID:</strong> {{ nftTokenId() }}</p>
              }
              @if (selectedTab() === 'wallet') {
                <p><strong>Wallet Address:</strong> {{ walletAddress() }}</p>
              }
              @if (selectedTab() === 'transaction') {
                <p><strong>Transaction Hash:</strong> {{ transactionHash() }}</p>
              }
            }
          </div>
        }

        <!-- Navigation Buttons -->
        <div class="pt-4 border-t border-gray-200">
          <div class="flex justify-between">
            <fsco-button *ngIf="currentStage() > 1" (click)="currentStage.set(currentStage() - 1)">
              Previous
            </fsco-button>
            <fsco-button *ngIf="currentStage() < 3" (click)="nextStage()" [disabled]="!canLink() || loading()">
              Next
            </fsco-button>
            <fsco-button *ngIf="currentStage() === 3" (click)="requestLinking()" [disabled]="loading()">
              Submit for Creation
            </fsco-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 
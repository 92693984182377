import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { ButtonComponent } from '../../components/button/button.component';

@Component({
    selector: 'cp-no-permission',
    templateUrl: './no-permission.html',
    styleUrls: ['./no-permission.scss'],
    standalone: true,
    imports: [ButtonComponent]
})
export class NoPermissionComponent {
    constructor(private sessionService: SessionService, private router: Router) {}

    async ngOnInit() {
    }

    toHome() {
        this.router.navigateByUrl('/');
    }
}

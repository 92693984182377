import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent, ExplorerService } from '@fsco/shared';
import { ExternalLink, LucideAngularModule } from 'lucide-angular';
import { catchError, from, of } from 'rxjs';
import { DeclarationService } from '../../services/decleration.service';
import { EventService } from '../../services/event.service';
import { LinkService } from '../../services/link.service';
import { ProofService } from '../../services/proof.service';
import { EvidenceDetailsComponent } from '../../shared/components/evidence-details/evidence-details.component';
import {
    LinkRequestEvent,
    ProofState,
    RequestedProofEvent,
    VerificationStatus,
    ZKEvidence,
} from '../../shared/interfaces/proof.interface';

@Component({
    selector: 'app-proof-review',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ButtonComponent,
        LucideAngularModule,
        EvidenceDetailsComponent,
    ],
    templateUrl: './proof-review.component.html',
})
export class ProofReviewComponent implements OnInit {
    proofRequests = signal<Extract<ProofState, { status: 'pending' }>[]>([]);
    linkRequests = signal<
        Extract<ProofState, { status: 'approved'; linkStatus: 'pending' }>[]
    >([]);
    loading = signal<boolean>(true);
    error = signal<string | null>(null);
    reviewNotes = signal<string>('');
    selectedProofId = signal<string | null>(null);
    activeTab = signal<'proofs' | 'links'>('proofs');
    readonly externalLinkIcon = ExternalLink;

    constructor(
        private proofService: ProofService,
        private eventService: EventService,
        private linkService: LinkService,
        private explorerService: ExplorerService,
    ) { }

    ngOnInit(): void {
        this.loadRequests();
    }

    loadRequests(): void {
        this.loading.set(true);
        this.eventService.list(['pending', 'approved']).subscribe({
            next: (requests: ProofState[]) => {
                // Separate proof requests and link requests
                const proofReqs = requests.filter(
                    (req) => req.status === 'pending',
                );
                const linkReqs = requests.filter(
                    (req) =>
                        req.status === 'approved' &&
                        req.linkStatus === 'pending',
                );
                for (const proof of proofReqs) {
                    if (proof.evidence.type === 'raw') {
                        continue;
                    }

                    const evidence = proof.evidence as ZKEvidence;
                    evidence.verificationStatuses = this.initializeVerificationStatuses(
                        evidence.attributes,
                        VerificationStatus.Pending,
                    );
                    this.proofService.verifyProof(proof.id, evidence).subscribe({
                        next: (verification) => {
                            evidence.verificationStatuses = Object.keys(
                                evidence.attributes,
                            ).reduce(
                                (acc, key) => {
                                    acc[key] = verification[key]?.merkleOnchainMatched
                                        ? VerificationStatus.Verified
                                        : VerificationStatus.VerifiedFailed;
                                    return acc;
                                },
                                {} as Record<string, VerificationStatus>,
                            );
                        },
                        error: (error) => {
                            console.error('Verification Error:', error);
                            evidence.verificationStatuses =
                                this.initializeVerificationStatuses(
                                    evidence.attributes,
                                    VerificationStatus.VerifiedFailed,
                                );
                        }
                    });
                }
                this.proofRequests.set(proofReqs);
                this.linkRequests.set(linkReqs);
                this.loading.set(false);
            },
            error: (err: Error) => {
                this.error.set('Failed to load requests');
                this.loading.set(false);
                console.error('Error loading requests:', err);
            },
        });
    }

    private initializeVerificationStatuses(
        attributes: Record<string, string>,
        status: VerificationStatus,
    ): Record<string, VerificationStatus> {
        return Object.keys(attributes).reduce(
            (acc, key) => {
                acc[key] = status;
                return acc;
            },
            {} as Record<string, VerificationStatus>,
        );
    }

    reviewProof(proofId: string, status: 'approved' | 'rejected'): void {
        this.loading.set(true);
        this.proofService
            .reviewProofRequest(proofId, status, this.reviewNotes())
            .subscribe({
                next: () => {
                    this.loadRequests();
                    this.reviewNotes.set('');
                    this.selectedProofId.set(null);
                },
                error: (err: Error) => {
                    this.error.set('Failed to update proof status');
                    this.loading.set(false);
                    console.error('Error reviewing proof:', err);
                },
            });
    }

    reviewLink(proofId: string, status: 'approved' | 'rejected'): void {
        this.loading.set(true);

        from(
            this.linkService.reviewLink(proofId, status, this.reviewNotes()),
        ).subscribe({
            next: () => {
                this.loadRequests();
                this.reviewNotes.set('');
                this.selectedProofId.set(null);
            },
            error: (err: Error) => {
                this.error.set('Failed to update link status');
                this.loading.set(false);
                console.error('Error reviewing link:', err);
            },
        });
    }

    getAttributeEntries(proof: ProofState): [string, any][] {
        if (!proof.evidence.attributes) return [];
        return Object.entries(proof.evidence.attributes);
    }

    getLinkAttributes(proof: ProofState): [string, any][] {
        if (!('link' in proof)) return [];
        return Object.entries(proof.link.targetAttributes);
    }

    selectProof(proofId: string): void {
        this.selectedProofId.set(proofId);
    }

    isProofSelected(proofId: string): boolean {
        return this.selectedProofId() === proofId;
    }

    setActiveTab(tab: 'proofs' | 'links'): void {
        this.activeTab.set(tab);
    }

    getRequestEvent(request: ProofState): RequestedProofEvent {
        return request.events.filter(
            (event): event is RequestedProofEvent => event.type === 'requested',
        )[0];
    }

    getExplorerUrl(txHash: string): string {
        const explorer = this.explorerService.chainLinker().transaction(txHash);
        console.log(explorer);
        return explorer;
    }

    getRequestedLinkEvent(request: ProofState): LinkRequestEvent {
        return request.events.filter(
            (event): event is LinkRequestEvent =>
                event.type === 'link-requested',
        )[0];
    }
}

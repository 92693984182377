<div class="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-4xl mx-auto">
    <h1 class="text-2xl text-gray-900 text-center mb-20 pt-20">
      Welcome to <span class="font-bold">FSCO</span> | <span class="font-normal">Proof</span>
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <ng-container *ngFor="let role of roles">
        <!-- Skip admin routes if user is not admin -->
        <ng-container *ngIf="!role.adminOnly || isAdmin">
          <a [routerLink]="role.route" class="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden transform hover:-translate-y-1 group border border-gray-200 hover:border-green-500 flex flex-col h-full">
            <div class="p-6 flex flex-col items-center flex-grow">
              <div class="w-16 h-16 bg-green-50 group-hover:bg-green-100 rounded-full flex items-center justify-center mb-4 transition-colors duration-300">
                <ng-icon [name]="role.icon" class="text-green-600 text-2xl"></ng-icon>
              </div>
              <h2 class="text-lg font-semibold text-gray-900 mb-2 text-center">{{ role.name }}</h2>
              <p class="text-gray-600 text-center text-sm">
                {{ role.helperText }}
              </p>
            </div>
            <div class="bg-gray-50 group-hover:bg-green-50 px-6 py-3 text-center transition-colors duration-300 mt-auto">
              <span class="text-green-600 font-medium inline-flex items-center text-sm">
                Enter
                <svg class="w-4 h-4 ml-2 transition-transform duration-300 transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </span>
            </div>
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { getEnvironment } from '../../../environments/environment';
import { Environment } from '../../config/environment.token';
import { AuthService } from '../../services/auth.service';
import { SessionService } from '../../services/session.service';
import { Logger } from '../../utils/logger';

@Component({
    selector: 'cp-login',
    templateUrl: './login.html',
    styleUrls: ['./login.scss'],
    standalone: true,
    imports: [
        CommonModule, // Add CommonModule to imports
    ],
})
export class LoginComponent implements OnInit, OnDestroy {
    private logger = new Logger('LoginComponent');
    private sub: Subscription | undefined;
    private environment: Environment = getEnvironment();

    constructor(
        private authService: AuthService,
        private sessionService: SessionService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    private sendToLogin() {
        const loginUrl = this.authService.loginUrl();
        window.location.href = loginUrl;
    }
    private sendToLogout() {
        const logoutUrl = this.authService.logoutUrl();
        window.location.href = logoutUrl;
    }

    async ngOnInit() {
        this.logger.log('Initialized');
        if (typeof this.sub !== 'undefined') this.sub.unsubscribe();

        this.sub = combineLatest([
            this.route.queryParams as Observable<any>,
            this.sessionService.get$(),
        ]).subscribe(async ([params, session]) => {
            if (session || this.environment.enableLogin === false) {
                this.logger.log(
                    'Login Component Session Found Navigating To Home',
                    session,
                );
                this.router.navigate(['/']);
            } else if (params.code && params.session_state) {
                this.logger.log(
                    'Exchanging Grant Code',
                    params.code,
                    params.session_state,
                );
                const success =
                    await this.authService.exchangeGrantCodeAndSetAuthToken(
                        params.code,
                        params.session_state,
                    );
                if (success) {
                    this.logger.log('Successfully exchanged grant code');
                    this.router.navigate(['/']);
                } else {
                    this.logger.error('Failed to exchange grant code');
                    // should redirect to logout page.
                    this.sendToLogout();
                }
            } else {
                this.logger.warn(
                    'No code or session state found sending to login',
                );
                // to keycloak login page
                this.sendToLogin();
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    appChecksHasUserData() {
        const hasUserData = this.sessionService.hasUserExtendedData();
        this.logger.log('hasUserData ', hasUserData);
        return hasUserData;
    }
}

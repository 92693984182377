<!-- Error Message -->
@if (error()) {
<div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 mt-4" role="alert">
    {{ error() }}
</div>
}

<!-- Loading State -->
@if (loading()) {
<div class="flex justify-center items-center p-4">
    <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
</div>
}

@switch (stage()) {
    @case (0) {
        <!-- Declaration Form Step -->
        <div class="max-w-lg mx-auto">
            <h2 class="text-xl font-bold mb-4">Provide Information</h2>
            <div class="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
                <app-declaration-form [declaration]="declaration"
                    (formData)="handleDeclarationData($event)"
                    (formValid)="declarationDataValid.set($event)"></app-declaration-form>
            </div>
            <div class="flex justify-between mt-6">
                <fsco-button (click)="previousStep()">
                    Previous
                </fsco-button>
                <fsco-button (click)="nextStep()" [disabled]="!declarationDataValid()">
                    Next
                </fsco-button>
            </div>
        </div>
    }

    @case (1) {
         <!-- Declaration Form Step -->
         <div class="max-w-lg mx-auto">
            <h2 class="text-xl font-bold mb-4">Provide Information</h2>
            <div class="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
                <app-evidence-form (fileSelected)="handleFileSelected($event)"></app-evidence-form>
            </div>
            <div class="flex justify-between mt-6">
                <fsco-button (click)="previousStep()">
                    Previous
                </fsco-button>
                <fsco-button (click)="submitEvidence()" [disabled]="!evidenceData()">
                    Submit
                </fsco-button>
            </div>
        </div>
    }
}

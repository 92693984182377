import { Inject, Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, isEmpty, map } from 'rxjs';
import { NIL, v4 as uuidv4 } from 'uuid';
import { getEnvironment } from '../../environments/environment';
import { Environment } from '../config/environment.token';
import {
    DecodedToken,
    OrganisationAttribute,
    OrganisationDisplayDetails,
    PermissionEnum,
    Session,
    UserDisplayDetails,
    UserLogin,
} from '../models/session.model';
import { Logger } from '../utils/logger';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
    logger = new Logger('SessionService');
    private sessionSubject: BehaviorSubject<Session | null>;

    private environment: Environment = getEnvironment();

    constructor(private readonly authService: AuthService) {
        this.logger.log('Initialized');
        const tokens = this.authService.tokens.getValue();
        this.sessionSubject = new BehaviorSubject<Session | null>(
            this.toSession(tokens),
        );

        this.authService.tokens
            .pipe(map((tokens) => this.toSession(tokens)))
            .subscribe((session) => {
                this.logger.log('Update Session', session);
                this.sessionSubject.next(session);
            });
    }

    get(): Session | null {
        return this.sessionSubject.getValue();
    }

    get$(): Observable<Session | null> {
        return this.sessionSubject.asObservable();
    }

    private toSession(login: UserLogin | null): Session | null {
        if (!login) return null;
        const dataToken: DecodedToken = jwtDecode<DecodedToken>(login.token);
        const orgData = this.toOrganisationData(login.token);
        if (!orgData) {
            return null;
        }

        const userData: UserDisplayDetails = {
            firstName: dataToken.given_name,
            lastName: dataToken.family_name,
            org: orgData?.name || '',
            role: PermissionEnum[0] || '',
            id: dataToken.sub,
        };
        const userSession: Session = {
            tokens: login,
            user: userData,
            org: orgData,
        };
        return userSession;
    }

    private toOrganisationData(
        sessionToken: string,
    ): OrganisationDisplayDetails | null {
        const decodedToken: DecodedToken = jwtDecode(sessionToken);
        const { organization, organizationMeta } = decodedToken;
        if (
            typeof organization !== 'string' ||
            typeof organizationMeta !== 'object'
        ) {
            this.logger.error('Invalid organisation data, it should have organisation and organisationMeta, check token builder');
            return null;
        }
        const orgMeta = organizationMeta[organization] as OrganisationAttribute;
        if ((orgMeta?.organisationId ?? []).length === 0) {
            this.logger.error('No organisationId attribute found, check token builder');
            return null;
        }
        return {
            id: orgMeta.organisationId[0],
            name: organization,
        };
    }

    private toHeaders(session: Session | null): Record<string, string> {
        if (!session || !session.tokens.token) {
            return {
                'x-user-id': NIL,
                'x-org-id': '00b4b8e6-6e9c-4ec8-8bc0-4f27cba57e26',
                'x-tenant-id': '00b4b8e6-6e9c-4ec8-8bc0-4f27cba57e26',
                'x-policy-id': NIL,
                'x-correlation-id': uuidv4(),
                ...this.environment.defaultHeaders,
            };
        }
        if (this.environment.stage === 'local') {
            return {
                'x-user-id': session.user.id,
                'x-org-id': session.org.id,
                'x-tenant-id': session.org.id,
                'x-policy-id': NIL,
                'x-correlation-id': uuidv4(),
                ...this.environment.defaultHeaders,
            };
        }
        return {
            Authorization: `Bearer ${session.tokens.token}`,
            ...this.environment.defaultHeaders,
        };
    }

    authHeaders(): Record<string, string> {
        const session = this.get();
        return this.toHeaders(session);
    }

    authHeaders$(): Observable<Record<string, string>> {
        return this.sessionSubject.pipe(map(this.toHeaders));
    }

    isLoggedIn(): boolean {
        const session = this.get();
        if (session?.tokens.token && this.hasUserExtendedData()) return true;
        return false;
    }

    hasUserExtendedData(): boolean {
        if (this.get()?.org?.id) return true;
        return false;
    }
}

import { Injectable } from '@angular/core';
import {
    Observable,
    combineLatest,
    firstValueFrom,
    from,
    lastValueFrom,
    mergeMap,
    of,
    switchMap,
} from 'rxjs';
import { environment } from '../../environments/environment';
import {
    Evidence,
    ProofState,
    RequestedProofEvent,
    ReviewedProofEvent,
    hashEvidence,
} from '../shared/interfaces/proof.interface';
import { ProofContractService } from './contract.service';
import { DeclarationService } from './decleration.service';
import { EventService } from './event.service';
import {
    VerificationAttributes,
    VerificationService,
} from './verification.service';
import { WalletService } from './wallet.service';

@Injectable({
    providedIn: 'root',
})
export class ProofService {
    constructor(
        private readonly eventService: EventService,
        private readonly contractService: ProofContractService,
        private readonly declarationService: DeclarationService,
        private readonly verificationService: VerificationService,
        private readonly walletService: WalletService,
    ) {}

    createProofRequestOffline(
        declarationId: string,
        evidence: Evidence,
    ): Observable<ProofState> {
        return this.declarationService.getDeclaration(declarationId).pipe(
            mergeMap((declaration) => {
                const newRequest: RequestedProofEvent = {
                    aggId: `proof-${Date.now()}`,
                    txHash: '0x0',
                    declaration: declaration,
                    status: 'pending',
                    requestedAt: new Date().toISOString(),
                    type: 'requested',
                    requestedBy: 'current-user',
                    walletAddress: '0x0',
                    eventTime: new Date(),
                    evidence,
                };
                return this.eventService.apply(newRequest);
            }),
        );
        // Mock API call
    }

    async createProofRequestOnline(
        declarationTokenId: string,
        evidence: Evidence,
    ): Promise<ProofState> {
        const alpha_user = await this.walletService.walletByName('User1');
        const declarations = await firstValueFrom(
            this.declarationService.getDeclarations(),
        );
        const proof_contract = await this.contractService.getProofContract();

        const declaration_token = declarations.find(
            (d) => d.id === declarationTokenId,
        );
        if (!declaration_token) {
            throw new Error('Declaration token not found');
        }

        const receipt = await proof_contract
            .executeWith({ walletId: alpha_user.id }, async (contract) => {
                return await contract.requestProof({
                    version: BigInt(declaration_token.version),
                    uri: declaration_token.uri,
                    declarationTokenId: BigInt(declarationTokenId),
                    evidence: JSON.stringify(evidence),
                });
            })
            .then((x) => x.wait());

        const proof_event = receipt.execution.logs
            .filter((log) => log.name === 'ProofRequested')
            .pop();

        if (!proof_event) {
            throw new Error('Proof ID not found');
        }

        const proof_id = proof_event.params.tokenId.value.toString();

        return await firstValueFrom(
            this.eventService.apply({
                aggId: proof_id,
                txHash: receipt.tx.hash,
                type: 'requested',
                eventTime: new Date(),
                requestedBy: alpha_user.id,
                walletAddress: alpha_user.address,
                status: 'pending',
                requestedAt: new Date().toISOString(),
                declaration: declaration_token,
                evidence,
            }),
        );
    }

    createProofRequest(
        declarationTokenId: string,
        evidence: Evidence,
    ): Observable<ProofState> {
        if (environment.online) {
            return from(
                this.createProofRequestOnline(declarationTokenId, evidence),
            );
        }
        return this.createProofRequestOffline(declarationTokenId, evidence);
    }

    async reviewProofRequestOnline(
        proofTokenId: string,
        status: 'approved' | 'rejected',
        notes?: string,
    ) {
        const admin_user = await this.walletService.adminWallet();
        const proof = await lastValueFrom(this.eventService.get(proofTokenId));
        const proof_contract = await this.contractService.getProofContract();

        const review_request = await proof_contract.executeWith(
            {
                walletId: admin_user.id,
            },
            async (contract) => {
                return await contract.reviewProof({
                    approved: status === 'approved',
                    tokenId: BigInt(proofTokenId),
                });
            },
        );

        const recept = await review_request.wait();

        const review_event = recept.execution.logs
            .filter((log: any) => log.name === 'ProofReviewed')
            .pop();

        if (!review_event) {
            throw new Error('Review event not found');
        }

        const newRequest: ReviewedProofEvent = {
            aggId: proofTokenId,
            status,
            txHash: recept.tx.hash,
            reviewedAt: new Date().toISOString(),
            reviewedBy: admin_user.id,
            walletAddress: admin_user.address,
            reviewNotes: notes,
            type: 'reviewed',
            eventTime: new Date(),
            proof: {
                customerId: proof.requestedBy,
                declarationId: proof.declaration.id,
                status: proof.status,
                evidenceHash: await hashEvidence(proof.evidence),
                id: proofTokenId,
            },
        };
        return lastValueFrom(this.eventService.apply(newRequest));
    }

    reviewProofRequestOffline(
        aggId: string,
        status: 'approved' | 'rejected',
        notes?: string,
    ): Observable<ProofState> {
        return this.eventService.get(aggId).pipe(
            mergeMap((proof_request) =>
                combineLatest([
                    of(proof_request),
                    hashEvidence(proof_request.evidence),
                ]),
            ),
            mergeMap(([proof_request, evidenceHash]) => {
                const newRequest: ReviewedProofEvent = {
                    aggId,
                    status,
                    txHash: '0x0',
                    reviewedAt: new Date().toISOString(),
                    reviewedBy: 'admin-user',
                    walletAddress: '0x0',
                    reviewNotes: notes,
                    type: 'reviewed',
                    eventTime: new Date(),
                    proof: {
                        customerId: proof_request.requestedBy,
                        declarationId: proof_request.declaration.id,
                        status: proof_request.status,
                        evidenceHash,
                        id: proof_request.id,
                    },
                };
                return this.eventService.apply(newRequest);
            }),
        );
    }

    reviewProofRequest(
        proofTokenId: string,
        status: 'approved' | 'rejected',
        notes?: string,
    ): Observable<ProofState> {
        if (environment.online) {
            return from(
                this.reviewProofRequestOnline(proofTokenId, status, notes),
            );
        }
        return this.reviewProofRequestOffline(proofTokenId, status, notes);
    }

    verifyProof(proofTokenId: string, evidence: Evidence) {
        return this.eventService.verify(proofTokenId, evidence);
    }
    verify(
        proofId: string,
        evidence: Evidence,
    ): Observable<VerificationAttributes> {
        return this.eventService.get(proofId).pipe(
            mergeMap(async (proof) => {
                if (!proof) {
                    throw new Error(`Proof not found with ID: ${proofId}`);
                }
                if (proof.status !== 'approved') {
                    throw new Error(
                        `Proof ${proofId} is not in a valid state for verification. Status: ${proof.status}`,
                    );
                }
                return this.verificationService.runVerification(
                    evidence.attributes,
                );
            }),
        );
    }
}

import { Injectable } from '@angular/core';
import { ProvisionLoader } from '@fsco/shared';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

type Wallet = {
    name: string;
    address: string;
    id: string;
};

@Injectable({
    providedIn: 'root',
})
export class WalletService {
    private _wallets: Wallet[];
    private _adminWallet: Wallet;
    private readonly provisionLoader: ProvisionLoader<typeof environment.did>;

    constructor() {
        this.provisionLoader = new ProvisionLoader(environment.did);
        const admin = this.provisionLoader.wallet(
            environment.chainConfig.wallets.adminWallet,
        );

        const user1 = this.provisionLoader.wallet(
            environment.chainConfig.wallets.user1Wallet,
        );
        const user2 = this.provisionLoader.wallet(
            environment.chainConfig.wallets.user2Wallet,
        );

        this._adminWallet = {
            name: 'Admin',
            address: admin.address,
            id: admin.id,
        };

        this._wallets = [
            this._adminWallet,
            {
                name: 'User1',
                address: user1.address,
                id: user1.id,
            },
            {
                name: 'User2',
                address: user2.address,
                id: user2.id,
            },
        ];
        console.log('Wallets initialized', this._wallets);
    }

    wallets(): Wallet[] {
        console.log('Fetching available wallets from API');
        return this._wallets;
    }

    walletById(address: string): string {
        const wallet = this._wallets.find(
            (wallet) => wallet.address === address,
        );
        if (!wallet) throw new Error('Could not find wallet ');
        return wallet.id;
    }

    walletByAddress(address: string): Wallet {
        const wallet = this._wallets.find(
            (wallet) => wallet.address === address,
        );
        if (!wallet) throw new Error('Could not find wallet ');
        return wallet;
    }

    walletByName(name: string): Wallet {
        const wallet = this._wallets.find((wallet) => wallet.name === name);
        if (!wallet) throw new Error('Could not find wallet ');
        return wallet;
    }

    adminWallet(): Wallet {
        return this._adminWallet;
    }
}

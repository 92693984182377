import { GetContracts, GetDeployments, GetWallets } from '@fsco/shared';
import { did } from './did.dev';

export const environment = {
    did,
    appName: 'fsco-proofs',
    realm: 'fsco-platform',
    clientId: 'fsco-proofs',
    authenticationUrl: 'https://id.dev.fsco.io',
    stage: 'dev',
    production: false,
    apiUrl: 'https://dev.api.fsco.io/v2',
    apiDelay: 1000,
    useLocalStorage: true,
    useMockData: false,
    enableLogin: true,
    online: true,
    chainId: 296,
    shareringConfig: {
        qrBaseUrl: 'https://shareringmetest.page.link/',
        qrSubfix: '&apn=network.sharering.shareringmetest',
        vqlBaseUrl: 'https://sharering.network/vql',
        graphqlBaseUrl: 'https://api.shareri.ng/graphql',
        clientId: '674d5ef61cbb4bcbfb9b1c8e',
        queryId: '674d5ff81cbb4bcbfb9b1dfb',
        queryOwner: 'shareledger1vf4ppe789waujfjdg82uh4d5w0we6udmmynl4g',
    },
    chainConfig: {
        wallets: {
            adminWallet:
                'hedera_testnet_deployer' as const satisfies GetWallets<
                    typeof did
                >,
            user1Wallet:
                'hedera_testnet_deployer' as const satisfies GetWallets<
                    typeof did
                >,
            user2Wallet:
                'hedera_testnet_deployer' as const satisfies GetWallets<
                    typeof did
                >,
        },
        deployments: {
            dvctToken:
                'ShareRing_Testnet_DocumentVerifiableCredentialsToken' as const satisfies GetDeployments<
                    typeof did
                >,
            vctToken:
                'ShareRing_Testnet_VerifiableCredentialsToken' as const satisfies GetDeployments<
                    typeof did
                >,
        },
        contracts: {
            declarations:
                'FSCOProofs_Testnet_Declaration' as const satisfies GetContracts<
                    typeof did
                >,
            proofs: 'FSCOProofs_Testnet_Proof' as const satisfies GetContracts<
                typeof did
            >,
            links: 'FSCOProofs_Testnet_ProofLink' as const satisfies GetContracts<
                typeof did
            >,
        },
    },
    defaultHeaders: {
        // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJ1c2VySWQiOiIwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwIiwidGVuYW50SWQiOiIwMGI0YjhlNi02ZTljLTRlYzgtOGJjMC00ZjI3Y2JhNTdlMjYiLCJhcHBzIjpbXSwiaXNTdXBwZXJLZXkiOnRydWUsIm9yZ2FuaXNhdGlvbklkIjoiMDBiNGI4ZTYtNmU5Yy00ZWM4LThiYzAtNGYyN2NiYTU3ZTI2IiwiYXR0cmlidXRlcyI6W10sImlzS2V5Ijp0cnVlLCJpYXQiOjE3MzI4NTM3MzN9.13W4eqIe7vbx_O8KVqg5MG-UYs8v0z-c6i94kF0HYwQ`,
    },
};

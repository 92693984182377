export type GetWallets<T> = Extract<
    keyof T,
    `$ref:wallets/${string}`
> extends `$ref:wallets/${infer WalletName}`
    ? WalletName
    : never;

export type GetContracts<T> = Extract<
    keyof T,
    `$ref:contracts/${string}`
> extends `$ref:contracts/${infer ContractName}`
    ? ContractName
    : never;

export type GetTransactions<T> = Extract<
    keyof T,
    `$ref:transactions/${string}`
> extends `$ref:transactions/${infer TransactionName}`
    ? TransactionName
    : never;

export type GetDeployments<T> = Extract<
    keyof T,
    `$ref:deployments/${string}`
> extends `$ref:deployments/${infer DeploymentName}`
    ? DeploymentName
    : never;

export type ExtractKey<T, Tx extends keyof T> = T[Tx];

export class ProvisionLoader<T extends Record<string, any>> {
    constructor(private readonly provision: T) {}

    contract(contractName: GetContracts<T>) {
        if (!this.provision) {
            throw new Error('ProvisionedLoader not initialized');
        }
        if (!this.provision[`$ref:contracts/${contractName}`]) {
            throw new Error(`Contract ${contractName} not found`);
        }
        return this.provision[
            `$ref:contracts/${contractName}`
        ] as any as ExtractKey<T, `$ref:contracts/${GetContracts<T>}`>;
    }

    deployment(deploymentName: GetDeployments<T>) {
        if (!this.provision) {
            throw new Error('ProvisionedLoader not initialized');
        }
        if (!this.provision[`$ref:deployments/${deploymentName}`]) {
            throw new Error(`Deployment ${deploymentName} not found`);
        }
        return this.provision[
            `$ref:deployments/${deploymentName}`
        ] as any as ExtractKey<T, `$ref:deployments/${GetDeployments<T>}`>;
    }

    wallet(walletName: GetWallets<T>) {
        if (!this.provision) {
            throw new Error('ProvisionedLoader not initialized');
        }
        if (!this.provision[`$ref:wallets/${walletName}`]) {
            throw new Error(`Wallet ${walletName} not found`);
        }
        return this.provision[
            `$ref:wallets/${walletName}`
        ] as any as ExtractKey<T, `$ref:wallets/${GetWallets<T>}`>;
    }

    tx<TX extends GetTransactions<T>>(
        transactionName: TX,
    ): ExtractKey<T, `$ref:transactions/${TX}`> {
        if (!this.provision) {
            throw new Error('ProvisionedLoader not initialized');
        }
        if (!this.provision[`$ref:transactions/${transactionName}`]) {
            throw new Error(`Transaction ${transactionName} not found`);
        }
        return this.provision[
            `$ref:transactions/${transactionName}`
        ] as any as ExtractKey<T, `$ref:transactions/${TX}`>;
    }
}

export interface OrganisationDisplayDetails {
    id: string;
    name: string;
    logo?: string;
}

export interface Session {
    tokens: UserLogin;
    user: UserDisplayDetails;
    org: OrganisationDisplayDetails;
}

export interface UserLogin {
    token: string;
    refresh_token: string;
    version: number;
}

export type UserDisplayDetails = {
    firstName: string;
    lastName: string;
    org: string;
    role: string;
    id: string;
};

export enum PermissionEnum {
    Super = 2,
    Admin = 3,
}

export type OrganisationAttribute = {
    organisationId: string[];
};

export interface DecodedToken {
    exp: number;
    iat: number;
    auth_time: number;
    jti: string;
    iss: string;
    aud: string;
    sub: string;
    typ: string;
    azp: string;
    sid: string;
    realm_access: {
        roles: string[];
    };
    resource_access: {
        account: {
            roles: string[];
        };
    };
    organization?: string;
    organizationMeta?: {
        [key: string]: OrganisationAttribute | string;
    };
    scope: string;
    email_verified: boolean;
    name: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    email: string;
}

export function replacer(_key: string, value: any): any {
    if (typeof value === 'bigint') {
        return { '@type': 'bigint', value: value.toString() };
    }
    if (value instanceof Date) {
        return { '@type': 'date', value: value.toISOString() };
    }
    return value;
}

export function reviver(_key: string, value: any): any {
    if (typeof value === 'object' && value !== null) {
        if (value['@type'] === 'bigint') {
            return BigInt(value.value);
        }
        if (value['@type'] === 'date') {
            return new Date(value.value);
        }
    }
    return value;
}

export const serializeForLocalStore = (obj: any): string => {
    return JSON.stringify(obj, replacer);
};

export const deserializeFromLocalStore = (str: string): any => {
    return JSON.parse(str, reviver);
};

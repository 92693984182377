<div class="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
  <div class="max-w-7xl mx-auto">
    <div class="bg-white shadow rounded-lg overflow-hidden">
      <div class="px-6 py-4 border-b border-gray-200">
        <h2 class="text-xl font-semibold text-gray-900">Review Requests</h2>
        <p class="mt-1 text-sm text-gray-500">Review and manage proof and link requests</p>
      </div>

      <!-- Tab Navigation -->
      <div class="px-6 py-4 border-b border-gray-200">
        <nav class="flex space-x-8" aria-label="Tabs">
          <button
            (click)="setActiveTab('proofs')"
            class="group relative min-w-0 flex-1 overflow-hidden py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 transition-colors duration-200"
            [class]="activeTab() === 'proofs' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'"
            aria-current="page"
          >
            <span>Proof Requests</span>
            @if (proofRequests().length > 0) {
              <span class="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                {{ proofRequests().length }}
              </span>
            }
            @if (activeTab() === 'proofs') {
              <span class="absolute inset-x-0 bottom-0 h-0.5 bg-blue-600"></span>
            }
          </button>
          <button
            (click)="setActiveTab('links')"
            class="group relative min-w-0 flex-1 overflow-hidden py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 transition-colors duration-200"
            [class]="activeTab() === 'links' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'"
          >
            <span>Link Requests</span>
            @if (linkRequests().length > 0) {
              <span class="ml-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                {{ linkRequests().length }}
              </span>
            }
            @if (activeTab() === 'links') {
              <span class="absolute inset-x-0 bottom-0 h-0.5 bg-blue-600"></span>
            }
          </button>
        </nav>
      </div>

      <!-- Loading State -->
      @if (loading()) {
        <div class="p-6 text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p class="mt-2 text-sm text-gray-500">Loading requests...</p>
        </div>
      }

      <!-- Error State -->
      @if (error()) {
        <div class="p-4 bg-red-50 text-red-700">
          {{ error() }}
        </div>
      }

      <!-- Proof Requests List -->
      @if (!loading() && !error() && activeTab() === 'proofs') {
        <div class="divide-y divide-gray-200">
          @for (request of proofRequests(); track request.id) {
            <div class="p-6">
              <div class="flex justify-between items-start max-w-6xl">
                <div class="flex-1 max-w-4xl">
                  <!-- Link Request Details -->
                  <div class="flex justify-between items-start">
                    <div>
                      <h3 class="text-lg font-medium text-gray-900">
                        {{ request.declaration.name }}
                      </h3>
                      <p class="mt-1 text-sm text-gray-600">
                        {{ request.declaration.description }}
                      </p>
                    </div>
                    <span class="px-2 py-1 text-xs rounded-full bg-yellow-100 text-yellow-800">
                      {{ request.status }}
                    </span>
                  </div>

                  <!-- Request Metadata -->
                  <div class="mt-2 text-sm text-gray-500">
                    <p>Requested by: {{ request.requestedBy }}</p>
                    <p>Created: {{ request.requestedAt | date:'medium' }}</p>
                  </div>

                  <!-- Declaration Attributes -->
                  <div class="mt-4">
                    <h4 class="text-sm font-medium text-gray-900 mb-2">Declaration Attributes</h4>
                    <div class="space-y-4">
                      <app-evidence-details 
                        [evidence]="request.evidence"
                        [loading]="false"
                      ></app-evidence-details>
                    </div>
                  </div>

                  <!-- Evidence Preview -->
                  @if (request.evidence.type === 'raw' && request.evidence.documentUrl) {
                    <div class="mt-4">
                      <h4 class="text-sm font-medium text-gray-900">Supporting Evidence</h4>
                      <a [href]="request.evidence.documentUrl"
                         target="_blank"
                         rel="noopener noreferrer"
                         class="text-blue-600 hover:text-blue-800">
                        View Supporting Document
                        <i-lucide 
                            [name]="externalLinkIcon"
                            class="inline-block w-4 h-4 ml-1"
                        ></i-lucide>
                      </a>
                    </div>
                  }

                  <!-- @if (getRequestEvent(request)) {
                    <a [href]="getExplorerUrl(getRequestEvent(request).txHash)"
                         target="_blank"
                         rel="noopener noreferrer"
                         class="text-blue-600 hover:text-blue-800">
                        Request Transaction
                        <i-lucide 
                            [name]="externalLinkIcon"
                            class="inline-block w-4 h-4 ml-1"
                        ></i-lucide>
                      </a>
                  } -->

                  <!-- Review Notes Input -->
                  @if (request.status === 'pending' && isProofSelected(request.id!)) {
                    <div class="mt-4">
                      <label for="notes" class="block text-sm font-medium text-gray-700">
                        Review Notes
                      </label>
                      <textarea
                          id="notes"
                          rows="3"
                          [(ngModel)]="reviewNotes"
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          placeholder="Add your review notes here..."
                      ></textarea>
                    </div>
                  }
                </div>

                <!-- Action Buttons -->
                @if (request.status === 'pending') {
                  <div class="ml-4 flex flex-col space-y-2">
                    @if (!isProofSelected(request.id!)) {
                      <fsco-button
                        (click)="selectProof(request.id!)"
                        variant="secondary"
                      >
                        Review
                      </fsco-button>
                    } @else {
                      <fsco-button
                        (click)="reviewProof(request.id!, 'approved')"
                        variant="primary"
                      >
                        Approve
                      </fsco-button>
                      <fsco-button
                        (click)="reviewProof(request.id!, 'rejected')"
                        variant="secondary"
                      >
                        Reject
                      </fsco-button>
                      <fsco-button
                        (click)="selectedProofId.set(null)"
                        variant="secondary"
                      >
                        Cancel
                      </fsco-button>
                    }
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }

      <!-- Link Requests List -->
      @if (!loading() && !error() && activeTab() === 'links') {
        <div class="divide-y divide-gray-200">
          @for (request of linkRequests(); track request.id) {
            <div class="p-6">
              <div class="flex justify-between items-start max-w-6xl">
                <div class="flex-1 max-w-4xl">
                  <!-- Link Request Details -->
                  <div class="flex justify-between items-start">
                    <div>
                      <h3 class="text-lg font-medium text-gray-900">
                        Link Request for {{ request.declaration.name }}
                      </h3>
                      <p class="mt-1 text-sm text-gray-600">
                        Target Type: {{ request.link.targetType }}
                      </p>
                    </div>
                    <span class="bg-yellow-100 text-yellow-800 px-2 py-1 text-xs rounded-full">
                      Pending Link
                    </span>
                  </div>

                  <!-- Request Metadata -->
                  <div class="mt-2 text-sm text-gray-500">
                    <p>Requested by: {{ request.linkRequestedBy }}</p>
                    <p>Created: {{ request.linkRequestedAt | date:'medium' }}</p>
                  </div>

                  <!-- Link Attributes -->
                  <div class="mt-4">
                    <h4 class="text-sm font-medium text-gray-900 mb-2">Link Details</h4>
                    <div class="bg-gray-50 rounded-md p-4">
                      @for (entry of getLinkAttributes(request); track entry[0]) {
                        <div class="flex justify-between py-2">
                          <span class="font-medium text-gray-600">{{ entry[0] }}</span>
                          <span class="text-gray-900">{{ entry[1] }}</span>
                        </div>
                      }
                    </div>
                  </div>

                  @if (getRequestEvent(request)) {
                    <div class="mt-4">
                        <h4 class="text-sm font-medium text-gray-900">Supporting Evidence</h4>
                        <a [href]="getExplorerUrl(getRequestEvent(request).txHash)"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue-600 hover:text-blue-800">
                            Request Transaction
                            <i-lucide 
                                [name]="externalLinkIcon"
                                class="inline-block w-4 h-4 ml-1"
                            ></i-lucide>
                        </a>
                    </div>
                    
                  }

                  <!-- Review Notes Input -->
                  @if (isProofSelected(request.id)) {
                    <div class="mt-4">
                      <label for="notes" class="block text-sm font-medium text-gray-700">
                        Review Notes
                      </label>
                      <textarea
                        id="notes"
                        rows="3"
                        [(ngModel)]="reviewNotes"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        placeholder="Add your review notes here..."
                      ></textarea>
                    </div>
                  }
                </div>

                <!-- Action Buttons -->
                <div class="ml-4 flex flex-col space-y-2">
                  @if (!isProofSelected(request.id)) {
                    <fsco-button
                      (click)="selectProof(request.id)"
                      variant="secondary"
                    >
                      Review
                    </fsco-button>
                  } @else {
                    <fsco-button
                      (click)="reviewLink(request.id, 'approved')"
                      variant="primary"
                    >
                      Approve
                    </fsco-button>
                    <fsco-button
                      (click)="reviewLink(request.id, 'rejected')"
                      variant="secondary"
                    >
                      Reject
                    </fsco-button>
                    <fsco-button
                      (click)="selectedProofId.set(null)"
                      variant="secondary"
                    >
                      Cancel
                    </fsco-button>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div> 
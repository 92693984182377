import axios from 'axios';
import { Observable, from, mergeMap } from 'rxjs';
import { getCorrelationId, getHeaders } from '../util/headers';
import {
    GetDeploymentQuery,
    GetDeploymentResponse,
    IContractQueryClient,
    QueryDeployment,
    QueryTXRecipte,
    SearchAbiQuery,
    SearchAbiResponse,
    SearchDeploymentsQuery,
    SearchDeploymentsResponse,
    TxReceipt,
} from './types';

export class ContractQueryClient implements IContractQueryClient {
    constructor(
        private readonly baseUrl: string,
        private readonly headers: () => Promise<Record<string, string>>,
    ) {
        this.baseUrl = `${baseUrl}/contract`;
    }

    queryDeployment(
        query: QueryDeployment,
    ): Observable<Record<string, unknown>> {
        return from(this.headers()).pipe(
            mergeMap((headers) => {
                return axios
                    .post<Record<string, unknown>>(
                        `${this.baseUrl}/deployment/${query.deploymentId}/query`,
                        query,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                ...headers,
                            },
                        },
                    )
                    .then((res) => res.data);
            }),
        );
    }

    getTxReceipt(query: QueryTXRecipte): Observable<TxReceipt> {
        return from(this.headers()).pipe(
            mergeMap((headers) => {
                return axios
                    .get<TxReceipt>(`${this.baseUrl}/tx/${query.txId}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            ...headers,
                        },
                    })
                    .then((res) => res.data);
            }),
        );
    }

    searchAbi(query: SearchAbiQuery): Observable<SearchAbiResponse> {
        return from(this.headers()).pipe(
            mergeMap((headers) => {
                return axios
                    .post<SearchAbiResponse>(
                        `${this.baseUrl}/abi/search`,
                        query,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                ...headers,
                            },
                        },
                    )
                    .then((res) => res.data);
            }),
        );
    }

    searchDeployments(
        query: SearchDeploymentsQuery,
    ): Observable<SearchDeploymentsResponse> {
        return from(this.headers()).pipe(
            mergeMap((headers) => {
                return axios
                    .post<SearchDeploymentsResponse>(
                        `${this.baseUrl}/deployment/search`,
                        query,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                ...headers,
                            },
                        },
                    )
                    .then((res) => res.data);
            }),
        );
    }

    getDeployment(
        query: GetDeploymentQuery,
    ): Observable<GetDeploymentResponse> {
        return from(this.headers()).pipe(
            mergeMap((headers) => {
                return axios
                    .get<GetDeploymentResponse>(
                        `${this.baseUrl}/deployment/${query.deploymentId}`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                ...headers,
                            },
                        },
                    )
                    .then((res) => res.data);
            }),
        );
    }
}

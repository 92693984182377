import { Injectable } from '@angular/core';
import { getEnvironment } from '../../environments/environment';

const ChainId = {
    HEDERA_MAINNET: 295,
    HEDERA_TESTNET: 296,
    ETHEREUM_MAINNET: 1,
    ETHEREUM_SEPOLIA: 11155111,
    SHARERING_LEDGER: 204,
};

const ChainExplorer = {
    hashscan: (baseUrl: string) => ({
        wallet: (address: string) => `${baseUrl}/account/${address}`,
        transaction: (hash: string) => `${baseUrl}/tx/${hash}`,
        contract: (address: string) => `${baseUrl}/contract/${address}`,
        nft: (contract: string, tokenId: string) =>
            `${baseUrl}/nft/${contract}/${tokenId}`,
    }),
    etherscan: (baseUrl: string) => ({
        wallet: (address: string) => `${baseUrl}/address/${address}`,
        transaction: (hash: string) => `${baseUrl}/tx/${hash}`,
        contract: (address: string) => `${baseUrl}/address/${address}`,
        nft: (contract: string, tokenId: string) =>
            `${baseUrl}/nft/${contract}/${tokenId}`,
    }),
};

const ChainLinker = {
    [ChainId.HEDERA_MAINNET]: ChainExplorer.hashscan(
        'https://hashscan.io/mainnet',
    ),
    [ChainId.HEDERA_TESTNET]: ChainExplorer.hashscan(
        'https://hashscan.io/testnet',
    ),
    [ChainId.ETHEREUM_MAINNET]: ChainExplorer.etherscan('https://etherscan.io'),
    [ChainId.ETHEREUM_SEPOLIA]: ChainExplorer.etherscan(
        'https://sepolia.etherscan.io',
    ),
    [ChainId.SHARERING_LEDGER]: ChainExplorer.hashscan(
        'https://explorer.sharding.technology',
    ),
};

@Injectable({
    providedIn: 'root',
})
export class ExplorerService {
    private readonly environment = getEnvironment();

    chainLinker(chainId: number = this.environment.chainId) {
        return ChainLinker[chainId];
    }
}

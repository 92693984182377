<div class="bg-white rounded-xl shadow-lg p-6 ">
  <!-- Summary Section -->
  <h3 class="text-lg font-medium text-gray-900 mb-4">Evidence Details</h3>
  <div class="bg-gray-50 rounded-xl p-4 mb-2">
    <div class="flex items-center justify-between">
      <div class="flex items-center space-x-3">
        <div class="w-10 h-10 rounded-full bg-indigo-100 flex items-center justify-center">
          <i class="fas fa-shield-alt text-indigo-600"></i>
        </div>
        <div>
          <div class="flex items-center space-x-2">
            <span class="text-base font-semibold text-gray-900">
              {{ proofState.evidence.type | titlecase }}
            </span>
            <span class="text-sm text-gray-500">
              ({{ proofState.evidence.type === 'zk' ? proofState.evidence.provider : 'raw' | titlecase }})
            </span>
          </div>
          <div class="text-xs text-gray-500 mt-1">
            Created: {{ proofState.requestedAt | date:'medium' }}
            @if (getReviewedAt(proofState)) {
              • Reviewed: {{ getReviewedAt(proofState) | date:'medium' }}
            }
          </div>
        </div>
      </div>
      <span [ngClass]="{
        'bg-green-50 text-green-700 border-green-200 ring-green-100': proofState.status === 'approved',
        'bg-yellow-50 text-yellow-700 border-yellow-200 ring-yellow-100': proofState.status === 'pending',
        'bg-red-50 text-red-700 border-red-200 ring-red-100': proofState.status === 'rejected'
      }" class="px-4 py-1.5 rounded-full text-sm font-medium border ring-1 ring-opacity-50 flex items-center space-x-2">
        <span class="w-2 h-2 rounded-full" [ngClass]="{
          'bg-green-500': proofState.status === 'approved',
          'bg-yellow-500': proofState.status === 'pending',
          'bg-red-500': proofState.status === 'rejected'
        }"></span>
        <span>{{ proofState.status | titlecase }}</span>
      </span>
    </div>
  </div>

  <!-- Evidence Section -->
  <div class="mb-2">
    <div class="flex items-center space-x-3 mb-4">
      <i class="fas fa-fingerprint text-gray-400"></i>
    </div>
    <div class="bg-gray-50 rounded-xl p-4 border border-gray-100">
        @if (proofState.evidence.type === 'zk' && proofState.evidence.provider === 'sharering') {
            <div class="grid grid-cols-1 gap-4">
                <div>
                    <p class="text-sm font-medium text-gray-500 mb-1">ShareLedger Address</p>
                    <p class="text-sm text-gray-900 font-mono break-all">
                        {{ proofState.evidence.attributes['ShareLedger_Address'] }}
                    </p>
                </div>

                <div>
                    <p class="text-sm font-medium text-gray-500 mb-1">EVM Address</p>
                    <p class="text-sm text-gray-900 font-mono break-all">
                        {{ proofState.evidence.attributes['Matic_Address'] }}
                    </p>
                </div>

                <div>
                    <p class="text-sm font-medium text-gray-500 mb-1">VCT Token ID</p>
                    <p class="text-sm text-gray-900 font-mono break-all">
                        {{ proofState.evidence.attributes['vct'] }}
                    </p>
                </div>
            </div>
        }
        @if(proofState.evidence.type === 'raw') {
            <p class="text-sm font-medium text-gray-500 mb-1">Data</p>
            @for(attribute of proofState.evidence.attributes | keyvalue; track attribute.key) {
                <div class="mb-4 last:mb-0">
                    <p class="text-sm font-medium text-gray-500 mb-1">{{ attribute.key }}</p>
                    <p class="text-sm text-gray-900 font-mono break-all">
                        {{ attribute.value }}
                    </p>
                </div>
            }
        }
    </div>
  </div>

  <!-- External Resources Section -->
  <div class="pt-4">
    <div class="flex items-center space-x-3 mb-4">
    </div>
    <div class="flex flex-wrap gap-4">
      @if (proofState.evidence.type === 'zk' && proofState.evidence.provider === 'sharering') {
        @if (proofState.evidence.attributes['Matic_Address']) {
          <fsco-button
            variant="primary"
            (clicked)="openExternalLink(explorerService.chainLinker().wallet(proofState.evidence.attributes['Matic_Address']))"
          >
            <i-lucide
              [name]="externalLinkIcon"
              class="w-5 h-5 text-green-500 mr-2"
            ></i-lucide>
            Hedera
          </fsco-button>
        }
        @if (proofState.evidence.attributes['ShareLedger_Address']) {
          <fsco-button
            variant="primary"
            (clicked)="openExternalLink('https://explorer.shareri.ng/accounts/' + proofState.evidence.attributes['ShareLedger_Address'])"
          >
            <i-lucide
              [name]="externalLinkIcon"
              class="w-5 h-5 text-green-500 mr-2"
            ></i-lucide>
            ShareRing
          </fsco-button>
        }
      }
      @if(proofState.evidence.type === 'raw') {
        <fsco-button
          variant="primary"
          (clicked)="openExternalLink(proofState.evidence.documentUrl)"
        >
          <i-lucide
            [name]="externalLinkIcon"
            class="w-5 h-5 text-green-500 mr-2"
            ></i-lucide>
          Document
        </fsco-button>
      }

      @if(getProofOwner(proofState)) {
        <fsco-button
          variant="primary"
          (clicked)="openExternalLink(explorerService.chainLinker().wallet(getProofOwner(proofState) ?? '0x0'))"
        >
        <i-lucide
        [name]="externalLinkIcon"
        class="w-5 h-5 text-green-500 mr-2"
        ></i-lucide>
          Owner
        </fsco-button>
      }
<!-- 
      @if(getProofNFT(proofState)) {
        <fsco-button
          variant="primary"
          (clicked)="openExternalLink(explorerService.chainLinker().nft(getContractAddress(proofState) ?? '0x0', getProofNFT(proofState) ?? '0x0'))"
        >
          <i-lucide
            [name]="externalLinkIcon"
            class="w-5 h-5 text-green-500 mr-2"
          ></i-lucide>
          NFT
        </fsco-button>
      } -->

      @if(getRequestEvent(proofState)) {
        <fsco-button
          variant="primary"
          (clicked)="openExternalLink(explorerService.chainLinker().transaction(getRequestEvent(proofState)?.txHash ?? '0x0'))"
        >
          <i-lucide
            [name]="externalLinkIcon"
            class="w-5 h-5 text-green-500 mr-2"
          ></i-lucide>
          Request Tx
        </fsco-button>
      }

      @if(getReviewEvent(proofState)) {
        <fsco-button
          variant="primary"
          (clicked)="openExternalLink(explorerService.chainLinker().transaction(getReviewEvent(proofState)?.txHash ?? '0x0'))"
        >
          <i-lucide
            [name]="externalLinkIcon"
            class="w-5 h-5 text-green-500 mr-2"
          ></i-lucide>
          Review Tx
        </fsco-button>
      }
    </div>
  </div>
</div>
export const CollateralizedTokenAbi = [
    {
      type: "function",
      name: "BLACKLISTER_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "DEFAULT_ADMIN_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "MINTER_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "PAUSER_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "allowance",
      inputs: [
        { name: "owner", type: "address", internalType: "address" },
        { name: "spender", type: "address", internalType: "address" }
      ],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "approve",
      inputs: [
        { name: "spender", type: "address", internalType: "address" },
        { name: "value", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "attestationManager",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "contract AttestationManager" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "authorizeMinter",
      inputs: [{ name: "minter", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "balanceOf",
      inputs: [{ name: "account", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "blacklist",
      inputs: [{ name: "account", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "blacklisted",
      inputs: [{ name: "", type: "address", internalType: "address" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "burn",
      inputs: [
        { name: "from", type: "address", internalType: "address" },
        { name: "amount", type: "uint256", internalType: "uint256" },
        { name: "attestationId", type: "bytes32", internalType: "bytes32" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "decimals",
      inputs: [],
      outputs: [{ name: "", type: "uint8", internalType: "uint8" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "getRoleAdmin",
      inputs: [{ name: "role", type: "bytes32", internalType: "bytes32" }],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "grantRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "hasRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "initialize",
      inputs: [
        { name: "name", type: "string", internalType: "string" },
        { name: "symbol", type: "string", internalType: "string" },
        { name: "_attestationManager", type: "address", internalType: "address" },
        { name: "admin", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "mint",
      inputs: [
        { name: "to", type: "address", internalType: "address" },
        { name: "amount", type: "uint256", internalType: "uint256" },
        { name: "attestationId", type: "bytes32", internalType: "bytes32" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "name",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "pause",
      inputs: [],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "paused",
      inputs: [],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "renounceRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "callerConfirmation", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "revokeMinter",
      inputs: [{ name: "minter", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "revokeRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "supportsInterface",
      inputs: [{ name: "interfaceId", type: "bytes4", internalType: "bytes4" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "symbol",
      inputs: [],
      outputs: [{ name: "", type: "string", internalType: "string" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "totalSupply",
      inputs: [],
      outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "transfer",
      inputs: [
        { name: "to", type: "address", internalType: "address" },
        { name: "value", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "transferFrom",
      inputs: [
        { name: "from", type: "address", internalType: "address" },
        { name: "to", type: "address", internalType: "address" },
        { name: "value", type: "uint256", internalType: "uint256" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "unblacklist",
      inputs: [{ name: "account", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "unpause",
      inputs: [],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "event",
      name: "Approval",
      inputs: [
        { name: "owner", type: "address", indexed: true, internalType: "address" },
        { name: "spender", type: "address", indexed: true, internalType: "address" },
        { name: "value", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "Blacklisted",
      inputs: [{ name: "account", type: "address", indexed: true, internalType: "address" }],
      anonymous: false
    },
    {
      type: "event",
      name: "Initialized",
      inputs: [{ name: "version", type: "uint64", indexed: false, internalType: "uint64" }],
      anonymous: false
    },
    {
      type: "event",
      name: "MinterAuthorized",
      inputs: [{ name: "account", type: "address", indexed: true, internalType: "address" }],
      anonymous: false
    },
    {
      type: "event",
      name: "MinterRevoked",
      inputs: [{ name: "account", type: "address", indexed: true, internalType: "address" }],
      anonymous: false
    },
    {
      type: "event",
      name: "Paused",
      inputs: [{ name: "account", type: "address", indexed: false, internalType: "address" }],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleAdminChanged",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "previousAdminRole", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "newAdminRole", type: "bytes32", indexed: true, internalType: "bytes32" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleGranted",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "account", type: "address", indexed: true, internalType: "address" },
        { name: "sender", type: "address", indexed: true, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleRevoked",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "account", type: "address", indexed: true, internalType: "address" },
        { name: "sender", type: "address", indexed: true, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "Transfer",
      inputs: [
        { name: "from", type: "address", indexed: true, internalType: "address" },
        { name: "to", type: "address", indexed: true, internalType: "address" },
        { name: "value", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "UnBlacklisted",
      inputs: [{ name: "account", type: "address", indexed: true, internalType: "address" }],
      anonymous: false
    },
    {
      type: "event",
      name: "Unpaused",
      inputs: [{ name: "account", type: "address", indexed: false, internalType: "address" }],
      anonymous: false
    },
    {
      type: "error",
      name: "AccessControlBadConfirmation",
      inputs: []
    },
    {
      type: "error",
      name: "AccessControlUnauthorizedAccount",
      inputs: [
        { name: "account", type: "address", internalType: "address" },
        { name: "neededRole", type: "bytes32", internalType: "bytes32" }
      ]
    },
    {
      type: "error",
      name: "ERC20InsufficientAllowance",
      inputs: [
        { name: "spender", type: "address", internalType: "address" },
        { name: "allowance", type: "uint256", internalType: "uint256" },
        { name: "needed", type: "uint256", internalType: "uint256" }
      ]
    },
    {
      type: "error",
      name: "ERC20InsufficientBalance",
      inputs: [
        { name: "sender", type: "address", internalType: "address" },
        { name: "balance", type: "uint256", internalType: "uint256" },
        { name: "needed", type: "uint256", internalType: "uint256" }
      ]
    },
    {
      type: "error",
      name: "ERC20InvalidApprover",
      inputs: [{ name: "approver", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "ERC20InvalidReceiver",
      inputs: [{ name: "receiver", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "ERC20InvalidSender",
      inputs: [{ name: "sender", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "ERC20InvalidSpender",
      inputs: [{ name: "spender", type: "address", internalType: "address" }]
    },
    {
      type: "error",
      name: "EnforcedPause",
      inputs: []
    },
    {
      type: "error",
      name: "ExpectedPause",
      inputs: []
    },
    {
      type: "error",
      name: "InvalidInitialization",
      inputs: []
    },
    {
      type: "error",
      name: "NotInitializing",
      inputs: []
    },
    {
      type: "error",
      name: "ReentrancyGuardReentrantCall",
      inputs: []
    }
  ] as const;
  
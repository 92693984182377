import { Injectable, OnDestroy, OnInit } from '@angular/core';
import {
    deserializeFromLocalStore,
    serializeForLocalStore,
} from '@fsco/shared';
import { Observable, from, of, switchMap } from 'rxjs';
import {
    VerificationAttributes,
    VerificationService,
} from '../services/verification.service';
import {
    Evidence,
    ProofEvent,
    ProofState,
    ProofStatus,
    applyEvent,
    reduceProofEvents,
} from '../shared/interfaces/proof.interface';
import { getMockProof } from '../shared/mocks/data.mock';

@Injectable({
    providedIn: 'root',
})
export class EventService implements OnDestroy, OnInit {
    // Mock data - replace with actual API endpoints
    private proofEvents: ProofEvent[] = [];
    private proofs: Record<string, ProofState> = {};

    constructor(private verificationService: VerificationService) {
        this.ngOnInit();
    }
    ngOnInit(): void {
        this.proofEvents = deserializeFromLocalStore(
            localStorage.getItem('proofEvents') || '[]',
        );
        if (this.proofEvents.length === 0) {
            this.proofEvents = getMockProof();
        }
        this.proofs = reduceProofEvents(this.proofEvents);
        console.log(this.proofs);
        console.log(this.proofEvents);
    }
    ngOnDestroy(): void {
        localStorage.setItem(
            'proofEvents',
            serializeForLocalStore(this.proofEvents),
        );
    }

    apply(event: ProofEvent): Observable<ProofState> {
        this.proofs[event.aggId] = applyEvent(event, this.proofs[event.aggId]);
        this.proofEvents.push(event);
        this.ngOnDestroy();
        return of(this.proofs[event.aggId]);
    }

    verify(
        proofId: string,
        evidence: Evidence,
    ): Observable<VerificationAttributes> {
        const proof = this.proofs[proofId];
        if (!proof) {
            throw new Error(`Proof not found with ID: ${proofId}`);
        }
        if (proof.status !== 'approved' && proof.status !== 'pending') {
            throw new Error(
                `Proof ${proofId} is not in a valid state for verification. Status: ${proof.status}`,
            );
        }
        return from(
            this.verificationService.runVerification(evidence.attributes),
        );
    }

    get(aggId: string) {
        const proof = this.proofs[aggId];
        if (!proof) {
            throw new Error(`Could not find proof ${aggId}`);
        }
        return of(proof);
    }

    list(
        statuses: ProofStatus[] = ['pending', 'approved', 'rejected'],
    ): Observable<ProofState[]> {
        const activeProofs = Object.values(this.proofs).filter((proof) =>
            statuses.includes(proof.status),
        );
        console.log('List Of Proofs', activeProofs);
        return of(activeProofs);
    }

    getAllEvents(): Observable<ProofEvent[]> {
        return of(this.proofEvents);
    }
}

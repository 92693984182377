import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
    CheckCircle2,
    CircleMinus,
    Loader2,
    LoaderCircle,
    LucideAngularModule,
    XCircle,
} from 'lucide-angular';
import {
    RawEvidence,
    VerificationStatus,
    ZKEvidence,
} from '../../interfaces/proof.interface';

@Component({
    selector: 'app-evidence-details',
    standalone: true,
    imports: [CommonModule, LucideAngularModule],
    templateUrl: './evidence-details.component.html',
})
export class EvidenceDetailsComponent {
    @Input() evidence!: ZKEvidence | RawEvidence;
    @Input() loading = false;

    // Lucide icons
    readonly loader2Icon = Loader2;
    readonly checkCircle2Icon = CheckCircle2;
    readonly circleMinusIcon = CircleMinus;
    readonly circleIcon = LoaderCircle;
    readonly xCircleIcon = XCircle;

    getVerificationStatus(key: string): VerificationStatus {
        if (this.loading) {
            return VerificationStatus.Pending;
        }
        if (!this.evidence) {
            return VerificationStatus.VerifiedFailed;
        }
        if (this.evidence.type === 'raw') {
            return VerificationStatus.Verified;
        }

        if (this.evidence.type === 'zk') {
            return this.evidence.verificationStatuses[key];
        }
        return VerificationStatus.Unverified;
    }

    isStatus(key: string, status: VerificationStatus): boolean {
        const is_status = this.getVerificationStatus(key);
        console.log(
            `Checking Status ${key} ${is_status} == ${status} = ${is_status === status}`,
        );
        return is_status === status;
    }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, signal } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ButtonComponent } from '@fsco/shared';
import { DeclarationService } from '../../../services/decleration.service';
import { Declaration } from '../../interfaces/proof.interface';

@Component({
    selector: 'app-declaration-selector',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, ButtonComponent],
    templateUrl: './declaration-selector.component.html',
    styleUrls: [],
})
export class DeclarationSelectorComponent implements OnInit {
    @Output() declarationSelect = new EventEmitter<Declaration>();

    declarations = signal<Declaration[]>([]);
    selectedDeclaration = signal<Declaration | null>(null);
    loading = signal<boolean>(false);
    error = signal<string | null>(null);

    constructor(private declarationService: DeclarationService) {}

    ngOnInit(): void {
        this.loadDeclarations();
    }

    private loadDeclarations(): void {
        this.loading.set(true);
        this.declarationService.getDeclarations().subscribe({
            next: (declarations: Declaration[]) => {
                this.declarations.set(declarations);
                this.loading.set(false);
            },
            error: (err: Error) => {
                this.error.set('Failed to load declarations');
                this.loading.set(false);
                console.error('Error loading declarations:', err);
            },
        });
    }

    onDeclarationSelect(declaration: Declaration): void {
        this.selectedDeclaration.set(declaration);
        this.declarationSelect.emit(declaration);
    }
}

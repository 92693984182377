import { Abi, AbiFunction } from 'abitype';
import { lastValueFrom, map, mergeMap, of } from 'rxjs';
import { IContractCommandClient, IContractQueryClient } from '../sdk/types';
import { ContractContainer } from './contract_container';
import { IContractContainer, IContractFactory } from './interface';

export class ContractFactory implements IContractFactory {
    constructor(
        private readonly queryClient: IContractQueryClient,
        private readonly commandClient: IContractCommandClient,
    ) {}

    getContracts<T extends Abi>(
        abi: T,
        opts?: { chain?: number[]; name?: string; skip: number; take: number },
    ): Promise<IContractContainer<T>[]> {
        return lastValueFrom(
            of(null).pipe(
                mergeMap(() => {
                    return this.queryClient.searchAbi({
                        skip: opts?.skip ?? 0,
                        take: opts?.take ?? 100,
                        interfaceFilter: abi
                            .filter((abi) => abi.type === 'function')
                            .map((abi) => {
                                return {
                                    methodName: abi.name,
                                    stateMutability: abi.stateMutability,
                                    inputs: abi.inputs.map((i) => i.type),
                                    outputs: abi.outputs.map((o) => o.type),
                                };
                            }),
                    });
                }),
                mergeMap((abis) => {
                    const abiId = abis.response.map((abi) => abi.abiId);
                    return this.queryClient.searchDeployments({
                        abiId,
                        chainId: opts?.chain ? opts.chain : undefined,
                        name: opts?.name,
                        skip: opts?.skip ?? 0,
                        take: opts?.take ?? 20,
                    });
                }),
                map((deployments) => {
                    return deployments.response.map((deployment) => {
                        return new ContractContainer(
                            deployment.deploymentId,
                            deployment.contractAddress,
                            deployment.chainId,
                            abi,
                            {
                                query: this.queryClient,
                                command: this.commandClient,
                            },
                        ) as IContractContainer<T>;
                    });
                }),
            ),
        );
    }

    getContract<T extends Abi>(
        abi: T,
        // TODO: add support for address and chainId
        opts: { deploymentId: string },
    ): Promise<IContractContainer<T>> {
        return lastValueFrom(
            of(null).pipe(
                mergeMap(() => {
                    return this.queryClient.getDeployment({
                        deploymentId: opts.deploymentId,
                    });
                }),
                map((res) => res.response),
                map((deployment) => {
                    return new ContractContainer(
                        deployment.deploymentId,
                        deployment.contractAddress,
                        deployment.chainId,
                        abi,
                        {
                            query: this.queryClient,
                            command: this.commandClient,
                        },
                    ) as IContractContainer<T>;
                }),
            ),
        );
    }
}

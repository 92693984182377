import { Abi, AbiFunction, ExtractAbiFunctionNames } from 'abitype';
import {
    catchError,
    filter,
    lastValueFrom,
    map,
    retry,
    takeUntil,
    throwError,
    throwIfEmpty,
    timer,
} from 'rxjs';
import { IContractCommandClient, IContractQueryClient } from '../sdk/types';
import {
    Contract,
    ContractResponse,
    ExtractFunctionInputParams,
    IContractContainer,
} from './interface';

const safeParse = (data: unknown): unknown => {
    if (typeof data === 'bigint') {
        return `0x${data.toString(16)}`;
    }
    if (typeof data === 'boolean') {
        return data ? '0x1' : '0x0';
    }
    if (Array.isArray(data)) {
        return data.map(safeParse);
    }
    if (typeof data === 'object' && data !== null) {
        return Object.fromEntries(
            Object.entries(data).map(([key, value]: [string, unknown]) => [
                key,
                safeParse(value),
            ]),
        );
    }
    return data;
};

const IsView = <A extends Abi, FuncName extends ExtractAbiFunctionNames<A>>(
    abi: A,
    func_name: FuncName,
): FuncName extends ExtractAbiFunctionNames<A, 'view' | 'pure'>
    ? true
    : false => {
    const fnc = abi
        .filter((f): f is AbiFunction => f.type === 'function')
        .find((f) => f.name === func_name);

    if (!fnc) {
        throw new Error(`Function ${func_name} not found`);
    }

    return (fnc.stateMutability === 'view' ||
        fnc.stateMutability ===
            'pure') as FuncName extends ExtractAbiFunctionNames<
        A,
        'view' | 'pure'
    >
        ? true
        : false;
};

export class ContractContainer<A extends Abi> implements IContractContainer<A> {
    private active_wallet?: string;

    constructor(
        private readonly _deployment_id: string,
        private readonly _contract_address: string,
        private readonly _chain_id: number,
        private readonly _abi: A,
        private readonly _contract_clients: {
            query: IContractQueryClient;
            command: IContractCommandClient;
        },
    ) {}

    get deploymentId() {
        return this._deployment_id;
    }

    get address() {
        return this._contract_address;
    }
    get chainId() {
        return this._chain_id;
    }
    get abi() {
        return this._abi;
    }

    set_wallet(address: string): this {
        this.active_wallet = address;
        return this;
    }
    contract(): Contract<A> {
        return new Proxy({} as Contract<A>, {
            get: (_target, key) => {
                return (params: any) => this.execute(key as string, params);
            },
        });
    }
    clone(): this {
        return new ContractContainer(
            this._deployment_id,
            this._contract_address,
            this._chain_id,
            this._abi,
            this._contract_clients,
        ) as this;
    }
    executeWith<Return>(
        opts: { walletId: string },
        callback: (contract: Contract<A>) => Promise<Return>,
    ): Promise<Return> {
        return callback(this.clone().set_wallet(opts.walletId).contract());
    }

    async execute<FuncName extends ExtractAbiFunctionNames<A>>(
        fnc_name: FuncName,
        params: ExtractFunctionInputParams<A, FuncName>,
    ): Promise<ContractResponse<A, FuncName>> {
        const fnc = this.abi
            .filter((f) => f.type === 'function')
            .find((f) => f.name === fnc_name);
        if (!fnc) {
            throw new Error(`Function ${fnc_name} not found`);
        }
        if (fnc.type !== 'function') {
            throw new Error(`Function ${fnc_name} not found`);
        }
        if (typeof this.active_wallet === 'undefined') {
            throw new Error('Wallet not set');
        }

        if (fnc.inputs.length !== Object.values(params).length) {
            throw new Error(
                `Function ${fnc_name} requires ${fnc.inputs.length} parameters`,
            );
        }

        let mapped_params: (string | number)[];
        if (Array.isArray(params)) {
            mapped_params = params as (string | number)[];
        } else {
            mapped_params = fnc.inputs.map((_input, _index) => {
                const param = params[_input.name as keyof typeof params];
                return safeParse(param);
            }) as any;
        }
        let res: ContractResponse<A, FuncName>;
        if (IsView(this.abi, fnc_name)) {
            const query = {
                deploymentId: this._deployment_id,
                walletId: this.active_wallet!,
                methodName: fnc_name,
                paramCollection: mapped_params,
            };
            res = await lastValueFrom(
                this._contract_clients.query.queryDeployment(query).pipe(
                    map((res) => {
                        return {
                            // TODO: this response type is not correct
                            data: (res as any).response,
                        } as any;
                    }),
                ),
            );
        } else {
            const cmd = {
                deploymentId: this._deployment_id,
                walletId: this.active_wallet!,
                methodName: fnc_name,
                paramCollection: mapped_params,
            };
            res = await lastValueFrom(
                this._contract_clients.command.callDeployment(cmd).pipe(
                    map((res) => {
                        return {
                            tx_id: res.queryId,
                            wait: (timeout?: number) => {
                                return this.fetchTxReceipt(
                                    res.queryId,
                                    timeout,
                                );
                            },
                        } as any;
                    }),
                ),
            );
        }

        return res;
    }

    private fetchTxReceipt(
        txId: string,
        timeout?: number,
        should_throw?: boolean,
    ) {
        const timeoutDuration = timeout ?? 1000 * 60 * 3; // Default to 3 minutes if no timeout is provided
        const shouldThrow = should_throw ?? true;

        const request$ = this._contract_clients.query
            .getTxReceipt({ txId })
            .pipe(
                map((res) => res.response),
                filter((response) => typeof response.tx.status !== 'undefined'), // Check if status is defined
                map((response) => {
                    if (response.error && shouldThrow) {
                        throw response.error;
                    }
                    return response;
                }),
                throwIfEmpty(
                    () =>
                        new Error(
                            'Timeout reached without successful response',
                        ),
                ), // Handle case where no successful response is received before timeout
                catchError((err) => {
                    console.error('Error during the transaction query:', err);
                    return throwError(
                        () =>
                            new Error(
                                `Failed to get transaction receipt: ${err.message}`,
                            ),
                    );
                }),
            );

        return lastValueFrom(
            request$.pipe(
                retry({
                    // biome-ignore lint/style/useNumberNamespace: <explanation>
                    count: Infinity, // Retry indefinitely
                    delay: (_error, retryCount) =>
                        retryCount * 1000 <= timeoutDuration
                            ? timer(1000)
                            : throwError(
                                  () => new Error('Retry time exceeded'),
                              ),
                }),
                takeUntil(timer(timeoutDuration)), // This ensures we do not exceed the overall timeout
            ),
        );
    }
}

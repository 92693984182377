import { InjectionToken } from '@angular/core';

export interface Environment {
    // custom app name
    appName: string;
    // should be the default master realm
    realm: string;
    // client id of the app
    clientId: string;
    // authentication url of the auth server, should be the keycloak server url
    authenticationUrl: string;
    // production flag
    production: boolean;
    // stage flag
    stage: 'local' | 'dev' | 'staging' | 'prod';
    // platformapi url
    apiUrl: string;
    // use local storage
    useLocalStorage: boolean;
    // use mock data
    useMockData: boolean;
    chainId: number;
    enableLogin: boolean;
    defaultHeaders: Record<string, string>;
}

export const ENVIRONMENT = new InjectionToken<Environment>('environment');

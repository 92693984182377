import { inject } from '@angular/core';
import { ENVIRONMENT } from '../../config/environment.token';

export const getContractsEnvironment = () => {
    const env = inject(ENVIRONMENT);
    return (env as any).contracts;
};

// Export a type for type safety
export type ContractsEnvironment = {
    collateralizedTokenFactoryDeploymentId: string;
    collateralizedTokenFactoryAddress: string;
    liquidityProviderFactoryDeploymentId: string;
    liquidityRouterDeploymentId: string;
    baseUrl: string;
    fscocERC20Id: string;
    fscocERC20Address: string;
    fscocAttestionId: string;
    fscocAttestionAddress: string;
    fscotERC20Id: string;
    fscotERC20Address: string;
    fscotAttestionId: string;
    fscotAttestionAddress: string;
    fscoPoolDeploymentId: string;
    fscoPoolAddress: string;
    headers: {
        'x-correlation-id': string;
        'x-org-id': string;
        'x-policy-id': string;
        'x-tenant-id': string;
        'x-user-id': string;
    };
};

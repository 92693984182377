export const AttestationManagerAbi = [
    {
      type: "constructor",
      inputs: [
        { name: "admin", type: "address", internalType: "address" },
        { name: "submitter", type: "address", internalType: "address" },
        { name: "verifier", type: "address", internalType: "address" }
      ],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "DEFAULT_ADMIN_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "SUBMITTER_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "VERIFIER_ROLE",
      inputs: [],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "attestations",
      inputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      outputs: [
        { name: "user", type: "address", internalType: "address" },
        { name: "amount", type: "uint256", internalType: "uint256" },
        { name: "valid", type: "bool", internalType: "bool" },
        { name: "used", type: "bool", internalType: "bool" }
      ],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "getRoleAdmin",
      inputs: [{ name: "role", type: "bytes32", internalType: "bytes32" }],
      outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "grantRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "hasRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "isAttestationValid",
      inputs: [{ name: "attestationId", type: "bytes32", internalType: "bytes32" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "markAttestationUsed",
      inputs: [{ name: "attestationId", type: "bytes32", internalType: "bytes32" }],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "renounceRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "callerConfirmation", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "revokeRole",
      inputs: [
        { name: "role", type: "bytes32", internalType: "bytes32" },
        { name: "account", type: "address", internalType: "address" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "setTokenContract",
      inputs: [{ name: "_tokenContract", type: "address", internalType: "address" }],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "submitAttestation",
      inputs: [
        { name: "attestationId", type: "bytes32", internalType: "bytes32" },
        { name: "user", type: "address", internalType: "address" },
        { name: "amount", type: "uint256", internalType: "uint256" }
      ],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "function",
      name: "supportsInterface",
      inputs: [{ name: "interfaceId", type: "bytes4", internalType: "bytes4" }],
      outputs: [{ name: "", type: "bool", internalType: "bool" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "tokenContract",
      inputs: [],
      outputs: [{ name: "", type: "address", internalType: "address" }],
      stateMutability: "view"
    },
    {
      type: "function",
      name: "verifyAttestation",
      inputs: [{ name: "attestationId", type: "bytes32", internalType: "bytes32" }],
      outputs: [],
      stateMutability: "nonpayable"
    },
    {
      type: "event",
      name: "AttestationSubmitted",
      inputs: [
        { name: "attestationId", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "user", type: "address", indexed: true, internalType: "address" },
        { name: "amount", type: "uint256", indexed: false, internalType: "uint256" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "AttestationUsed",
      inputs: [
        { name: "attestationId", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "marker", type: "address", indexed: true, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "AttestationVerified",
      inputs: [
        { name: "attestationId", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "verifier", type: "address", indexed: true, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleAdminChanged",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "previousAdminRole", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "newAdminRole", type: "bytes32", indexed: true, internalType: "bytes32" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleGranted",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "account", type: "address", indexed: true, internalType: "address" },
        { name: "sender", type: "address", indexed: true, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "event",
      name: "RoleRevoked",
      inputs: [
        { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
        { name: "account", type: "address", indexed: true, internalType: "address" },
        { name: "sender", type: "address", indexed: true, internalType: "address" }
      ],
      anonymous: false
    },
    {
      type: "error",
      name: "AccessControlBadConfirmation",
      inputs: []
    },
    {
      type: "error",
      name: "AccessControlUnauthorizedAccount",
      inputs: [
        { name: "account", type: "address", internalType: "address" },
        { name: "neededRole", type: "bytes32", internalType: "bytes32" }
      ]
    }
  ] as const;
  
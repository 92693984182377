import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonComponent } from '@fsco/shared';
import { StepProgressComponent } from '@fsco/shared';
import { firstValueFrom } from 'rxjs';
import { EventService } from '../services/event.service';
import {
    ERC20LinkType,
    LinkService,
    NFTLinkType,
    TransactionLinkType,
    WalletLinkType,
} from '../services/link.service';
import { ProofService } from '../services/proof.service';
import { ProofState } from '../shared/interfaces/proof.interface';

@Component({
    selector: 'app-link-proof',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonComponent,
        StepProgressComponent,
    ],
    templateUrl: './link-proof.component.html',
    styles: [],
})
export class LinkProofComponent implements OnInit {
    loading = signal<boolean>(false);
    selectedProof = signal<ProofState | null>(null);
    searchTerm = signal<string>('');
    selectedTab = signal<string>('erc20');
    erc20ContractAddress = signal<string>('');
    erc20WalletAddress = signal<string>('');
    nftContractAddress = signal<string>('');
    nftTokenId = signal<string>('');
    walletAddress = signal<string>('');
    transactionHash = signal<string>('');
    currentStage = signal<number>(1);
    availableProofs = signal<ProofState[]>([]);

    steps = [
        { title: 'Select Proof', complete: false },
        { title: 'Asset Details', complete: false },
        { title: 'Review', complete: false },
    ];

    tabs = [
        { id: 'erc20', label: 'ERC-20 Balance' },
        { id: 'nft', label: 'NFT' },
        { id: 'wallet', label: 'Wallet' },
        { id: 'transaction', label: 'Transaction' },
    ];

    constructor(
        private router: Router,
        private eventService: EventService,
        private proofService: ProofService,
        private linkService: LinkService,
    ) {}

    ngOnInit() {
        // Load only approved proofs that aren't linked yet
        this.eventService.list(['approved']).subscribe((proofs) => {
            const unlinkableProofs = proofs.filter(
                (proof) =>
                    'linkStatus' in proof &&
                    (proof.linkStatus === 'unlinked' ||
                        proof.linkStatus === 'rejected'),
            );
            this.availableProofs.set(unlinkableProofs);
        });
    }

    selectProof(proof: ProofState): void {
        this.selectedProof.set(proof);
    }

    selectTab(tab: string): void {
        this.selectedTab.set(tab);
    }

    nextStage(): void {
        if (this.currentStage() < 3) {
            this.currentStage.set(this.currentStage() + 1);
        }
    }

    private getLinkData() {
        switch (this.selectedTab()) {
            case 'erc20': {
                const erc20LinkData: ERC20LinkType = {
                    type: 'erc20',
                    contractAddress: this.erc20ContractAddress(),
                    userBalance: BigInt(this.erc20WalletAddress()),
                };
                return erc20LinkData;
            }
            case 'nft': {
                const nftLinkData: NFTLinkType = {
                    type: 'nft',
                    contractAddress: this.nftContractAddress(),
                    tokenId: BigInt(this.nftTokenId()),
                };
                return nftLinkData;
            }
            case 'wallet': {
                const walletLinkData: WalletLinkType = {
                    type: 'wallet',
                    walletAddress: this.walletAddress(),
                };
                return walletLinkData;
            }
            case 'transaction': {
                const transactionLinkData: TransactionLinkType = {
                    type: 'transaction',
                    txHash: BigInt(this.transactionHash()),
                };
                return transactionLinkData;
            }
            default:
                throw new Error('Invalid tab');
        }
    }

    async requestLinking(): Promise<void> {
        const proof = this.selectedProof();
        const selectedTab = this.selectedTab();
        if (!proof || !selectedTab) {
            return;
        }

        const linkData = this.getLinkData();

        console.log('linkData', linkData);

        if (!linkData) {
            throw new Error('No link data');
        }
        try {
            this.loading.set(true);

            await firstValueFrom(
                this.linkService.requestLink(proof.id, linkData),
            );

            // Navigate to success or dashboard
            this.router.navigate(['/dashboard']);
        } catch (error) {
            console.error('Error submitting link request:', error);
            // Handle error appropriately
        } finally {
            this.loading.set(false);
        }
    }

    canLink(): boolean {
        // Stage 1: Only check if a proof is selected
        if (this.currentStage() === 1) {
            return !!this.selectedProof();
        }

        // Stage 2: Check the selected asset type fields
        if (this.currentStage() === 2) {
            const isERC20 =
                this.selectedTab() === 'erc20' &&
                !!this.erc20ContractAddress() &&
                !!this.erc20WalletAddress();
            const isNFT =
                this.selectedTab() === 'nft' &&
                !!this.nftContractAddress() &&
                !!this.nftTokenId();
            const isWallet =
                this.selectedTab() === 'wallet' && !!this.walletAddress();
            const isTransaction =
                this.selectedTab() === 'transaction' &&
                !!this.transactionHash();

            return isERC20 || isNFT || isWallet || isTransaction;
        }

        // Stage 3: All validations should be complete
        return true;
    }

    filterProofs(searchTerm: string): ProofState[] {
        return this.availableProofs().filter((proof) =>
            proof.declaration.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()),
        );
    }
}

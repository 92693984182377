import {
    HttpClient,
    type HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { type Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { getEnvironment } from '../../environments/environment';
import { ENVIRONMENT, type Environment } from '../config/environment.token';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private environment: Environment = getEnvironment();

    constructor(
        private http: HttpClient,
        private readonly sessionService: SessionService,
    ) {}

    private getHeaders(): HttpHeaders {
        const headers = this.sessionService.authHeaders();
        return new HttpHeaders({
            'Content-Type': 'application/json',
            ...headers,
        });
    }

    get<T>(endpoint: string): Observable<T> {
        return this.http
            .get<T>(`${this.environment.apiUrl}/${endpoint}`, {
                headers: this.getHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    post<T, D = unknown>(endpoint: string, data: D): Observable<T> {
        return this.http
            .post<T>(`${this.environment.apiUrl}/${endpoint}`, data, {
                headers: this.getHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    put<T, D = unknown>(endpoint: string, data: D): Observable<T> {
        return this.http
            .put<T>(`${this.environment.apiUrl}/${endpoint}`, data, {
                headers: this.getHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    delete<T>(endpoint: string): Observable<T> {
        return this.http
            .delete<T>(`${this.environment.apiUrl}/${endpoint}`, {
                headers: this.getHeaders(),
            })
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = 'An unknown error occurred';
        if (error.error instanceof ErrorEvent) {
            // Client-side or network error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Backend error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        return throwError(() => new Error(errorMessage));
    }
}

<div class="min-h-screen flex flex-col bg-gray-50">
  <!-- Navigation -->
  <nav class="bg-white shadow-sm sticky top-0 z-10 border-t-4 border-green-500">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex items-center">
          <img class="h-8 w-auto" src="assets/shared/logo.svg" alt="FSCO Logo">
          <span class="ml-2 text-xl text-gray-900">
            <span class="font-semibold">FSCO</span> | <span class="font-normal">Proof</span>
          </span>
        </div>
        <div class="flex items-center space-x-4">
          <a routerLink="/" routerLinkActive="text-green-600 font-medium" [routerLinkActiveOptions]="{exact: true}" class="text-gray-700 hover:text-green-600 px-3 py-2 rounded-md text-sm transition-colors duration-200">Home</a>
          @if(enableLogin) {
            <button (click)="logout()" class="text-gray-700 hover:text-green-600 px-3 py-2 rounded-md text-sm transition-colors duration-200">Logout</button>
          }
        </div>
      </div>
    </div>
  </nav>

  <!-- Page Content -->
  <main class="flex-grow">
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <router-outlet></router-outlet>
    </div>
  </main>

  <!-- Footer -->
  <footer class="bg-white border-t border-gray-200">
    <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <p class="text-center text-sm text-gray-500">&copy; {{ getYear() }} FSCO.io. All rights reserved.</p>
    </div>
  </footer>
</div>

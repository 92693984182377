import { Injectable } from '@angular/core';
import { ApiService } from '@fsco/shared';
import axios from 'axios';
import { Observable, from, map } from 'rxjs';
import { convertSessionIdToUUID } from '../../utils';

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    constructor(private readonly apiService: ApiService) {}

    createMetadataType(key: string): Observable<{
        typeId: string;
    }> {
        return this.apiService.post<
            { typeId: string },
            {
                displayName: string;
                key: string;
                description: string;
                type: string;
            }
        >('metadata/type', {
            displayName: 'webhook-data',
            key,
            description: 'Sharering webhook data',
            type: 'string',
        });
    }

    getMetadataTypeByKey(key: string): Observable<{ typeId: string }> {
        return this.apiService.post<{ typeId: string }, { key: string }>(
            'metadata/type/find',
            {
                key,
            },
        );
    }

    createMetadata(
        query: string,
        typeId: string,
        sessionId: string,
    ): Observable<{ queryId: string }> {
        const aggId = convertSessionIdToUUID(sessionId);
        return this.apiService.post<
            { queryId: string },
            {
                aggregates: {
                    aggregateId: string;
                    metadata: {
                        typeId: string;
                        value: string;
                    }[];
                }[];
            }
        >('metadata', {
            aggregates: [
                {
                    aggregateId: aggId,
                    metadata: [
                        {
                            typeId: typeId,
                            value: query,
                        },
                    ],
                },
            ],
        });
    }

    findMetadata(
        sessionId: string,
        typeId: string,
    ): Observable<{ value: string }> {
        const aggId = convertSessionIdToUUID(sessionId);
        return this.apiService
            .post<
                { metadata: { value: string }[] },
                {
                    aggregateId: string;
                    typeId: string;
                }
            >('metadata/find', {
                aggregateId: aggId,
                typeId,
            })
            .pipe(map((response) => response.metadata[0]));
    }
}

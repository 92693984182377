import { HttpClient } from '@angular/common/http';
import { Observable, from, mergeMap } from 'rxjs';
import { Environment } from '../config/environment.token';

// SDK class that handles the raw auth API interactions
export class AuthSDK {
    constructor(
        private readonly environment: Environment,
        private readonly httpClient: HttpClient,
    ) { }

    getLoginUrl(redirectUrl: string): string {
        const encodedRedirect = encodeURIComponent(redirectUrl);
        return `${this.environment.authenticationUrl}/realms/${this.environment.realm}/protocol/openid-connect/auth?client_id=${this.environment.clientId}&redirect_uri=${encodedRedirect}&response_type=code&scope=organization`;
    }

    getLogoutUrl(redirectUrl: string): string {
        const encodedRedirect = encodeURIComponent(redirectUrl);
        return `${this.environment.authenticationUrl}/realms/${this.environment.realm}/protocol/openid-connect/logout?client_id=${this.environment.clientId}&post_logout_redirect_uri=${encodedRedirect}`;
    }

    exchangeCode(
        code: string,
        redirectUrl: string,
    ): Observable<{ access_token: string; refresh_token: string }> {
        const url = `${this.environment.authenticationUrl}/realms/${this.environment.realm}/protocol/openid-connect/token`;

        const body = new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: this.environment.clientId,
            code: code,
            redirect_uri: redirectUrl,
        });

        return this.httpClient.post<{
            access_token: string;
            refresh_token: string;
        }>(url, body.toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }

    refreshToken(
        refreshToken: string,
    ): Observable<{ access_token: string; refresh_token: string }> {
        const refreshUrl = `${this.environment.authenticationUrl}/realms/${this.environment.realm}/protocol/openid-connect/token`;

        const body = new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: this.environment.clientId,
            refresh_token: refreshToken,
        });

        return this.httpClient.post<{
            access_token: string;
            refresh_token: string;
        }>(refreshUrl, body.toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
}

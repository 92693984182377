import { CommonModule } from '@angular/common';
import { Component, Input, computed, signal } from '@angular/core';

export interface Step {
    title: string;
    completed?: boolean;
}

@Component({
    selector: 'fsco-step-progress',
    templateUrl: './step-progress.component.html',
    styleUrls: [],
    standalone: true,
    imports: [CommonModule],
})
export class StepProgressComponent {
    @Input({ required: true }) steps!: Step[];
    @Input({ required: true }) currentStep = 0;
    protected isCompleted = computed(() => (index: number) => {
        return this.currentStep > index + 1;
    });

    protected isCurrent = computed(() => (index: number) => {
        return this.currentStep === index + 1;
    });
}

export class Logger {
    private readonly styles = {
        LOG: 'color: #8a8a8a',         // Gray
        ERROR: 'color: #ff3333',       // Red
        WARN: 'color: #ffa500',        // Orange
        INFO: 'color: #00aaff',        // Blue
        LABEL: 'font-weight: bold;',   // Bold styling for the label
        RESET: ''                      // Reset
    };

    constructor(private readonly namespace: string) { }

    private out(space: string, level: string, ...args: any[]) {
        const style = this.styles[level as keyof typeof this.styles] || '';
        const label = `%c[${level}] [${space}]:`;
        console.log(
            label,
            `${this.styles.LABEL};${style}`,
            ...args
        );
    }

    log(...args: any[]) {
        this.out(this.namespace, 'LOG', ...args);
    }

    error(...args: any[]) {
        this.out(this.namespace, 'ERROR', ...args);
    }

    warn(...args: any[]) {
        this.out(this.namespace, 'WARN', ...args);
    }

    info(...args: any[]) {
        this.out(this.namespace, 'INFO', ...args);
    }
}

<div class="container mx-auto p-4">
    <!-- Error Message -->
    @if (error()) {
    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
        {{ error() }}
    </div>
    }

    <!-- Loading State -->
    @if (loading()) {
    <div class="flex justify-center items-center">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
    }

    <div class="mb-6">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            @for (declaration of declarations(); track declaration.id) {
            <button (click)="onDeclarationSelect(declaration)" [class]="declaration === selectedDeclaration() ? 
                            'p-4 border rounded-lg bg-blue-50 border-blue-500 transition-colors duration-200' :
                            'p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200'">
                <h3 class="font-semibold">{{ declaration.name }}</h3>
                <p class="text-sm text-gray-600">{{ declaration.description }}</p>
            </button>
            }
        </div>
    </div>

</div>
<div class="container mx-auto p-4">
    <!-- Error Message -->
    @if (error()) {
    <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
        {{ error() }}
    </div>
    }

    <!-- Loading State -->
    @if (loading()) {
    <div class="flex justify-center items-center">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
    }


    <div class="max-w-lg mx-auto">
        <div class="flex items-center mb-4">
            <h2 class="text-xl font-bold">{{ declaration?.name }}</h2>
        </div>

        <form [formGroup]="declarationForm"  class="space-y-4">
            @for (field of declaration?.formConfig; track field.attributeKey) {
            <div class="form-group">
                <label [for]="field.attributeKey" class="block text-sm font-medium text-gray-700 mb-1">
                    {{ field.label }}
                </label>

                @switch (field.type) {
                @case ('date') {
                <input [id]="field.attributeKey" type="date" [formControlName]="field.attributeKey"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500" />
                }
                @case ('number') {
                <input [id]="field.attributeKey" type="number" [formControlName]="field.attributeKey"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500" />
                }
                @default {
                <input [id]="field.attributeKey" type="text" [formControlName]="field.attributeKey"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500" />
                }
                }

                @if (declarationForm.get(field.attributeKey)?.errors?.['required'] &&
                declarationForm.get(field.attributeKey)?.touched) {
                <p class="mt-1 text-sm text-red-600">This field is required</p>
                }
            </div>
            }
        </form>
    </div>

</div>
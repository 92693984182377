import { ContractFactory } from '../util';
import { ContractCommandClient } from './command.client';
import { ContractQueryClient } from './query.client';

export class ContractSdk {
    public readonly query: ContractQueryClient;
    public readonly command: ContractCommandClient;
    public readonly factory: ContractFactory;
    constructor(
        private readonly baseUrl: string,
        private readonly headers: () => Promise<Record<string, string>>,
    ) {
        this.query = new ContractQueryClient(this.baseUrl, this.headers);
        this.command = new ContractCommandClient(this.baseUrl, this.headers);
        this.factory = new ContractFactory(this.query, this.command);
    }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ButtonComponent } from '@fsco/shared';
import { DeclarationFormComponent } from '../../../shared/components/declaration-form/declaration-form.component';
import { EvidenceFormComponent } from '../../../shared/components/evidence-form/evidence-form.component';
import {
    Declaration,
    Evidence,
} from '../../../shared/interfaces/proof.interface';

@Component({
    selector: 'app-fsco-query',
    standalone: true,
    imports: [
        CommonModule,
        ButtonComponent,
        DeclarationFormComponent,
        EvidenceFormComponent,
    ],
    templateUrl: './fsco-query.component.html',
})
export class FSCOQueryComponent {
    loading = signal<boolean>(false);
    error = signal<string | null>(null);
    success = signal<boolean>(false);
    stage = signal<number>(0);
    file = signal<File | null>(null);
    declarationData = signal<Pick<Evidence, 'attributes'> | null>(null);
    evidenceData = signal<string | null>(null);
    declarationDataValid = signal<boolean>(false);

    @Input() declaration!: Declaration;
    @Output() evidenceSubmit = new EventEmitter<Evidence>();

    constructor() {}

    handleDeclarationData(data: Pick<Evidence, 'attributes'>): void {
        this.declarationData.set(data);
    }

    handleFileSelected(file: string): void {
        this.evidenceData.set(file);
    }

    submitEvidence(): void {
        const declarationData = this.declarationData();
        const evidenceData = this.evidenceData();
        console.log('submitEvidence', declarationData, evidenceData);
        if (declarationData && evidenceData) {
            this.evidenceSubmit.emit({
                attributes: declarationData.attributes,
                documentUrl: evidenceData,
                id: `evidence-${Date.now()}`,
                customerId: 'customer-id',
                type: 'raw',
                declarationId: this.declaration.id,
            });
        }
    }

    nextStep(): void {
        this.stage.update((current) => Math.min(current + 1, 1));
    }

    previousStep(): void {
        this.stage.update((current) => Math.max(0, current - 1));
    }

    queryFSCO() {
        this.loading.set(true);
        // Simulate API call
        setTimeout(() => {
            this.loading.set(false);
        }, 1000);
    }
}

export type ProofStatus = 'pending' | 'approved' | 'rejected';
export type LinkStatus = 'unlinked' | 'pending' | 'approved' | 'rejected';

export type ProofEventType =
    | 'requested'
    | 'reviewed'
    | 'link-requested'
    | 'link-reviewed';

export type RequestedProofEvent = {
    type: 'requested';
    status: 'pending';
    aggId: string;
    txHash: string;
    walletAddress: string;
    eventTime: Date;
    requestedBy: string;
    requestedAt: string;
    declaration: Declaration;
    evidence: Evidence;
};

export type ReviewedProofEvent = {
    type: 'reviewed';
    status: 'approved' | 'rejected';
    aggId: string;
    txHash: string;
    walletAddress: string;
    eventTime: Date;
    reviewedBy: string;
    reviewedAt: string;
    reviewNotes?: string;
    proof: Proof;
};

export type LinkRequestEvent = {
    type: 'link-requested';
    aggId: string;
    txHash: string;
    walletAddress: string;
    eventTime: Date;
    requestedBy: string;
    requestedAt: string;
    linkRequest: LinkRequest;
};

export type LinkReviewedEvent = {
    type: 'link-reviewed';
    aggId: string;
    txHash: string;
    walletAddress: string;
    eventTime: Date;
    reviewedBy: string;
    reviewedAt: string;
    status: 'approved' | 'rejected';
    notes: string;
};

export type ProofEvent =
    | RequestedProofEvent
    | ReviewedProofEvent
    | LinkRequestEvent
    | LinkReviewedEvent;

export type LinkUnlinkedState = {
    linkStatus: 'unlinked';
};

export type LinkPendingState = {
    linkStatus: 'pending';
    linkRequestedBy: string;
    linkRequestedAt: Date;
    linkRequesterWalletAddress: string;
    link: LinkRequest;
};

export type LinkApprovedState = {
    linkStatus: 'approved';
    linkReviewedBy: string;
    linkReviewedAt: Date;
    linkReviewerWalletAddress: string;
} & Omit<LinkPendingState, 'linkStatus'>;

export type LinkRejectedState = {
    linkStatus: 'rejected';
    linkReviewedBy: string;
    linkReviewedAt: Date;
    linkReviewerWalletAddress: string;
} & Omit<LinkPendingState, 'linkStatus'>;

export type LinkState =
    | LinkPendingState
    | LinkApprovedState
    | LinkRejectedState
    | LinkUnlinkedState;

export type ProofPendingState = {
    status: 'pending';
    requestedBy: string;
    requestedAt: Date;
    requestedByWalletAddress: string;
    declaration: Declaration;
    evidence: Evidence;
};

export type ProofApprovedState = {
    status: 'approved';
    reviewedBy: string;
    reviewedAt: Date;
    reviewedByWalletAddress: string;
    reviewNotes?: string;
    proof: Proof;
} & Omit<ProofPendingState, 'status'> &
    LinkState;

export type ProofRejectedState = {
    status: 'rejected';
    reviewedBy: string;
    reviewedAt: Date;
    reviewedByWalletAddress: string;
    reviewNotes?: string;
} & Omit<ProofPendingState, 'status'>;

export type ProofState = {
    id: string;
    events: ProofEvent[];
} & (ProofPendingState | ProofApprovedState | ProofRejectedState);

export const applyEvent = (
    event: ProofEvent,
    state?: ProofState,
): ProofState => {
    switch (event.type) {
        case 'requested':
            if (state) throw new Error('Request event on existing proof');
            return {
                id: event.aggId,
                events: [event],
                status: 'pending',
                requestedBy: event.requestedBy,
                requestedAt: event.eventTime,
                requestedByWalletAddress: event.walletAddress,
                declaration: event.declaration,
                evidence: event.evidence,
            };
        case 'reviewed':
            if (!state) throw new Error('Review event on new proof');
            if (state.status !== 'pending')
                throw new Error('Can only review pending proofs');
            if (event.status === 'approved') {
                return {
                    ...state,
                    events: [...state.events, event],
                    status: 'approved',
                    reviewedBy: event.reviewedBy,
                    reviewedAt: event.eventTime,
                    reviewedByWalletAddress: event.walletAddress,
                    reviewNotes: event.reviewNotes,
                    proof: event.proof,
                    linkStatus: 'unlinked',
                };
            }
            return {
                ...state,
                id: event.aggId,
                events: [...state.events, event],
                status: 'rejected',
                reviewedBy: event.reviewedBy,
                reviewedAt: event.eventTime,
                reviewedByWalletAddress: event.walletAddress,
                reviewNotes: event.reviewNotes,
            };
        case 'link-requested':
            if (!state) throw new Error('Link request event on new proof');
            if (state.status !== 'approved')
                throw new Error('Can on request links on approved proofs');
            if (state.linkStatus === 'approved')
                throw new Error('Can only be linked once');
            if (state.linkStatus === 'pending')
                throw new Error('Can only request links once');
            return {
                ...state,
                events: [...state.events, event],
                linkStatus: 'pending',
                linkRequestedBy: event.requestedBy,
                linkRequestedAt: event.eventTime,
                linkRequesterWalletAddress: event.walletAddress,
                link: event.linkRequest,
            };
        case 'link-reviewed':
            if (!state) throw new Error('Link review event on new proof');
            if (state.status !== 'approved')
                throw new Error('Can only review links on approved proofs');
            if (state.linkStatus !== 'pending')
                throw new Error('Can only review pending links');
            return {
                ...state,
                id: event.aggId,
                events: [...state.events, event],
                linkStatus: event.status,
                linkReviewedBy: event.reviewedBy,
                linkReviewedAt: event.eventTime,
                linkReviewerWalletAddress: event.walletAddress,
            };
    }
};

export const reduceProofEvents = (
    events: ProofEvent[],
): Record<string, ProofState> => {
    return events.reduce(
        (acc, event) => {
            const state = applyEvent(event, acc[event.aggId]);
            acc[event.aggId] = state;
            return acc;
        },
        {} as Record<string, ProofState>,
    );
};

export async function hashEvidence(evidence: Evidence): Promise<string> {
    // Create a string containing all evidence data to hash
    const evidenceString = `${evidence.id}:${evidence.customerId}:${evidence.declarationId}:${Object.entries(
        evidence.attributes,
    )
        .map(([key, value]) => `${key}:${value}`)
        .join(',')}`;

    // Convert string to array buffer
    const encoder = new TextEncoder();
    const data = encoder.encode(evidenceString);

    // Create SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert hash to hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');

    return hashHex;
}

export interface Declaration {
    id: string;
    version: string;
    uri: string;
    shareRing: {
        queryId: string;
        queryOwner: string;
        clientId: string;
    };
    name: string;
    description: string;
    formConfig: {
        attributeKey: string;
        label: string;
        type: 'text' | 'number' | 'date' | 'boolean';
    }[];
}

export const VerificationStatus = {
    Unverified: 'unverified',
    Pending: 'pending',
    Verified: 'verified',
    VerifiedFailed: 'verifiedFailed',
} as const;
export type VerificationStatus =
    (typeof VerificationStatus)[keyof typeof VerificationStatus];

export interface ZKEvidence {
    id: string;
    type: 'zk';
    customerId: string;
    declarationId: string;
    provider: 'sharering';
    attributes: Record<string, string>;
    verificationStatuses: Record<string, VerificationStatus>;
}

export interface RawEvidence {
    id: string;
    type: 'raw';
    customerId: string;
    declarationId: string;
    attributes: Record<string, string>;
    documentUrl: string;
}

export type Evidence = ZKEvidence | RawEvidence;

export interface ProofRequest {
    id: string;
    customerId: string;
    declarationId: string;
    status: ProofStatus;
    evidenceHash: string;
}

export interface Proof {
    id: string;
    customerId: string;
    declarationId: string;
    status: ProofStatus;
    evidenceHash: string;
}

export interface LinkRequest {
    id: string;
    customerId: string;
    proofId: string;
    targetType: string;
    targetAttributes: Record<string, string>;
}

import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { ButtonComponent } from '@fsco/shared';
import { LoggerService } from '@fsco/shared';
import { DeclarationService } from '../../../services/decleration.service';
import { Declaration, Evidence } from '../../interfaces/proof.interface';

@Component({
    selector: 'app-declaration-form',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, ButtonComponent],
    templateUrl: './declaration-form.component.html',
    styleUrls: [],
})
export class DeclarationFormComponent implements OnInit {
    @Output() formData = new EventEmitter<Pick<Evidence, 'attributes'>>();
    @Output() formValid = new EventEmitter<boolean>();

    @Input() declaration?: Declaration;
    declarationForm: FormGroup;
    loading = signal<boolean>(false);
    error = signal<string | null>(null);

    constructor(
        private declarationService: DeclarationService,
        private fb: FormBuilder,
        private logger: LoggerService,
    ) {
        this.declarationForm = this.fb.group({});
    }

    ngOnInit(): void {
        if (this.declaration) {
            this.createForm(this.declaration);
            this.logger.info(
                'Declaration form initialized',
                { declarationId: this.declaration.id },
                'DeclarationFormComponent',
            );
        }
    }

    private createForm(declaration: Declaration): void {
        const formGroup: Record<string, any> = {};

        for (const field of declaration.formConfig) {
            formGroup[field.attributeKey] = ['', []];

            if (field.type === 'number') {
                formGroup[field.attributeKey].push(Validators.pattern(/^\d+$/));
            }
        }

        this.declarationForm = this.fb.group(formGroup);
        this.declarationForm.valueChanges.subscribe(() => {
            this.formValid.emit(this.declarationForm.valid);
            if (this.declarationForm.valid && this.declaration) {
                this.formData.emit({
                    attributes: this.declarationForm.value,
                });
            }
        });
    }
}

<div class="flex flex-col items-center gap-4">
  <h2 class="text-2xl font-bold mb-4">ShareRing Query</h2>

  @if (qrcodeUrl()) {
  <qrcode [qrdata]="qrcodeUrl()!" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  }

  <!-- <div class="sharering-query" [attr.queryId]="queryId" mode="dynamic" [attr.qrcodeOwner]="qrcodeOwner"
    app="ShareRing Me">
    <div class="qr-container">
      <div #qrcodeElement id="qrcode" class="bg-white p-4 rounded-lg shadow-lg"></div>
    </div>
  </div> -->
  <!--
  @if (loading()) {
  <div class="text-center">
    <p class="text-gray-600">Loading QR Code...</p>
  </div>
  } -->

  @if (error()) {
  <div class="text-center text-red-600">
    {{ error() }}
  </div>
  }

  @if (success()) {
  <div class="text-center text-green-600">
    Scan successful!
  </div>
  }
</div>

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SessionService } from '../../services/session.service';
import { Logger } from '../../utils/logger';
import { ButtonComponent } from '../../components/button/button.component';

@Component({
  selector: 'cp-logout',
  templateUrl: './logout.html',
  styleUrls: ['./logout.scss'],
  standalone: true,
  imports: [ButtonComponent]
})
export class LogoutComponent {
  private readonly logger = new Logger('LogoutComponent');
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.logger.log('Initialized');
    this.authService.save(null);
  }

}
